import { configureStore } from "@reduxjs/toolkit";
//import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import appReducer from './slices/app-slice';
import transactionReducer from './slices/transaction-slice';
import messagesReducer from './slices/messages-slice';
import messagesV2Reducer from './slices/messages-v2-slice';
import productReducer from './slices/product-slice';
import portfolioBreakdownSlice from './slices/portfolio-breakdown-slice';
import userDataSlice from './slices/user-slice';
import recentTransactionSlice from './slices/recent-transaction-slice';
import strategyPerformanceReducer from './slices/strategy-performance-slice';
import inactivityReducer from './slices/inactivity-slice';
import apiKeySlice from './slices/apikey-slice';
import popperSlice from "./slices/popper-slice";
import socialMediaSlices from "./slices/social-media-slice";
import portalToggleSlice from "./slices/portal-toggle-slice";
import modeToggleSlice from "./slices/mode-toggle-slice";
import drawerSlice from "./slices/drawer-slice";
import tabSlice from "./slices/common-slice";
import bellIconBadgeSlice from "./slices/bell-icon-badge-slice";
import logoutSlice from "./slices/logout-slice";
import productIndividualSlice from "./slices/product-individual-slice";
import themingReducer from "./slices/theming-slice";
import serverStateSlice from "./slices/server-slice";
const store = configureStore({
  reducer: {
    app: appReducer,
    theme: themingReducer,
    txn: transactionReducer,
    messagev2: messagesV2Reducer,
    message: messagesReducer,
    product: productReducer,
    portfolioBreakdown: portfolioBreakdownSlice,
    user: userDataSlice,
    drawer: drawerSlice,
    tab: tabSlice,
    recentTransaction: recentTransactionSlice,
    strategyPerformance: strategyPerformanceReducer,
    inactivityStatus: inactivityReducer,
    apiKey: apiKeySlice,
    popperStatus: popperSlice,
    socialMediaConnectionStatus: socialMediaSlices,
    portal: portalToggleSlice,
    mode: modeToggleSlice,
    bellIconBadge: bellIconBadgeSlice,
    logout: logoutSlice,
    productIndividual: productIndividualSlice,
    serverState: serverStateSlice,
  },
  // middleware: [createLogger(), thunk],
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
