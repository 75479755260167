import { Button, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import "./index.scss";

interface EmptyCardInterface {
    title: string,
    subtitle?: string,
    buttonContent?: string,
    functionToCall?: any
}

const useStyles = makeStyles(() => ({

    saveButton: {
        height: "40px",
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",
        backgroundColor: "#43D9BB",
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: "#36b299"
        }
    }

}))

function EmptyCard(props: EmptyCardInterface) {

    const { title, subtitle, buttonContent, functionToCall } = props;
    const classes = useStyles();

    const onClickHandler = () => {
        functionToCall(true)
    }

    return <Card className="empty_card" sx={{ border: "1px solid #EAECF0" }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ minWidth: "1064px", minHeight: "172px" }}>
            <Box className="text-base font-medium text-gray-900 adjustable">
                {title}
            </Box>
            {subtitle && <Box className="text-sm font-normal text-gray-500">
                {subtitle}
            </Box>}
            {buttonContent && <Box mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.saveButton} adjustable-btn`}
                    size='small'
                    onClick={onClickHandler}
                >
                    {buttonContent}
                </Button>
            </Box>}
        </Box>
    </Card>
}

export default EmptyCard;