export enum STATUS_CODE {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    INTERNAL_SERVER_ERROR = 500,
}

export enum MESSAGE_TYPE {
    NOT_FOUND = "Wrong email address or password.",
    NOT_VERIFIED = "This account is unverified. Please check out the email inbox to activate your account."
}

export const DomainInitial: {[key: string]: string} = {
    'dev': 'test',
    'beta': 'beta',
    'prod': 'app',
}

export enum THEME {
    DEFAULT = "DEFAULT",
    DARK = "DARK"
}

export enum TRANSACTION_STATUS {
    ALL = "All",
    SUCCESS = "Sucess",
    PENDING = "Pending",
    FAILED = "Failed",
    REFUND = "Refund",
    VOID = "Void",
    EXPIRED = "Expired",
    OPEN = "Pending",
    COMPLETED = "Sucess"
}

export enum TX_FILTER {
    ALL = "A",
    SUCCESS = "S",
    PENDING = "P",
    FAILED = "F",
    CANCELLED = "C"
}