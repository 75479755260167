import { Divider, Box, Grid, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getSingleCustomer } from "../../services/axios/customer";
import { useAppSelector } from "../../store/hooks";
import "./customer.scss";
import goBackDeveloper from "../../assets/images/common/goBackDeveloper.svg";
import { useNavigate, useParams } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const statusMappings: { [x: string]: string } = {
    'A': 'Active',
    'I': 'Inactive',
    'S': 'Suspended',
    'T': 'Terminated',
    'U': 'Unverified',
    'P': 'Pending',
    'R': 'Rejected'
}

export default function CustomerDetails(props: any) {

    const params = useParams();

    //temporary
    const { functionToCall, editFunction } = props;

    const navigate = useNavigate();
    const goBack = () => {
        navigate("/customer")
    }
    const edit = () => {
        navigate(`/customer/${params.id}/edit`);
    }

    //table titles
    // const apiTitles = ['Date/Time', 'Error Message']
    // const webhookTitles = ['Date/Time', 'Event Data', 'Webhook attempts', 'Webhook response received']

    const [customerDetails, setCustomerDetails] = useState<any>(null)
    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)
    const [displaySkeleton, setDisplaySkeleton] = useState(true)

    const CustomerDetailsFunc = async (id: number) => {
        try {
            const res = await getSingleCustomer(id, sandboxMode)
            setCustomerDetails(res.data.data)
            setDisplaySkeleton(false)
        } catch (err) {
            goBack();
        }
    }

    useEffect(() => {
        if (params && params.id) CustomerDetailsFunc(parseInt(params.id));
    }, [])

    const root = document.getElementById('overall-content');

    useEffect(() => {
        function onScroll() {
            root?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            root?.removeEventListener('scroll', onScroll, true);
        }

        root?.addEventListener('scroll', onScroll, true);

        return () => {
            // root?.scrollTo(0, 0);
            root?.removeEventListener('scroll', onScroll, true);
        }
    }, []);

    useDidMountEffect(() => {
        goBack();
    }, [sandboxMode])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`customer-details-container ${themeState}-customer-details`}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
                <Box className="customer">
                    <img src={goBackDeveloper} alt={"Go Back"} onClick={goBack} style={{ cursor: "pointer" }} />
                    Customers
                </Box>
                <Box className="subtitle">View your Customers here</Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                {/* temporary */}
                {/* <Box mr={2} mt={2} style={{ fontWeight: 500, fontSize: "16px", cursor: "pointer" }} onClick={goBack}>
                    Go Back
                </Box> */}
                <Box mr={4} mt={2} style={{ fontWeight: 500, fontSize: "16px", color: "#43D9BB", cursor: "pointer" }} onClick={edit}>
                    Edit
                </Box>
            </Box>
        </Box>
        <Divider className="divider" />
        {displaySkeleton ? <Box>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
        </Box> :
            <Box>
                {customerDetails && <Box>
                    <Box className="basic-details">
                        <Box mt={3} mb={4} display="flex">
                            <Box className="bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">First Name</Box>
                                <Box className="value" mt={1}>{customerDetails.firstName ? customerDetails.firstName : "-"}</Box>
                            </Box>
                            <Box className="bounded-data" px={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Last Name</Box>
                                <Box className="value" mt={1}>{customerDetails.lastName ? customerDetails.lastName : "-"}</Box>
                            </Box>
                        </Box>
                        <Box my={2} mb={4} display="flex">
                            <Box className="bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Date of Birth</Box>
                                <Box className="value" mt={1}>{customerDetails.dateofBirth ?
                                    (new Date(customerDetails.dateofBirth)
                                        .toLocaleDateString
                                        (undefined,
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',

                                            })).toString() : "-"}</Box>
                            </Box>
                            <Box pl={3}>
                                <Box className="headings">Email</Box>
                                <Box className="value" mt={1}>{customerDetails.emailAddress ? customerDetails.emailAddress : "-"}</Box>
                            </Box>
                        </Box>
                        <Box my={2} display="flex">
                            <Box pr={3}>
                                <Box className="headings">Status</Box>
                                <Box className="value" mt={1}>{customerDetails.status ? statusMappings[customerDetails.status] : "-"}</Box>
                            </Box>
                        </Box>
                        <Divider className="divider" />
                    </Box>
                    <Box mb={2} mt={5} className="customer">
                        Address
                    </Box>
                    <Divider className="divider" />
                    <Box className="basic-details-second">
                        <Box className="value" mt={3} mb={4} display="flex">
                            <Box pr={3}>
                                <Box className="headings">Street</Box>
                                <Box mt={1}>{customerDetails.street ? customerDetails.street : "-"}</Box>
                            </Box>
                        </Box>
                        <Box mt={3} mb={4} display="flex">
                            <Box className="bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">City</Box>
                                <Box className="value" mt={1}>{customerDetails.city ? customerDetails.city : "-"}</Box>
                            </Box>
                            <Box className="bounded-data" px={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">State</Box>
                                <Box className="value" mt={1}>{customerDetails.state ? customerDetails.state : "-"}</Box>
                            </Box>
                        </Box>
                        <Box my={2} display="flex">
                            <Box className="bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Country</Box>
                                <Box className="value" mt={1}>{customerDetails.country ? customerDetails.country : "-"}</Box>
                            </Box>
                            <Box pl={3}>
                                <Box className="headings">Postal Code</Box>
                                <Box className="value" mt={1}>{customerDetails.postalCode ? customerDetails.postalCode : "-"}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>}
            </Box>}
    </Box >
}
