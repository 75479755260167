import { createSlice } from '@reduxjs/toolkit';

export interface Alternator {
    alternator: Boolean | null
}

const initialState: Alternator = {
    alternator: false
}

const popperSlice = createSlice({
    name: 'popper',
    initialState,
    reducers: {
        updatePopperStatus(state) {
            state.alternator = !state.alternator
        }
    }
})

export const { updatePopperStatus } = popperSlice.actions

export default popperSlice.reducer