import axios, { getJWT } from "./axios";

export const getAllWebhookLogs = async (pageNumber: number, sandboxMode: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/webhook-logs?limit=${10}&page=${pageNumber}&sandbox=${sandboxMode}`, header);
    return res;
}

export const getSingleWebhookLog = async (id: number, sandboxMode: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/webhook-logs/${id}?sandbox=${sandboxMode}`, header);
    return res;
}

export const getWebhookLogsWithinDateRange = async (start: string, end: string, pageNumber: number, sandboxMode: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/webhook-logs?limit=${10}&page=${pageNumber}&startDate=${start}&endDate=${end}&sandbox=${sandboxMode}`, header);
    console.log(res, "res from date range")
    return res;
}

export const getWebhookLogsTimeSeries = async (start: string, end: string, sandboxMode: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/webhook-logs/time-series?startDate=${start}&endDate=${end}&sandbox=${sandboxMode}`, header);
    return res;
}