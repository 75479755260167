import { Button, createTheme, Grid, TextField, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PASSWORD_PATTERN } from '../../../constants/validation';
import { userChangePassword } from '../../../services/axios/auth';
import { useAppSelector } from '../../../store/hooks';
import { updateIsResetPassword } from '../../../store/slices/app-slice';
import { success, error } from '../../../store/slices/messages-slice';
import { updateMsgState } from '../../../store/slices/messages-v2-slice';
import VisibilityOff from "../../../assets/images/icons/visible-eye-off.svg";
import Visibility from "../../../assets/images/icons/visible-eye.svg";
import FormErrorIcon from '../../../assets/images/icons/form-error-icon.svg';
import './password-form.scss';
import { BaseSyntheticEvent, useState } from 'react';
import { STATUS_CODE } from '../../../constants/general';

interface IResetPasswordFormProps {
  requireCurrentPassword?: boolean;
}

interface State {
  password: string;
  showPassword: boolean;
}

function ResetPasswordForm(props: IResetPasswordFormProps) {

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors
  } = useForm({
    mode: 'onChange',
  });

  const tempPassword = useAppSelector((state) => state.app.tempPassword);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const matches = useMediaQuery('(max-width:720px)');

  const onSubmit = async (data: any) => {
    try {
      const currentPassword = props.requireCurrentPassword
        ? data.currentPassword
        : tempPassword;
      const updatedPassword = data.confirmPassword;

      if (currentPassword === undefined) {
        dispatch(
          error({
            text: 'Missing current password',
          })
        );
      }
      const res = await userChangePassword(updatedPassword, currentPassword);
      if (res.status === STATUS_CODE.BAD_REQUEST) {
        // dispatch(
        //   error({
        //     text: res.data.errorMessage,
        //   })
        // );
        dispatch(updateMsgState({state: "error", title: res.data.errorMessage, attempt: true, isMain: false}));

        return;
      }
      dispatch(updateIsResetPassword({ isReset: false, tempPassword: '' }));
      // dispatch(
      //   success({
      //     text: 'Update Password Successful!',
      //   })
      // );
      dispatch(updateMsgState({state: "success", title: "Update Password Successful", attempt: true, isMain: false}));

      if (!props.requireCurrentPassword) {
        navigate('/dashboard');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [showVisible, setShowVisible] = useState(true);
  const [showVisible2, setShowVisible2] = useState(true);
  const [showVisible3, setShowVisible3] = useState(true)
  const handleChange = (event: BaseSyntheticEvent, target: number) => {

      switch(target) {
          case 1:
              if(event.target.value) {
                  setShowVisible(true)
                } else {
                  setShowVisible(false)
                }
              break;
          case 2:
              if(event.target.value) {
                  setShowVisible2(true)
                } else {
                  setShowVisible2(false)
                }
              break;
          case 3:
            if(event.target.value) {
                setShowVisible2(true)
              } else {
                setShowVisible2(false)
              }
            break;
      }
  };

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const [values2, setValues2] = useState<State>({
      password: '',
      showPassword: false,
  });

  const [values3, setValues3] = useState<State>({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = (target: number) => {
    switch(target) {
        case 1:
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
            break;
        case 2:
            setValues2({
                ...values2,
                showPassword: !values2.showPassword,
            });
            break;
        case 3:
          setValues3({
              ...values3,
              showPassword: !values3.showPassword,
          });
          break;
    }

};

  const onCancel = () => {
    setValue('currentPassword', null);
    setValue('password', null);
    setValue('confirmPassword', null);
    clearErrors()
  }

  return (
    <div className='password-container'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="form-container">
          <div className='form-flex'>
            {props.requireCurrentPassword && (

            <div id="input-container" className='input-standard' style={!matches ? { width: "100%" } : { width: "100%" }}>
              <span className="upper-label text-sm font-medium text-gray-700">
                  Current Password<span className='text-error-500'>*</span>
                  {/* <span className="aesterisk">*</span> */}
              </span>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter your current password"
                    variant="outlined"
                    fullWidth
                    {...register('currentPassword', {
                      required: 'Required',
                      onChange: (e) => handleChange(e, 1)
                    })}
                    error={!!errors?.currentPassword}
                    helperText={errors?.currentPassword?.message}
                    type={values.showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment:
                        <div className="hide-adorment">
                          {!!errors?.currentPassword &&
                            <div><img src={FormErrorIcon}/></div>
                          }
                          {(showVisible && errors?.currentPassword === undefined) &&
                            <div onClick={() => handleClickShowPassword(1)}>
                              {!values.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                            </div>
                          }
                        </div>
                    }}
                  />
              </div>
            )}

            <div id="input-container" className='input-standard' style={!matches ? { width: "100%" } : { width: "100%" }}>
              <span className="upper-label text-sm font-medium text-gray-700">
                  New Password<span className='text-error-500'>*</span>
                  {/* <span className="aesterisk">*</span> */}
              </span>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter your new password"
                  variant="outlined"
                  fullWidth
                  {...register('password', {
                    required: 'Required',
                    pattern: {
                      value: PASSWORD_PATTERN,
                      message:
                        'Password must be a combination of at least 1 uppercase, 1 lower case, 1 number and 1 special character with min length of 8',
                    },
                  })}
                  error={!!errors?.password}
                  helperText={errors?.password?.message}
                  type={values2.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment:
                      <div className="hide-adorment">
                        {!!errors?.password &&
                          <div><img src={FormErrorIcon}/></div>
                        }
                        {(showVisible && errors?.password === undefined) &&
                          <div onClick={() => handleClickShowPassword(2)}>
                            {!values2.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                          </div>
                        }
                      </div>
                  }}
                />
            </div>

            <div id="input-container" className='input-standard' style={!matches ? { width: "100%" } : { width: "100%" }}>
              <span className="upper-label text-sm font-medium text-gray-700">
                  Confirm Password<span className='text-error-500'>*</span>
                  {/* <span className="aesterisk">*</span> */}
              </span>
                <TextField
                  id="outlined-basic"
                  placeholder="Confirm your new password"
                  variant="outlined"
                  fullWidth
                  {...register('confirmPassword', {
                    required: 'Required',
                    validate: (val: string) => {
                      if (watch('password') !== val) {
                        return 'Password does not match';
                      }
                    },
                  })}
                  error={!!errors?.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  type={values3.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment:
                      <div className="hide-adorment">
                        {!!errors?.confirmPassword &&
                          <div><img src={FormErrorIcon}/></div>
                        }
                        {(showVisible && errors?.confirmPassword === undefined) &&
                          <div onClick={() => handleClickShowPassword(3)}>
                            {!values3.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                          </div>
                        }
                      </div>
                  }}
                />
            </div>
          </div>
        </Box>

        <div id="action-container">
        <Button
              variant="contained"
              type="button"
              className="cancel-changes-button text-sm font-medium text-gray-700 !w-[80px]"
              onClick={onCancel}
            >
              Cancel
        </Button>
        <Button
            variant="contained"
            type="submit"
            className="save-changes-button text-sm font-medium text-white !w-[80px]"
          >
              Save
        </Button>
        </div>
      </form>
    </div>
  );
}

export default ResetPasswordForm;
