import { THEME } from "../../../constants/general"
import { ReactComponent as DefaultModeIcon } from "./thirdfi-monochrome.icon.svg";
import { ReactComponent as GradientModeIcon } from "./thirdfi-primary.icon.svg";
import { useEffect, useState } from "react";

function CompanyLogo(state?: any) {
    const [theme, setTheme] = useState<JSX.Element>(<GradientModeIcon/>);

    const mode = state.mode;
    useEffect(() => {
        if(mode === THEME.DEFAULT) {
            setTheme(<GradientModeIcon/>);
        } else if(mode === THEME.DARK) {
            setTheme(<DefaultModeIcon/>);
        } 
    }, [mode])

    return theme
}

export default CompanyLogo