import { Divider, Box, Grid, Skeleton } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonTable from "../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../components/DeveloperToolsComponents/EmptyCard";
import { getSingleDeveloperTransaction } from "../../services/axios/transaction-listing";
import { useAppSelector } from "../../store/hooks";
import WebhookEventsDetails from "../DeveloperTools/WebhookEvents/WebhookEvents";
import "./transactionListingStyles.scss";
import goBackDeveloper from "../../assets/images/common/goBackDeveloper.svg";
import useDidMountEffect from "../../hooks/useDidMountEffect";

export const AAVE_ACTION_TYPE_PREFIX = 'AAVE_';
export const AAVE_SESSION_TYPE_PREFIX = 'aave_';
export const VENUS_ACTION_TYPE_PREFIX = 'VENUS_';
export const VENUS_SESSION_TYPE_PREFIX = 'venus_';

function TransactionDetails(props: any) {

    //table titles
    const headings = ['Date/Time', 'Webhook Attempts', 'Webhook Response Received']
    const themeState = useAppSelector((state) => state.theme.themeState);

    const ChainIdLabels: { [x: number]: string } = {
        1: 'Ethereum',
        4: 'Ethereum Testnet',
        43114: 'Avalanche',
        43113: 'Avalanche Testnet',
        56: 'BNB',
        97: 'BNB Testnet',
        137: 'Polygon',
        80001: 'Polygon Testnet',
        1313161554: 'Aurora',
        1313161555: 'Aurora Testnet',
        421613: 'Arbitrum Testnet',
        42161: 'Arbitrum',
        5: 'Goerli testnet',
        10: 'Optimism',
        420: 'Optimism Testnet',
        25: 'Cronos',
        338: 'Cronos Testnet',
        321: 'KCC Mainnet',
        322: 'KCC Testnet',
        65: 'OKX Testnet',
        66: 'OKX Chain',
        71: 'Conflux Testnet',
        1030: 'Conflux',
        91002: 'Nautilus Testnet',
    };

    const fillStatus: { [x: string]: string } = {
        "S": "Success",
        "P": "Pending",
        "F": "Failed",
        "R": "Refund",
        "V": "Void",
        "failed": "Failed",
        "expired": "Expired",
        "open": "Pending",
        "completed": "Success",
        "pending": "Pending"
    }

    const { functionToCall, ID } = props;
    const sandboxMode = useAppSelector(state => state.mode.sandboxMode);
    const [transactionsDetails, setTransactionsDetails] = useState<any>(null);
    const [transactionsDetailsLength, setTransactionsDetailsLength] = useState(0);
    const [contentRows, setContentRows] = useState<any[][]>([[]]);
    const [displayTable, setDisplayTable] = useState(false);
    const [IDWebhook, setIDWebhook] = useState(0);
    const [displayWebhookEvents, setDisplayWebhookEvents] = useState(false);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);
    const [queryId, setQueryId] = useState<number | any>();

    const navigate = useNavigate();
    const goBack = () => {
        navigate("/transaction-listing");
    }

    const root = document.getElementById('overall-content');

    useEffect(() => {
        function onScroll() {
            root?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            root?.removeEventListener('scroll', onScroll, true);
        }
        root?.addEventListener('scroll', onScroll, true);
        return () => {
            // root?.scrollTo(0, 0);
            root?.removeEventListener('scroll', onScroll, true);
        }
    }, []);

    useDidMountEffect(() => {
        goBack();
    }, [sandboxMode])

    const transactionDetailsFunc = async (id: number) => {
        try {
            const transaction = await getSingleDeveloperTransaction(id, sandboxMode)

            setTransactionsDetailsLength(transaction.data.data.transactions[1])
            setTransactionsDetails(transaction.data.data.transactions[0])

            const logsArray = transaction.data.data.webhookLogs;
            const resultArray: any[][] = [];

            if (logsArray.length > 0) setDisplayTable(true)

            logsArray.map((log: any, i: number) => {
                const arr = [];
                arr[0] = (new Date(log.createdDate)
                    .toLocaleDateString
                    (undefined,
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        })).toString();
                arr[1] = log.tries;
                arr[2] = log.responseCode;
                if (!log.responseCode) arr[2] = "No Response"
                arr[3] = log.id.toString();
                resultArray[i] = arr;
            })

            setContentRows(resultArray)
            setDisplaySkeleton(false)
        } catch (err) {
            goBack();
        }
    }

    const params = useParams();

    useEffect(() => {
        if (params && params.id) transactionDetailsFunc(parseInt(params.id))
    }, [])

    return <Box className={`transaction-details-container ${themeState}-basic-details`}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
                <Box className="transaction">
                    <img src={goBackDeveloper} alt={"Go Back"} onClick={goBack} style={{ cursor: "pointer" }} />
                    Transactions
                </Box>
                <Box className="text-sm font-normal text-gray-500">View your transactions here</Box>
            </Box>
            {/* <Box>
                <Box style={{ fontWeight: 500, fontSize: "16px", cursor: "pointer" }} onClick={goBack}>
                    Go Back
                </Box>
            </Box> */}
        </Box>
        <Divider className="divider" />
        {/* {transactionsDetails && transactionsDetailsLength && transactionsDetails[transactionsDetailsLength - 1].amount} */}
        {/* {transactionsDetails && console.log(transactionsDetails[transactionsDetailsLength - 1].amount)} */}
        {displaySkeleton ? <Box>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
        </Box> :
            <Box>
                {!displayWebhookEvents && <Box>
                    {transactionsDetails && transactionsDetailsLength && <><Box>
                        <Box className="basic-details">
                            <Box mb={2} mt={2}>
                                <Box className="headings">Amount</Box>
                                <Box className="first-row" mt={1}>
                                    {(() => {
                                        const log = transactionsDetails[transactionsDetailsLength - 1]
                                        let returnVal: any = parseFloat(log.amount).toFixed(8);

                                        if (log.actionType === "DEPOSIT" || log.actionType === "WITHDRAW") {
                                            if (log.session && log.product && log.product.symbol) {
                                                returnVal = parseFloat(log.amount).toFixed(8) + " " + log.product.symbol;
                                            } else {
                                                returnVal = parseFloat(log.amount).toFixed(8) + " " + log.currency
                                            }
                                        }
                                        else if (log.actionType === "SWAP") {
                                            if (log.session && log.session.params) {
                                                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    
                                                if (parsedParams.exactIn) {
                                                    returnVal = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"
                                                } else {
                                                    returnVal = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"
                                                }

                                            }
                                        }
                                        else if (log.actionType === "DEX_LIQUIDITY") {
                                            if (log.session && log.session.params) {
                                                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                                                let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                                                let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                                                let amount0Token = parsedParams.pair.split("-")[0];
                                                let amount1Token = parsedParams.pair.split("-")[1];

                                                returnVal = <div className="multi-amount text-base font-medium text-gray-900">
                                                    {`Amount0: ${amount0} ${amount0Token}`}
                                                    <br></br>
                                                    {`Amount1: ${amount1} ${amount1Token}`}
                                                </div>
                                            }
                                        }
                                        else if (log.actionType === "DEX_LIQUIDITY_2") {
                                            if (log.session && log.session.params) {
                                                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                                                let amount = parseFloat(parsedParams.amount).toFixed(12);
                                                if (parsedParams.addLiquidity) {
                                                    returnVal = <div className="multi-amount text-base font-medium text-gray-900">
                                                        {`${amount} ${parsedParams.inputTokenSymbol}`}
                                                    </div>
                                                } else {
                                                    returnVal = <div className="multi-amount text-base font-medium text-gray-900">
                                                        {`${amount} ${parsedParams.pair} LP`}
                                                    </div>
                                                }

                                            }
                                        }
                                        else if (log.actionType === "APPROVE") {
                                            if (log.session && log.session.params) {
                                                const parsedParams = (typeof log.session.params === 'string') ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params : log.session.params
                                                if (log.session.type === "deposit" || log.session.type === "withdraw") {
                                                    returnVal = parseFloat(log.session.amount).toFixed(8) + " " + log.product.symbol;
                                                } else if (parsedParams.exactIn === true) {
                                                    returnVal = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"
                                                } else if (parsedParams.exactIn === false) {
                                                    returnVal = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"
                                                } else if (log.session.type == "dex_liquidity_2" && (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false)) {
                                                    let amount = parseFloat(parsedParams.amount).toFixed(12);
                                                    if (parsedParams.addLiquidity) {
                                                        returnVal = <div className="multi-amount text-base font-semibold text-gray-900">
                                                            {`${amount} ${parsedParams.inputTokenSymbol}`}
                                                        </div>
                                                    } else {
                                                        returnVal = <div className="multi-amount text-base font-semibold text-gray-900">
                                                            {`${amount} ${parsedParams.pair} LP`}
                                                        </div>
                                                    }
                                                } else if (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false) {
                                                    let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                                                    let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                                                    let amount0Token = parsedParams.pair.split("-")[0];
                                                    let amount1Token = parsedParams.pair.split("-")[1];

                                                    returnVal = <div className="multi-amount text-base font-semibold text-gray-900">
                                                        {`Amount0: ${amount0} ${amount0Token}`}
                                                        <br></br>
                                                        {`Amount1: ${amount1} ${amount1Token}`}
                                                    </div>
                                                }
                                            }
                                        }
                                        else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                                            if (log.session && log.session.params) {
                                                const parsedParams = log.session.params;
                                                const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                                if (log.actionType === "AAVE_SUPPLY") {
                                                    returnVal = `${parsedParams.amount} ${parsedParams.symbol} (Supply)`;
                                                } else if (log.actionType === "AAVE_WITHDRAW") {
                                                    const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                    returnVal = `${parsedParams.amount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.amount} ${parsedParams.symbol} (Withdraw)`;
                                                } else if (log.actionType === "AAVE_BORROW") {
                                                    returnVal = `${parsedParams.amount} ${parsedParams.symbol} (Borrow)`;
                                                } else if (log.actionType === "AAVE_REPAY") {
                                                    const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                    returnVal = `${parsedParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.amount} ${parsedParams.symbol} (Repay)`;
                                                } else if (log.actionType === "AAVE_SUPPLYANDBORROW") {
                                                    returnVal = `${parsedParams.supplyAmount} ${parsedParams.supplySymbol} (Supply), ${parsedParams.borrowAmount} ${parsedParams.borrowSymbol} (Borrow)`;
                                                } else if (log.actionType === "AAVE_REPAYANDWITHDRAW") {
                                                    const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                    const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                    returnVal = `${parsedParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.repayAmount} ${parsedParams.repaySymbol} (Repay)`
                                                        + `, ${parsedParams.withdrawalAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.withdrawalAmount} ${parsedParams.withdrawalSymbol} (Withdraw)`;
                                                } else if (log.actionType === "AAVE_SWAPBORROWRATEMODE") {
                                                    const fromMode = (parsedParams.rateMode === 1) ? 'Stable' : 'Variable';
                                                    const toMode = (parsedParams.rateMode === 1) ? 'Variable' : 'Stable';
                                                    returnVal = `Swaps user\'s borrow rate mode from ${fromMode} to ${toMode} on ${parsedParams.symbol}`
                                                } else if (log.actionType === "AAVE_CLAIMREWARDS") {
                                                    returnVal = 'Claim rewards';
                                                }
                                            }
                                        }
                                        else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                                            if (log.session && log.session.params) {
                                                const parsedParams = log.session.params;
                                                const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                                if (log.actionType === "VENUS_SUPPLY") {
                                                    returnVal = `${parsedParams.amount} ${parsedParams.symbol} (Supply)`;
                                                } else if (log.actionType === "VENUS_WITHDRAW") {
                                                    const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                    returnVal = `${parsedParams.vTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.vTokenAmount} ${parsedParams.vTokenSymbol} (Withdraw)`;
                                                } else if (log.actionType === "VENUS_BORROW") {
                                                    returnVal = `${parsedParams.amount} ${parsedParams.symbol} (Borrow)`;
                                                } else if (log.actionType === "VENUS_REPAY") {
                                                    const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                    returnVal = `${parsedParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.amount} ${parsedParams.symbol} (Repay)`;
                                                } else if (log.actionType === "VENUS_REPAYANDWITHDRAW") {
                                                    const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                    const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                    returnVal = `${parsedParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.repayAmount} ${parsedParams.repaySymbol} (Repay)`
                                                        + `, ${parsedParams.withdrawalVTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.withdrawalVTokenAmount} ${parsedParams.withdrawalVTokenSymbol} (Withdraw)`;
                                                } else if (log.actionType === "VENUS_CLAIMREWARDS") {
                                                    returnVal = 'Claim rewards';
                                                }
                                            }
                                        }

                                        return returnVal;
                                    })()}
                                </Box>
                            </Box>
                            <Divider className="divider" />
                            <Box className="general-details" my={3} display="flex">
                                <Box className="bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Box className="headings">Date/Time</Box>
                                    <Box className="second-row" mt={1}>{
                                        (new Date(transactionsDetails[transactionsDetailsLength - 1].createAt)
                                            .toLocaleDateString
                                            (undefined,
                                                {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hour12: true
                                                })).toString()}
                                    </Box>
                                </Box>
                                <Box className="on-responsive bounded-data" px={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Box className="headings">Customer</Box>
                                    <Box className="second-row" mt={1}>{
                                        transactionsDetails[transactionsDetailsLength - 1]?.user?.emailAddress ?
                                            transactionsDetails[transactionsDetailsLength - 1].user.emailAddress :
                                            "-"
                                    }
                                    </Box>
                                </Box>
                                <Box className="on-responsive" pl={3}>
                                    <Box className="headings">Network Chain</Box>
                                    {transactionsDetails[transactionsDetailsLength - 1].chainId ?
                                        <Box className="second-row" mt={1}>
                                            {transactionsDetails[transactionsDetailsLength - 1].chainId + " " +
                                                ChainIdLabels[transactionsDetails[transactionsDetailsLength - 1].chainId]}
                                        </Box> : <Box className="second-row" mt={1}>
                                            -
                                        </Box>}
                                </Box>
                            </Box>
                            <Divider className="divider" />
                            <Box className="of-send-or-receive-wallet" my={2} display="flex">
                                <Box className="sender bounded-data" pr={3} style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                    <Box className="headings">Sender Wallet Address</Box>
                                    <Box className="wallet-address" style={{ wordBreak: "break-word" }} mt={1}>
                                        {(() => {
                                            const log = transactionsDetails[transactionsDetailsLength - 1]
                                            let returnVal = "_";

                                            if (log.transactionCategory === "WALLET") {
                                                returnVal = "-"
                                                return returnVal;
                                            }

                                            if (log.actionType === "WITHDRAW") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.event.address;
                                                }
                                            }
                                            else if (log.actionType === "DEPOSIT") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.sender;
                                                }
                                            }
                                            else if (log.actionType === "SWAP") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.to;
                                                }
                                            }
                                            else if (log.actionType === "DEX_LIQUIDITY" || log.actionType === "DEX_LIQUIDITY_2") {
                                                if (log.session && log.session.params) {
                                                    const parsedData = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                                                    returnVal = parsedData.userAddress;
                                                }
                                            }
                                            else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)
                                                || log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)
                                            ) {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data);
                                                    returnVal = parsedData.walletAddress;
                                                }
                                            }

                                            return returnVal;
                                        })()}

                                    </Box>
                                </Box>
                                <Box className="receiver" pl={3}>
                                    <Box className="headings">Receiver Wallet Address</Box>
                                    <Box className="wallet-address" mt={1}>
                                        {(() => {
                                            const log = transactionsDetails[transactionsDetailsLength - 1]
                                            let returnVal = "_";

                                            if (log.transactionCategory === "WALLET") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.walletAddress;
                                                    return returnVal;
                                                }
                                            }

                                            if (log.actionType === "DEPOSIT") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.event.address;
                                                }
                                            }
                                            else if (log.actionType === "WITHDRAW") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.sender;
                                                }
                                            }
                                            else if (log.actionType === "SWAP") {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data)
                                                    returnVal = parsedData.to;
                                                }
                                            }
                                            else if (log.actionType === "DEX_LIQUIDITY" || log.actionType === "DEX_LIQUIDITY_2") {
                                                if (log.session && log.session.params) {
                                                    const parsedData = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                                                    returnVal = parsedData.userAddress;
                                                }
                                            }
                                            else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)
                                                || log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)
                                            ) {
                                                if (log.data) {
                                                    const parsedData = JSON.parse(log.data);
                                                    returnVal = parsedData.walletAddress;
                                                }
                                            }

                                            return returnVal;
                                        })()}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider className="divider" />
                        </Box>
                        {transactionsDetails[transactionsDetailsLength - 1].payment ? <Box className="timeline-container" mt={2}>
                            <Box mb={2} mt={4} className="timeline">Timeline</Box>
                            <Divider className="divider" />
                            {transactionsDetails.map((tr: any, i: number) => {
                                return (
                                    <Box mt={2} key={i}>
                                        <Box className="headings">{JSON.parse(tr.data).orderStatus}</Box>
                                        <Box className="sub-headings">
                                            {
                                                new Date(JSON.parse(tr.data).createdDate)
                                                    .toLocaleDateString
                                                    (undefined,
                                                        {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: true
                                                        }).toString()
                                            }
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                            :
                            <Box className="timeline-container" my={2}>
                                <Box mb={2} mt={4} className="timeline">Timeline</Box>
                                <Divider className="divider" />
                                {transactionsDetails.map((tr: any, i: number) => {
                                    return (
                                        <Box mt={2} key={i}>
                                            <Box className="headings">{
                                                (() => {
                                                    let params = typeof tr.session.params == 'string' ? JSON.parse(tr.session.params) : tr.session.params
                                                    if (tr.actionType == "DEX_LIQUIDITY" || tr.actionType == "DEX_LIQUIDITY_2") {
                                                        return params.addLiquidity  ? "Add Liquidity" : "Remove Liquidity"
                                                    } else {
                                                        return tr.actionType
                                                    }
                                                })()
                                            }</Box>
                                            <Box className="sub-headings">
                                                {
                                                    new Date(tr.createAt)
                                                        .toLocaleDateString
                                                        (undefined,
                                                            {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                                hour12: true
                                                            }).toString()
                                                }
                                            </Box>
                                            <Box className="sub-headings" mt={1}>
                                                <Box mr={1} className="tx-hash-heading" style={{ "overflowWrap": "break-word" }}>Transaction hash:</Box>
                                                <a href={tr.explorerURL} target="_blank" style={{ "overflowWrap": "break-word", }}>
                                                    {
                                                        tr.hash
                                                    }
                                                </a>
                                            </Box>
                                        </Box>)
                                })}
                            </Box>}

                        {transactionsDetails[transactionsDetailsLength - 1].payment ? <Box className="payment-details" mt={2}>
                            <Divider className="divider" />
                            <Box mb={2} mt={4} className="payment">Payment Details</Box>
                            <Divider className="divider" />
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={3}>Fiat Amount</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" mt={3}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].payment.fiatAmount).toFixed(2)} {transactionsDetails[transactionsDetailsLength - 1].payment.fiatCurrencyUsed}</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={2}>Crypto Amount</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" mt={2}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].payment.cryptoAmount).toFixed(8)} {transactionsDetails[transactionsDetailsLength - 1].payment.tokenUsed}</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={2}>Amount (In USD)</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" mt={2}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].payment.usdAmount).toFixed(2)} USD</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={2}>{transactionsDetails[transactionsDetailsLength - 1].payment.tokenUsed} - USD rate</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" mt={2}>{
                                        parseFloat(transactionsDetails[transactionsDetailsLength - 1].payment.usdAmount) /
                                        parseFloat(transactionsDetails[transactionsDetailsLength - 1].payment.cryptoAmount)
                                        + " USD"
                                    }
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" my={2}>Status</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" my={2}>{fillStatus[transactionsDetails[transactionsDetailsLength - 1].payment.status]}</Box>
                                </Grid>
                            </Grid>
                            {/* <Grid container>
                            <Grid xs={3} item>
                                <Box className="headings" my={2}>Product</Box>
                            </Grid>
                            <Grid xs={6} item>
                                <Box className="sub-headings" my={2}>{transactionsDetails[transactionsDetailsLength - 1].product.name}</Box>
                            </Grid>
                            </Grid> */}
                        </Box> : <Box className="payment-details" mt={2}>
                            <Divider className="divider" />
                            <Box mb={2} mt={4} className="payment">Payment Details</Box>
                            <Divider className="divider" />
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={3}>Amount</Box>
                                </Grid>
                                {(() => {
                                    if (transactionsDetails[transactionsDetailsLength - 1].session && transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity") {
                                        const params = transactionsDetails[transactionsDetailsLength - 1].session.params;
                                        const parsedParams = typeof params === "string" ? JSON.parse(params) : params;
                                        let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                                        let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                                        let amount0Token = parsedParams.pair.split("-")[0];
                                        let amount1Token = parsedParams.pair.split("-")[1];
                                        return <Grid xs={6} item>
                                            <Box className="sub-headings" mt={3}>
                                                <div>
                                                    {`Amount0: ${amount0} ${amount0Token}`}
                                                    <br></br>
                                                    {`Amount1: ${amount1} ${amount1Token}`}
                                                </div>
                                            </Box>
                                        </Grid>
                                    }

                                    if (transactionsDetails[transactionsDetailsLength - 1].session && transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity_2") {
                                        const params = transactionsDetails[transactionsDetailsLength - 1].session.params;
                                        const parsedParams = typeof params === "string" ? JSON.parse(params) : params;
                                        //TODO check parsed values
                                        // let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                                        // let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                                        // let amount0Token = parsedParams.pair.split("-")[0];
                                        // let amount1Token = parsedParams.pair.split("-")[1];
                                        let amount = parseFloat(parsedParams.amount).toFixed(12);
                                        if (parsedParams.addLiquidity) {
                                            return <Grid xs={6} item>
                                                <Box className="sub-headings" mt={3}>
                                                    <div>
                                                        {`${amount} ${parsedParams.inputTokenSymbol}`}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        } else {
                                            return <Grid xs={6} item>
                                                <Box className="sub-headings" mt={3}>
                                                    <div>
                                                        {`${amount} ${parsedParams.pair} LP`}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        }
                                    }

                                    if (transactionsDetails[transactionsDetailsLength - 1].session && transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_swap") {
                                        const params = transactionsDetails[transactionsDetailsLength - 1].session.params
                                        const parsedParams = typeof params == 'string' ? JSON.parse(params) : params
                                        if (parsedParams.exactIn) {
                                            return <Box className="sub-headings" mt={3}>
                                                {parseFloat(transactionsDetails[transactionsDetailsLength - 1].session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}
                                            </Box>
                                        } else {
                                            return <Box className="sub-headings" mt={3}>
                                                {parseFloat(transactionsDetails[transactionsDetailsLength - 1].session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}
                                            </Box>
                                        }
                                    }

                                    if (transactionsDetails[transactionsDetailsLength - 1].actionType === "DEPOSIT" || transactionsDetails[transactionsDetailsLength - 1].actionType === "WITHDRAW") {
                                        if (transactionsDetails[transactionsDetailsLength - 1].session && transactionsDetails[transactionsDetailsLength - 1].product && transactionsDetails[transactionsDetailsLength - 1].product.symbol) {
                                            return <Box className="sub-headings" mt={3}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].session.amount).toFixed(8) + " " + transactionsDetails[transactionsDetailsLength - 1].product.symbol}</Box>
                                        } else {
                                            return <Box className="sub-headings" mt={3}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].session.amount).toFixed(8) + " " + transactionsDetails[transactionsDetailsLength - 1].currency}</Box>
                                        }
                                    }

                                    const log = transactionsDetails[transactionsDetailsLength - 1];
                                    if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                                        let value = '';
                                        if (log.session && log.session.params) {
                                            const parsedParams = log.session.params;
                                            const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                            if (log.actionType === "AAVE_SUPPLY") {
                                                value = `${parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "AAVE_WITHDRAW") {
                                                const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                value = `${parsedParams.amount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "AAVE_BORROW") {
                                                value = `${parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "AAVE_REPAY") {
                                                const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                value = `${parsedParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "AAVE_SUPPLYANDBORROW") {
                                                value = `${parsedParams.supplyAmount} ${parsedParams.supplySymbol} (Supply), ${parsedParams.borrowAmount} ${parsedParams.borrowSymbol} (Borrow)`;
                                            } else if (log.actionType === "AAVE_REPAYANDWITHDRAW") {
                                                const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                value = `${parsedParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.repayAmount} ${parsedParams.repaySymbol} (Repay)`
                                                    + `, ${parsedParams.withdrawalAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.withdrawalAmount} ${parsedParams.withdrawalSymbol} (Withdraw)`;
                                            }
                                        }
                                        return <Grid xs={6} item>
                                            <Box className="sub-headings" mt={3}>{value}</Box>
                                        </Grid>
                                    }
                                    else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                                        let value = '';
                                        if (log.session && log.session.params) {
                                            const parsedParams = log.session.params;
                                            const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                            if (log.actionType === "VENUS_SUPPLY") {
                                                value = `${parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "VENUS_WITHDRAW") {
                                                const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                value = `${parsedParams.vTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.vTokenAmount} ${parsedParams.vTokenSymbol}`;
                                            } else if (log.actionType === "VENUS_BORROW") {
                                                value = `${parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "VENUS_REPAY") {
                                                const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                value = `${parsedParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.amount} ${parsedParams.symbol}`;
                                            } else if (log.actionType === "VENUS_REPAYANDWITHDRAW") {
                                                const estimatedAmountToRepay = transactionData?.estimatedAmountToRepay;
                                                const estimatedAmountToWithdraw = transactionData?.estimatedAmountToWithdraw;
                                                value = `${parsedParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : parsedParams.repayAmount} ${parsedParams.repaySymbol} (Repay)`
                                                    + `, ${parsedParams.withdrawalVTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : parsedParams.withdrawalVTokenAmount} ${parsedParams.withdrawalVTokenSymbol} (Withdraw)`;
                                            }
                                        }
                                        return <Grid xs={6} item>
                                            <Box className="sub-headings" mt={3}>{value}</Box>
                                        </Grid>
                                    }

                                    return <Grid xs={6} item>
                                        <Box className="sub-headings" mt={3}>{parseFloat(transactionsDetails[transactionsDetailsLength - 1].session.amount).toFixed(8)}</Box>
                                    </Grid>
                                })()}
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={3}>Amount (In USD)</Box>
                                </Grid>
                                <Grid xs={6} item>
                                {(() => {
                                    const log = transactionsDetails[transactionsDetailsLength - 1];
                                    let value = `${parseFloat(log.session.usdAmount).toFixed(2)} USD`;
                                    if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                                        const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                        if (log.actionType === "AAVE_SUPPLY") {
                                            value = (transactionData.supplyValueInUSD !== undefined) ? `${parseFloat(transactionData.supplyValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "AAVE_WITHDRAW") {
                                            value = (transactionData.withdrawalValueInUSD !== undefined) ? `${parseFloat(transactionData.withdrawalValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "AAVE_BORROW") {
                                            value = (transactionData.borrowValueInUSD !== undefined) ? `${parseFloat(transactionData.borrowValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "AAVE_REPAY") {
                                            value = (transactionData.repayValueInUSD !== undefined) ? `${parseFloat(transactionData.repayValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "AAVE_SUPPLYANDBORROW") {
                                            const value1 = (transactionData.supplyValueInUSD !== undefined) ? `${parseFloat(transactionData.supplyValueInUSD).toFixed(2)} USD (Supply)` : '';
                                            const value2 = (transactionData.borrowValueInUSD !== undefined) ? `${parseFloat(transactionData.borrowValueInUSD).toFixed(2)} USD (Borrow)` : '';
                                            value = `${value1}, ${value2}`;
                                        } else if (log.actionType === "AAVE_REPAYANDWITHDRAW") {
                                            const value1 = (transactionData.repayValueInUSD !== undefined) ? `${parseFloat(transactionData.repayValueInUSD).toFixed(2)} USD (Repay)` : '';
                                            const value2 = (transactionData.withdrawalValueInUSD !== undefined) ? `${parseFloat(transactionData.withdrawalValueInUSD).toFixed(2)} USD (Withdraw)` : '';
                                            value = `${value1}, ${value2}`;
                                        } else if (log.actionType === "AAVE_SWAPBORROWRATEMODE") {
                                            value = '';
                                        } else if (log.actionType === "AAVE_CLAIMREWARDS") {
                                            value = (transactionData.rewardsValueInUSD !== undefined) ? `${parseFloat(transactionData.rewardsValueInUSD).toFixed(2)} USD` : '';
                                        }
                                    }
                                    else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                                        const transactionData = log.data ? JSON.parse(log.data) : undefined;
                                        if (log.actionType === "VENUS_SUPPLY") {
                                            value = (transactionData.supplyValueInUSD !== undefined) ? `${parseFloat(transactionData.supplyValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "VENUS_WITHDRAW") {
                                            value = (transactionData.withdrawalValueInUSD !== undefined) ? `${parseFloat(transactionData.withdrawalValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "VENUS_BORROW") {
                                            value = (transactionData.borrowValueInUSD !== undefined) ? `${parseFloat(transactionData.borrowValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "VENUS_REPAY") {
                                            value = (transactionData.repayValueInUSD !== undefined) ? `${parseFloat(transactionData.repayValueInUSD).toFixed(2)} USD` : '';
                                        } else if (log.actionType === "VENUS_REPAYANDWITHDRAW") {
                                            const value1 = (transactionData.repayValueInUSD !== undefined) ? `${parseFloat(transactionData.repayValueInUSD).toFixed(2)} USD (Repay)` : '';
                                            const value2 = (transactionData.withdrawalValueInUSD !== undefined) ? `${parseFloat(transactionData.withdrawalValueInUSD).toFixed(2)} USD (Withdraw)` : '';
                                            value = `${value1}, ${value2}`;
                                        } else if (log.actionType === "VENUS_CLAIMREWARDS") {
                                            value = (transactionData.rewardsValueInUSD !== undefined) ? `${parseFloat(transactionData.rewardsValueInUSD).toFixed(2)} USD` : '';
                                        }
                                    }
                                    return <Box className="sub-headings" mt={3}>{value}</Box>
                                })()}
                                </Grid>
                            </Grid>
                            {(() => {
                                if (transactionsDetails[transactionsDetailsLength - 1].session && (
                                    transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity"
                                    || transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity_2"
                                ))
                                    return <Grid container>
                                        <Grid xs={3} item>
                                            <Box className="headings" mt={3}>LP Amount</Box>
                                        </Grid>
                                        {(() => {
                                            if (transactionsDetails[transactionsDetailsLength - 1].session && (
                                                transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity"
                                                || transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity_2")
                                            ) {
                                                const params = transactionsDetails[transactionsDetailsLength - 1].session.params;
                                        const parsedParams = typeof params === "string" ? JSON.parse(params) : params;
                                                if (parsedParams.addLiquidity) {
                                                    return <Grid xs={6} item>
                                                        <Box className="sub-headings" mt={3}>
                                                            <div>
                                                                {transactionsDetails[transactionsDetailsLength - 1].session.amount + " "
                                                                    + parsedParams.pair + " LP"}
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                } else {
                                                    return <Grid xs={6} item>
                                                        <Box className="sub-headings" mt={3}>
                                                            <div>
                                                            {(transactionsDetails[transactionsDetailsLength - 1].session.type === "dex_liquidity" ? 
                                                                transactionsDetails[transactionsDetailsLength - 1].session.amount : parsedParams.amount) + " "
                                                                    + parsedParams.pair + " LP"}
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                }
                                            }
                                        })()}
                                    </Grid>
                            }
                            )()}
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" mt={2}>Status</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" mt={2}>{fillStatus[transactionsDetails[transactionsDetailsLength - 1].session.status]}</Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid xs={3} item>
                                    <Box className="headings" my={2}>Product</Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box className="sub-headings" my={2}>
                                        {
                                            transactionsDetails[transactionsDetailsLength - 1].product.name ?
                                                transactionsDetails[transactionsDetailsLength - 1].product.name :
                                                transactionsDetails[transactionsDetailsLength - 1].product.symbol
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>}</Box>
                        <Divider className="divider" />
                        <Box className="api-log-container">
                            {!displayWebhookEvents && <Box>
                                <Box mb={2} mt={5} className="api-log">Webhook Event</Box>
                                <Divider className="divider" />
                                {!displayTable && <Box my={3}>
                                    <EmptyCard title="No associated webhook logs" />
                                </Box>}
                                {displayTable && <Box my={3}>
                                    <CommonTable
                                        headings={headings}
                                        rows={contentRows}
                                        displaySearchIcon={true}
                                        hasRowId={true}
                                        setID={setIDWebhook}
                                        functionToCall={setDisplayWebhookEvents}
                                        navigationURL="/webhook-events"
                                    />
                                </Box>}
                            </Box>}
                        </Box>
                    </>
                    }
                </Box>}
                {displayWebhookEvents && <Box mt={2} p={2}>
                    <WebhookEventsDetails functionToCall={setDisplayWebhookEvents} ID={IDWebhook} />
                </Box>}
            </Box>}
    </Box >
}

export default TransactionDetails;
