import {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
  githubAuthProvider,
  twitterAuthProvider,
  microsoftAuthProvider,
} from '../../services/firebase';
import {
  signInWithPopup,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import GoogleIcon from '../../assets/images/login/google.svg';
import FacebookIcon from '../../assets/images/login/facebook.svg';
import TwitterIcon from '../../assets/images/login/twitter.svg';
import GithubIcon from '../../assets/images/login/github.svg';
// import MicrosoftIcon from '../../assets/images/login/microsoft.svg';
import { exchangeSocialToken } from '../../services/axios/user';
import { useAuth } from '../../services/auth/authProvider';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { error } from '../../store/slices/messages-slice';

interface IAuth {
  [index: string]: any;
}

interface ISocialLoginProps {
  vertical?: boolean;
}

export default function SocialLogins({ vertical }: ISocialLoginProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState('');
  useEffect(() => {
    const code = searchParams.get('referral');
    if (code) setReferralCode(code);
    return () => {};
  }, []);

  const { setLoginSessionStorage } = useAuth();

  const socialSignIn = async (e: any, platform: string) => {
    e.preventDefault();
    platform = platform.toLowerCase();
    const authProviders: IAuth = {
      google: { instance: googleAuthProvider, provider: GoogleAuthProvider },
      facebook: {
        instance: facebookAuthProvider,
        provider: FacebookAuthProvider,
      },
      twitter: { instance: twitterAuthProvider, provider: TwitterAuthProvider },
      github: { instance: githubAuthProvider, provider: GithubAuthProvider },
      microsoft: { instance: microsoftAuthProvider, provider: OAuthProvider },
    };

    const authObject = authProviders[platform];
    if (!authObject) {
      console.error(`${platform} login not supported`);
      return;
    }

    signInWithPopup(auth, authObject.instance)
      .then(async (result) => {
        if (!result.user.emailVerified) {
          await result.user.reload();
        }
        
        if (result.user) {
          const postToBackend = async () => {
            const currentUser = auth.currentUser;
            if (!currentUser) return;
            const idToken = await currentUser.getIdToken(false);
            return await exchangeSocialToken(idToken, referralCode);
          };

          const res = await postToBackend();
          if (typeof res === 'string') {
            dispatch(
              error({
                text: res,
                error: 'Something wrong',
              }),
            );
            return;
          }

          if (res && res.data && res.data.data) {
            setLoginSessionStorage(res.data.data);
            navigate('/', { replace: true });
          }
        }
      })
      .catch((err) => {
        const errCode = err ? err.code : null;
        switch (errCode) {
          case 'auth/account-exists-with-different-credential':
            dispatch(
              error({
                text: `You've previously logged with another social login. Please try another social login.`,
                error: 'Something wrong',
              }),
            );
            return;

          case 'auth/popup-closed-by-user':
            return;

          default:
            dispatch(
              error({
                text: `Error in logging in. Please contact support.`,
                error: 'Something wrong',
              }),
            );
            console.error('Error in firebase social login ', err);
            return;
        }
      });
  };

  const platforms = [
    { name: 'Google', icon: GoogleIcon },
    { name: 'Facebook', icon: FacebookIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Github', icon: GithubIcon },
  ];

  return (
    <>
      {vertical ? (
        <div className="flex flex-col gap-6 justify-center max-w-md">
          {platforms.map((platform) => {
            return (
              <div
                key={platform.name}
                className="mounted-social flex gap-6 items-center cursor-pointer rounded-md border border-gray-300 border-solid  px-6 py-3"
                onClick={(e) => socialSignIn(e, platform.name)}
              >
                <img
                  src={platform.icon}
                  width="30px"
                />
                <div className="text-gray-500 text-md">
                  Continue with {platform.name}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex gap-12 justify-center">
          {platforms.map((platform) => {
            return (
              <button key={platform.name} onClick={(e) => socialSignIn(e, platform.name)}>
                <img
                  src={platform.icon}
                  width="40px"
                />
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
