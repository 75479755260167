import { useEffect, useRef, useState } from 'react';

import './header.scss';
import '../app.scss';

import useWindowSize from '../../hooks/window';

import MobileMenu, { IMobileMenuProps } from '../MobileMenu';
import { IconButton, useMediaQuery } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { useAuth } from '../../services/auth/authProvider';

import { Avatar, Typography, AppBar, Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import menu from '../../constants/menu';
import { ReactComponent as LightMode } from '../../assets/images/icons/light-switch.svg';
import { ReactComponent as ReferralIcon } from '../../assets/images/icons/referral.svg';
import { ReactComponent as AccountIcon } from '../../assets/images/icons/account.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout.svg';
import { ReactComponent as SettingDropDownGearIcon } from '../../assets/images/icons/setting-dropdown-gear.svg';

import GobackIcon from '../../assets/images/icons/go-back-arrow.svg';
import HumanIcon from '../../assets/images/icons/default-profile.svg';
import MenuIconSecuro from '../../assets/images/icons/MenuIconSecuroV2.svg';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUnreadUserNotification } from '../../services/axios/userNotification';

import { updateModeStatus } from '../../store/slices/mode-toggle-slice';
import { updateInactivityStatus } from '../../store/slices/inactivity-slice';
import { updatePortalStatus } from '../../store/slices/portal-toggle-slice';
import { updateLogoutState } from '../../store/slices/logout-slice';
import { THEME } from '../../constants/general';
import { enableLoader, updateThemeState } from '../../store/slices/theming-slice';
import CompanyLogo from '../../assets/images/company';

const useOutsideClick = (callback: any) => {
  // Menu item expand disclose
  const ref = useRef();
  useEffect(() => {
    const handleClick = (event: any) => {
      callback();
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);
  return ref;
};

function Header() {
  const windowSize = useWindowSize();
  const [scroll, setScroll] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 720px)');

  const [showStakedIcon, setShowStakedIcon] = useState(false);
  const [openSide, setOpenSide] = useState(false);

  const { user, logout } = useAuth();
  const [username, setUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [parentPath, setParentPath] = useState('');
  const [isChildrenPage, setIsChildrenPage] = useState(false);
  const ignoredChildTransform = ["api-logging", "webhook-events", "transaction-listing", "customer"];

  const [profilePicDisplayFile, setProfilePicDisplayFile] =
    useState<any>(HumanIcon);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useAppDispatch();

  //notification
  // const [showZero, setShowZero] = useState(false);

  //notification
  // async function notificationsToDisplay() {
  //   const result = await getUnreadUserNotification();

  //   if (result.data.data.length === 0) {
  //     setShowZero(false);
  //   } else {
  //     setShowZero(true);
  //   }
  // }

  //notification
  // const showBellIconBadge = useAppSelector(
  //   (state) => state.bellIconBadge.showBellIconBadge,
  // );

  //notification
  // useEffect(() => {
  //   if (user) {
  //     notificationsToDisplay();
  //   }
  // }, [showBellIconBadge, user]);

  const userData = useAppSelector((state) => state.user.userData);
  const themeState = useAppSelector((state) => state.theme.themeState);

  useEffect(() => {
    if (
      userData !== undefined &&
      userData.userPhotoUrl !== undefined &&
      userData.userPhotoUrl !== null &&
      userData.userPhotoUrl !== ''
    ) {
      setProfilePicDisplayFile(userData.userPhotoUrl);
      if (userData.firstName) setUsername(userData.firstName);
    } else {
      setProfilePicDisplayFile(HumanIcon);
      //if (userData.firstName) setUsername(userData.firstName)
    }
  }, [userData]);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      const parsedUser = user;
      setUsername(parsedUser.name ? parsedUser.name : parsedUser.emailAddress);
    }
    setIsAuthenticated(!!user);
  }, [user]);

  // Listen to window size change
  useEffect(() => {
    const { width } = windowSize;

    if (width !== undefined && width <= 720) {
      setShowStakedIcon(true);
    } else {
      setShowStakedIcon(false);
      setOpenSide(false);
    }
  }, [windowSize]);

  useEffect(() => {

  }, [expanded])

  const location = useLocation();
  const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useState('');
  const businessView = useAppSelector((state) => state.app.businessView);
  const nonBusinessView = useAppSelector((state) => state.app.nonBusinessView);

  const { pathname } = location;
  // Scrolling
  useEffect(() => {
    function onScroll() {
      const scrollTop = root?.scrollTop;
      if (scrollTop) {
        setScroll(scrollTop >= 5);
      }
      if (scrollTop === 0) {
        setScroll(false);
      }
    }

    const root = document.getElementById('overall-content');
    root?.addEventListener('scroll', onScroll, true);

    return () => {
      root?.scrollTo(0, 0);
      root?.removeEventListener('scroll', onScroll, true);
    };
  }, []);
  const [subtitle, setSubtitle] = useState(0);
  const testDataPage = [
    'dashboard',
    'transaction-listing',
    'customer',
    'reporting',
    'api-logging',
    'webhook-events',
  ];

  useEffect(() => {
    function onScroll() {
      const scrollTop = root?.scrollTop;
      if (scrollTop) {
        setScroll(scrollTop >= 10);
      }
    }
    setParentPath('');
    setIsChildrenPage(false);
    setScroll(false);
    const root = document.getElementById('overall-content');
    root?.addEventListener('scroll', onScroll, true);
    root?.scrollTo(0, 0);
    root?.removeEventListener('scroll', onScroll, true);
    const { pathname, search } = location;
    const paths = pathname.split('/').filter((p) => p !== '');
    if (paths.length > 0) {
      if (paths.length >= 2 && !ignoredChildTransform.includes(paths[0])) {
        setParentPath(`/${paths[0]}`);
        switch (paths[1]) {
          case 'LCI':
            setSubtitle(10);
            break;
          case 'MWI':
            setSubtitle(11);
            break;
          case 'BNI':
            setSubtitle(12);
            break;
        }
        switch (paths[0]) {
          case 'transaction-listing':
            setHeaderTitle('transaction-listing');
            setSubtitle(32);
            break;
          case 'webhook-events':
            setHeaderTitle('webhook-events');
            console.log(paths)
            setSubtitle(31);
            break;
        }
        setIsChildrenPage(true);
      } else {
        const path = menu.find((m) => m.path.includes(`/${paths[0]}`));
        if (path !== undefined) {
          setOpenSide(false); // Closes mobile drawer sidebar
          setHeaderTitle(path.headerLabel);
          switch (paths[0]) {
            case 'dashboard':
              setSubtitle(0);
              break;
            case 'invest':
              setSubtitle(1);
              break;
            case 'portfolio-performance':
              setSubtitle(2);
              break;
            case 'transaction-history':
              setSubtitle(3);
              break;
            case 'settings':
              if (search.replace('?', '') === 'notification-settings') {
                setHeaderTitle('Notification Settings');
                setSubtitle(4);
              } else {
                setSubtitle(5);
              }
              break;
            case 'my-account':
              setSubtitle(6);
              break;
            case 'api-logging':
              setSubtitle(30);
              break;
            case 'webhook-events':
              setSubtitle(31);
              break;
            case 'transaction-listing':
              setSubtitle(32);
              break;
            case 'customer':
              setSubtitle(33);
              break;
            case 'business-details':
              setSubtitle(34);
              break;
            case 'reporting':
              setSubtitle(35);
              break;
            case 'notifications':
              setSubtitle(36);
              break;
            case 'referral':
              setSubtitle(37);
              break;
            default:
              setSubtitle(500);
              break;
          }
        }
      }
    }
  }, [location, businessView, nonBusinessView, subtitle]);

  const mobileMenuProps: IMobileMenuProps = {
    open: openSide,
    handleOpen: setOpenSide,
  };

  function GoBackComponent() {
    const { pathname } = location;
    const paths = pathname.split('/').filter((p) => p !== '');

    const display_text = [
      {
        parent: 'invest',
        text: 'Investment Strategies',
      },
      {
        parent: 'transaction-history',
        text: 'Transaction History',
      },
      {
        parent: 'transaction-listing',
        text: 'Transaction Listing',
      },
      {
        parent: 'webhook-events',
        text: 'Webhook Events',
      },
    ];

    return (
      <div
        className="flex-row align-items-center"
        id="go-back"
        onClick={() => navigate(parentPath)}
      >
        <img
          src={GobackIcon}
          alt="Go back"
        />
        <span
          className="text-sm font-normal text-gray-500"
          style={{ marginLeft: '8px' }}
        >
          Back to {display_text.find((item) => item.parent === paths[0])?.text}
        </span>
      </div>
    );
  }

  const handleLogout = () => {
    setScroll(false);
    dispatch(updateInactivityStatus(false));
    dispatch(updateModeStatus(false));
    dispatch(updatePortalStatus(false));
    dispatch(updateLogoutState(false));
    navigate('/');
    logout();
  };

  useEffect(() => {
    if (user === null) setOpenSide(false);
  }, [user]);

  function AccountButtonComponent() {
    function BasicPopover() {
      const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl === null) {
          setAnchorEl(event.currentTarget);
        } else {
          setAnchorEl(null);
        }
      };

      const handleClose = () => {
        setAnchorEl(null);
        //notificationsToDisplay();
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

      return (
        <>
          <IconButton
            onClick={() => themeFn()}
            disableRipple
          >
            <LightMode />
          </IconButton>

          {/* <IconButton
            onClick={handleClick}
            disableRipple
          >
            <Badge
              color="error"
              variant="dot"
              showZero={showZero}
              badgeContent={0}
            >
              <BellIcon />
            </Badge>
          </IconButton> */}

          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{
              zIndex: 4,
            }}
          >
            <UnreadNotificationsComponent />
          </Popover> */}
        </>
      );
    }

    return <BasicPopover />;
  }

  function SanboxToggle() {
    const ToggleSwitch = styled((props: SwitchProps) => (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
      />
    ))(({ theme }) => ({
      width: 40,
      height: 22,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '200ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#43D9BB',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    }));

    const modeState = useAppSelector((state) => state.mode.sandboxMode);
    const dispatch = useAppDispatch();

    const handleChange = () => {
      dispatch(updateModeStatus(!modeState));
    };

    return (
      <Box
        className={`${themeState}-switch`}
        display="flex"
        justifyContent="center"
      >
        {modeState ? (
          <Box
            style={{ color: '#667085', fontWeight: '500' }}
            mr={1}
          >
            SandBox Mode
          </Box>
        ) : (
          <Box
            style={{ color: '#667085', fontWeight: '500' }}
            mr={1}
          >
            Live Mode
          </Box>
        )}
        <ToggleSwitch
          checked={modeState}
          onChange={handleChange}
        />
      </Box>
    );
  }

  const themeFn = () => {
    if (themeState === THEME.DEFAULT) {
      dispatch(updateThemeState('DARK'))
      dispatch(enableLoader(true));
    } else if (themeState === THEME.DARK) {
      dispatch(updateThemeState('DEFAULT'))
      dispatch(enableLoader(true));
    }
  }

  const handleClickOutside = () => {
    setExpanded(false)
  };
  const ref: any = useOutsideClick(handleClickOutside);
  const handleClick = (event: any) => {
    setExpanded(true)
    event.stopPropagation();
  };

  function AccountButtonComponentV2() {
    return (
      <div className="header-right-nav">
        <div>
          <SanboxToggle />
        </div>
        <div className={`btn-grid ${themeState}-header-btn-grid-property`}>
          <LightMode onClick={() => themeFn()} />
        </div>
        <div
          className="btn-profile-grid"
        >
          <Avatar
            ref={ref}
            onClick={handleClick}
            src={profilePicDisplayFile}
            alt="human"
            className={`${expanded ? 'icon-align' : ''} `}
            style={{ marginRight: '4px', minWidth: '40px', height: '40px' }}
          />
          <div
            id="name-container"
            className={`${expanded ? 'expanded' : ''}`}
          >
            {expanded && (
              <div className={`expanded-container ${themeState}-expandable`}>
                <div className="preview-profile">
                  <Avatar
                    src={profilePicDisplayFile}
                    alt="human"
                    className={`${expanded ? 'icon-align' : ''} `}
                    style={{
                      marginRight: '4px',
                      width: '32px',
                      height: '32px',
                    }}
                  />
                  <div className="preview-items">
                    <div className="text-sm font-medium text-gray-700">
                      {user.name}
                    </div>
                    <div className="text-sm font-normal text-gray-500">
                      {user.emailAddress}
                    </div>
                  </div>
                </div>

                <div
                  className="menu-item text-sm font-normal text-gray-500"
                  onClick={() => navigate('/my-account')}
                >
                  <div>
                    <AccountIcon
                      width={'18px'}
                      height={'18px'}
                    />
                  </div>
                  My Account
                </div>

                <div
                  className="menu-item text-sm font-normal text-gray-500"
                  onClick={() => {
                    navigate('/settings?api-setup');
                  }}
                >
                  <div>
                    <SettingDropDownGearIcon
                      width={'18px'}
                      height={'18px'}
                    />
                  </div>
                  Settings
                </div>

                {/* <div
                  className="menu-item text-sm font-normal text-gray-500"
                  onClick={() => {
                    // Switch to developer portal
                    dispatch(updatePortalStatus(true));
                    navigate('/developer-dashboard');
                  }}
                >
                  <div>
                    <DeveloperIcon
                      width={'18px'}
                      height={'18px'}
                    />
                  </div>
                  Developer Portal
                </div> */}

                <div
                  className="menu-item text-sm font-normal text-gray-500"
                  onClick={() => {
                    navigate('/referral-program');
                  }}
                >
                  <div>
                    <ReferralIcon
                      width={'18px'}
                      height={'18px'}
                    />
                  </div>
                  Referral Program
                </div>

                <div
                  className="menu-item text-sm font-normal text-gray-500 special"
                  onClick={() => dispatch(updateLogoutState(true))}
                >
                  <div>
                    <LogoutIcon
                      className="logout-path"
                      width={'18px'}
                      height={'18px'}
                    />
                  </div>
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  const modeState = useAppSelector((state) => state.mode.sandboxMode);
  return (
    <>
      {isAuthenticated && showStakedIcon && (
        <>
          <AppBar className='DARK-AppBar' color="inherit">
            <Box
              className="DARK-injectable-mobile-header"
              height={'64px'}
              display="flex"
              justifyContent="space-between"
              style={{
                background: '#FFFFFF',
                borderBottom: '1px solid #EAECF0',
                alignItems: 'center',
                paddingLeft: '16px',
                paddingRight: '26px',
                paddingTop: '16.5px',
                paddingBottom: '16.5px',
              }}
            >
              <div>
                <CompanyLogo  mode={themeState} />
              </div>
              <div style={{ display: 'flex' }}>
                <div className="mobile-btn-grid">

                  <AccountButtonComponent />
                </div>
                <img
                  style={{ width: '28px' }}
                  src={MenuIconSecuro}
                  onClick={() => setOpenSide(!openSide)}
                ></img>
                {/* <MenuIcon onClick={() => setOpenSide(!openSide)} /> */}
              </div>
            </Box>
          </AppBar>
        </>
      )}

      {isAuthenticated && (
        <Box className="header-container">
          <div
            className={`${isDesktop
              ? `header-container-wrapper`
              : `header-container-wrapper`
              } ${scroll ? (expanded ? `${themeState}-scrolled-expanded` : `${themeState}-scrolled`) : ''} ${expanded ? 'expanded' : ''
              }`}
          >
            {modeState &&
              testDataPage.includes(pathname.replace('/', '')) && (
                <div className="sandbox-toggle">
                  <div className="grid-info">TEST DATA</div>
                </div>
              )}
            <Grid>
              {isAuthenticated && (
                <>
                  {isChildrenPage ? (
                    <>
                      <GoBackComponent />
                      <div className="header-container-title">
                        {subtitle === 10 && (
                          <div className="text-3xl font-medium text-gray-900">
                            Low Risk Crypto Index (LCI)
                          </div>
                        )}
                        {subtitle === 11 && (
                          <div className="text-3xl font-medium text-gray-900">
                            Market Weighted Index (MWI)
                          </div>
                        )}
                        {subtitle === 12 && (
                          <div className="text-3xl font-medium text-gray-900">
                            Blockchain Network Index (BNI)
                          </div>
                        )}
                        {subtitle === 13 && (
                          <div className="text-3xl font-medium text-gray-900">
                            Transaction History
                          </div>
                        )}
                        {subtitle === 31 && (
                          <div className="text-3xl font-medium text-gray-900">
                            Webhook Event
                          </div>
                        )}
                        {subtitle === 32 && (
                          <div className="text-base font-normal text-gray-500">
                            Transaction
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="header-container-title">
                      <div className={`${themeState}-text-m-3xl`}>
                        {headerTitle}
                      </div>
                      <div className="header-container-subtitle">
                        {subtitle === 0 && (
                          <div className="text-base font-normal text-gray-500">
                            Welcome back, {username}
                          </div>
                        )}
                        {subtitle === 1 && (
                          <div className="text-base font-normal text-gray-500">
                            Seamlessly invest into multi investment strategies
                          </div>
                        )}
                        {subtitle === 2 && (
                          <div className="text-base font-normal text-gray-500">
                            View the statistics of your investment
                          </div>
                        )}
                        {subtitle === 3 && (
                          <div className="text-base font-normal text-gray-500">
                            View and search your transactions here
                          </div>
                        )}
                        {subtitle === 4 && (
                          <div className="text-base font-normal text-gray-500">
                            Update your notification settings here
                          </div>
                        )}
                        {subtitle === 5 && (
                          <div className="text-base font-normal text-gray-500">
                            Update your settings here.
                          </div>
                        )}
                        {subtitle === 6 && (
                          <div className="text-base font-normal text-gray-500">
                            Update your settings here.
                          </div>
                        )}
                        {subtitle === 30 && (
                          <div className="text-base font-normal text-gray-500">
                            View your API Logging here.
                          </div>
                        )}
                        {subtitle === 31 && (
                          <div className="text-base font-normal text-gray-500">
                            View your webhook events here.
                          </div>
                        )}
                        {subtitle === 32 && (
                          <div className="text-base font-normal text-gray-500">
                            View and export your transactions here.
                          </div>
                        )}
                        {subtitle === 33 && (
                          <div className="text-base font-normal text-gray-500">
                            Add, view and edit your customers.
                          </div>
                        )}
                        {subtitle === 34 && (
                          <div className="text-base font-normal text-gray-500">
                            Update your Business Logo, Description, Display Name
                            here.
                          </div>
                        )}
                        {subtitle === 35 && (
                          <div className="text-base font-normal text-gray-500">
                            View your reporting here
                          </div>
                        )}
                        {subtitle === 36 && (
                          <div className="text-base font-normal text-gray-500">
                            View all your notifications here
                          </div>
                        )}
                        {subtitle === 37 && (
                          <div className="text-base font-normal text-gray-500">
                            Refer your friend and earn reward!
                          </div>
                        )}
                        {subtitle === 500 && <>{null}</>}
                      </div>
                    </div>
                  )}
                </>
              )}
            </Grid>
            <Grid>
              {isAuthenticated && isDesktop && <AccountButtonComponentV2 />}
            </Grid>
          </div>
        </Box>
      )}
      <MobileMenu {...mobileMenuProps} />
    </>
  );
}

export function Titles() {
  const windowSize = useWindowSize();
  const [scroll, setScroll] = useState(false);

  const [showStakedIcon, setShowStakedIcon] = useState(false);
  const [openSide, setOpenSide] = useState(false);

  const { user } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [parentPath, setParentPath] = useState('');
  const [isChildrenPage, setIsChildrenPage] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  // Listen to window size change
  useEffect(() => {
    const { width } = windowSize;

    if (width !== undefined && width <= 720) {
      setShowStakedIcon(true);
    } else {
      setShowStakedIcon(false);
      setOpenSide(false);
    }
  }, [windowSize]);
  const location = useLocation();
  const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useState('');

  useEffect(() => {
    setParentPath('');
    setIsChildrenPage(false);
    setScroll(false);
    const { pathname } = location;
    const paths = pathname.split('/').filter((p) => p !== '');
    if (paths.length > 0) {
      if (paths.length >= 2) {
        setParentPath(`/${paths[0]}`);
        setIsChildrenPage(true);
      } else {
        const path = menu.find((m) => m.path.includes(`/${paths[0]}`));
        if (path !== undefined) {
          setHeaderTitle(path.headerLabel);
        }
      }
    }
  }, [location]);

  function GoBackComponent() {
    return (
      <div
        className="flex-row align-items-center"
        id="go-back"
        onClick={() => navigate(parentPath)}
      >
        <img
          src={GobackIcon}
          alt="Go back"
          style={showStakedIcon ? { width: '18px', height: '18px' } : {}}
        />
        <span
          style={
            showStakedIcon
              ? { marginLeft: '8px', fontSize: '20px' }
              : { marginLeft: '8px' }
          }
        >
          Go Back
        </span>
      </div>
    );
  }

  return (
    <>
      {showStakedIcon && (
        <Box>
          {isAuthenticated && (
            <>
              {isChildrenPage ? (
                <Box ml={4}>
                  <GoBackComponent />
                </Box>
              ) : (
                <Box ml={'16px'}>
                  <Typography style={{ fontSize: '25px', fontWeight: 700 }}>
                    {headerTitle}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default Header;
