import { Card, Divider } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import BusinessLogo from "../../../assets/images/icons/BusinessLogo.svg";

export default function Preview (
    {previewImage, displayName = ""}:
    {
        previewImage: any,
        displayName: string
    }) {

    const currentDateTime = new Date();
    const [dateTime, setDateTime] = useState<any>();
    useEffect(() => {
        setDateTime(moment(currentDateTime).format("DD/MM/YYYY HH:MM:SS A"))
    }, [])
    return (
        <>
        <Box className="subheadings">
            Preview
        </Box>
        <Box className="">
            <Card
                style=
                {{
                    borderRadius: "8px",
                    width: "350px",
                    height: "530px",
                    textAlign: "center",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column"
                }}
            >   {!previewImage ? <Box display="flex" justifyContent="center" alignItems="center">
                <div className="rounded-full" style={{ width: "50px", height: "50px" }}><img src={BusinessLogo}/></div>
                </Box> :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <div className="rounded-full" style={{ width: "50px", height: "50px" }}><img src={previewImage}/></div>
                </Box>
                }
                <Box mt={2} className="text-xl font-medium text-gray-700">
                    Transaction Confirmation
                </Box>
                <Box mt={1} className="text-xs font-medium text-gray-700">
                    {displayName}
                </Box>
                <Box display="flex" justifyContent="space-around" my={2}>
                    <Box className="text-xs font-normal text-gray-500">Transaction ID: #123835</Box>
                    <Box className="text-xs font-normal text-gray-500">{dateTime}</Box>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-around" mt={2} mb={2}>
                    <Box className="text-xs font-medium text-gray-900 flex flex-col gap-3.5">
                        My Wallet Address
                        <Box className="text-xs font-normal text-gray-500">0x95f...ab</Box>
                    </Box>
                    <Box className="text-xs font-medium text-gray-900 flex flex-col gap-3.5">
                        Receiver's wallet address
                        <Box className="text-xs font-normal text-gray-500">0x25f...f1</Box>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-around" mt={2} mb={2}>
                    <Box className="text-xs font-medium text-gray-900 flex flex-col gap-3.5">
                        Description
                        <Box className="text-xs font-normal text-gray-500">Approve transaction</Box>
                    </Box>
                    <Box display="flex">
                        <Box className="text-xs font-medium text-gray-900 flex flex-col gap-3.5" mr={2}>
                            Currency
                            <Box className="text-xs font-normal text-gray-500">USDT</Box>
                        </Box>
                        <Box className="text-xs font-medium text-gray-900 flex flex-col gap-3.5">
                            Value
                            <Box className="text-xs font-normal text-gray-500">10,000.00</Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box mt={4} flexGrow={1} display="flex" justifyContent="space-around" alignItems={"end"} className="text-xs font-normal text-gray-400">
                    © ThirdFi 2023. All Rights Reserved.
                </Box>
            </Card>
        </Box>
        </>
    )
}