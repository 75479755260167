import '../app.scss'
import './sidebar.scss'
import Menus from './menu';
import DeveloperPortalToggle from "./developerPortalToggle";

import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout-icon.svg'
import { ReactComponent as TemporaryProfileIcon } from "../../assets/images/icons/default-profile.svg";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useAppSelector } from '../../store/hooks'
import { useAuth } from '../../services/auth/authProvider'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { updateLogoutState } from '../../store/slices/logout-slice'
import { useState } from 'react';
import HoverableText from '../../utils/hoverable-text';
import CompanyLogo from '../../assets/images/company';

function Sidebar() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.user.userData);
  const logoutState = useAppSelector(state => state.logout.showLogout);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(updateLogoutState(true));
    // logout();
  }
  const themeState = useAppSelector((state) => state.theme.themeState);
  
  return <div className={`sidebar-container ${themeState}-sidebar-bg flex-column align-item-center`}>
    <div>
      <div className='company-logo-container'>
        <CompanyLogo mode={themeState} />
      </div>
    </div>
    <div className='menu-sidebar'>
      <div className='flex-column align-items-center upper-container'>
        <Menus />
      </div>
    </div>
    <div className='bottom-sidebar-wrapper'>
      <div className='flex-bottom'>
        <div className='switcher text-sm font-medium text-gray-500'>
          {/* <DeveloperPortalToggle /> */}
        </div>
        <div className={`${themeState}-sidebar-bottom-divider`}>
        </div>
        <div className='user-wrapper'>
          <div className='icon'>
            {userData.userPhotoUrl ?
              <img src={userData.userPhotoUrl} onClick={() => navigate('/my-account')} />
              :
              <TemporaryProfileIcon width={"40px"} height={"40px"} onClick={() => navigate('/my-account')} />
            }
          </div>
          <div className='about'>
            <div className={`user-name ${themeState}-username`}>
              {userData.firstName && userData.firstName.length >= 15 &&
                <HoverableText text={userData.firstName} />
              }
              {userData.firstName && userData.firstName.length < 15 &&
                <>{userData.firstName}</>
              }
            </div>
            <div className='user-mail text-sm font-medium text-gray-500'>
              {userData.emailAddress.length >= 15 &&
                <HoverableText text={userData.emailAddress} />
              }
              {userData.emailAddress.length < 15 &&
                <>{userData.emailAddress}</>
              }
            </div>
          </div>
          <div className='logout'>
            <LogoutIcon onClick={() => handleLogout()} />
          </div>
        </div>
      </div>
    </div>
  </div>

}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    marginTop: 1,
    marginLeft: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#43D9BB',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default Sidebar