import { Box, IconButton } from "@mui/material";
import { ReactComponent as DeveloperToolsIcon } from '../../assets/images/menus/dark/developerTools.svg';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

import './sidebar.scss'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";

export default function DeveloperToolsMenu() {

    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState(false);

    const [selectAPILogging, setSelectAPILogging] = useState(false)
    const [selectWebhook, setSelectWebhook] = useState(false)

    const handleNavigate = (path: string) => {
        navigate(path);
    }

    useEffect(() => {
        if (location.pathname !== "/api-logging" && location.pathname !== "/webhook-events") {
            setSelectAPILogging(false);
            setSelectWebhook(false);
            setSelected(false);
        } else if (location.pathname === "/webhook-events") {
            setSelectWebhook(true);
            setSelectAPILogging(false);
            setSelected(true);
        } else if (location.pathname === "/api-logging") {
            setSelectWebhook(false);
            setSelectAPILogging(true);
            setSelected(true);
        }
    }, [location]);

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`${themeState}-developer-tools`}>
        <Box onClick={() => setSelected(!selected)} className="menu-container-item flex-row flex-align-center" style={{ cursor: "default" }}>
            <Box display="flex"
                justifyContent="center"
                alignItems="center">
                <div className="menu-item-icon"><DeveloperToolsIcon/></div>
            </Box>
            <Box style={{ marginRight: "15px", cursor: "pointer" }}>Developer Tools</Box>
            <Box style={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                {selected ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Box>
        </Box>
        {selected && <Box className="menu-container-normal">
            <Box
                className={`menu-container-item ${selectAPILogging ? 'selected' : ''}`}
                style={{ marginLeft: "45px" }}
                onClick={() => {
                    setSelectWebhook(false)
                    setSelectAPILogging(true)
                    handleNavigate("/api-logging")
                }}>
                API logging
            </Box>
            <Box
                className={`menu-container-item ${selectWebhook ? 'selected' : ''}`}
                style={{ marginLeft: "45px" }}
                onClick={() => {
                    setSelectWebhook(true)
                    setSelectAPILogging(false)
                    handleNavigate("/webhook-events")
                }} >
                Webhook events
            </Box>
        </Box>}
    </Box >
}