import { useCallback, useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import FileSuccessIcon from "../../assets/images/icons/file-upload-success.svg";
import TickSuccessIcon from "../../assets/images/icons/tick.svg";
import DeleteIcon from "../../assets/images/icons/delete.svg";
import UploadIcon from "../../assets/images/icons/upload-icon-v2.svg";
import { ImgContext } from "../../pages/BusinessDetails";
import { Dispatch } from "@reduxjs/toolkit";
import "./upload-droplet.scss";
import { createStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../../store/hooks";

interface IDropletActionsPayload {
    logoFile: any,
    setLogoFile: Dispatch<any>,
    setPreviewImage: Dispatch<any>,
    newLogoFile: boolean,
    setNewLogoFile: Dispatch<any>,
    loadingAll: any,
}
export default function UploadDroplet({uploaded}:{uploaded?: boolean}) {

    const { logoFile, setLogoFile, setPreviewImage, newLogoFile, setNewLogoFile, loadingAll }: IDropletActionsPayload = useContext(ImgContext);

    useEffect(() => {
        if(loadingAll) {

        }
    }, [loadingAll])
    const themeState = useAppSelector((state) => state.theme.themeState);

    return (
        <section className={`business-drag-and-drop-container ${themeState}-drag-and-drop`}>
        <div className={`root-box ${(logoFile !== null) ? `on-uploaded` : `on-default`}`}>
            <Dropzone onDrop={(acceptedFiles) => {
                setLogoFile(acceptedFiles[0])
                setPreviewImage(URL.createObjectURL(acceptedFiles[0]));
                // setLogoFile(acceptedFiles[0]);
                setNewLogoFile(true);
            }}
                maxSize={2097152}
                multiple={false}
                accept={{
                    'image/png': [],
                    'image/jpg': [],
                    'image/jpeg': []
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: `drop-box` })}>
                        <input {...getInputProps()} />
                        {(logoFile !== null) ?
                            <div className="wrapped-on-uploaded">
                                <div className="icon">
                                    <div className={`bound-icon on-uploaded`}>
                                    <img src={FileSuccessIcon} />
                                    </div>
                                </div>
                                <div className="file-details">
                                    <div className="file-name text-base font-normal text-gray-500">
                                        <div className="wrapped-text">{logoFile?.name}</div>
                                    </div>
                                    {(!newLogoFile && !loadingAll) &&
                                        <div className="progress">
                                            <div className="bar"></div>
                                            <div className="text">100%</div>
                                        </div>
                                    }
                                    {newLogoFile && !loadingAll &&
                                        <div className="progress">
                                            <div className="bar-one-new-state"></div>
                                            <div className="text">0%</div>
                                        </div>
                                    }
                                    {newLogoFile && loadingAll &&
                                        <div className="progress-is-upload">
                                            <CircularProgress className="circular-progress"/>
                                            <span className="override">Uploading</span>
                                        </div>
                                    }
                                </div>
                                <div className="action-container">
                                    <img src={TickSuccessIcon} alt="tick" />
                                    <img src={DeleteIcon} style={{ cursor: "pointer" }} alt="del" onClick={(event) => {
                                        event.stopPropagation();
                                        setLogoFile(null)
                                        setPreviewImage(null);
                                        setNewLogoFile(true)
                                        // If removed and saved, newLogoFile === true => indicating for upload patch to "" value
                                    }
                                    } />
                                </div>
                            </div>
                            :
                            <div className="text-item-empty">
                                <div className="icon-wrapper-none"><img src={UploadIcon} /></div>
                                <div className="guide-info">
                                    <span className="text-sm font-normal securo-color">Click to upload or</span>
                                    &nbsp;
                                    <span className="text-sm font-normal text-gray-500">drag and drop</span>
                                </div>
                                <div className="text-xs font-normal text-gray-500">PNG or JPG (max. 2 MB)</div>
                            </div>
                        }
                    </div>
                )}
            </Dropzone>
        </div>
    </section>
    )
}

const ProgressBar = () => {
    return (
        <></>
    )
}

    // const startUpload = () => {
    //     let stylesheet = document.styleSheets[0];
    //     const keyframes = `@keyframes resizeBar {
    //         0% {
    //             width: 10%;
    //         }
    //         ${'20%'} {
    //             width: 20%;
    //         }
    //         ${'50'}% {
    //             width: 50%;
    //         }
    //         ${'100'}% {
    //             width: 100%;
    //         }
    //     }
    //     `
    //     stylesheet.insertRule(keyframes, stylesheet.cssRules.length);
    //     console.log('inserter')
    //     let timer = 100;
    //     let initialPercentage = 0;
    //     let progress = setInterval(() => {
            
    //         if(initialPercentage === 100) {
    //             clearInterval(progress);
    //             setIntervalPercentage(100);
    //         }
            
    //     }, timer)

    //     if(!loadingAll) {
    //         setIntervalPercentage(100);
    //         clearInterval(progress);
    //     }
    // }

    // useEffect(() => {
    //     if(intervalPercentage === 100) {
    //         clearInterval()
    //     }
    // })

    // const animateStyles: React.CSSProperties = {
    //     animationFillMode: `forwards`,
    //     animation: `resizeBar 5s ease-in`,
    // }