import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TestData from '../../components/TestData';
import APILogging from './APILogging';
import WebhookEvents from "./WebhookEvents";
import "./index.scss";
export default function DeveloperTools() {

    const location = useLocation();

    return (
        <Box style={{ width: "100%" }}>
            <div className='global-header-spacer'></div>
            {/* <TestData /> */}
            <Box className='developer-tools-wrap'>

                {location.pathname === "/webhook-events" ? <Box>
                    <Box>
                        <WebhookEvents />
                    </Box>
                </Box> : <Box>
                    <Box>
                        <APILogging />
                    </Box>
                </Box>
                }
            </Box>
        </Box>
    );
}
