import { Box, Divider, Grid, Card, TextField, TextareaAutosize, Button, useMediaQuery, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./businessDetails.scss";
import BusinessLogo from "../../assets/images/icons/BusinessLogo.svg";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { createContext, useEffect, useState } from "react";

import { getBusinessDetails, uploadBusinessDetails } from "../../services/axios/businessDetails";
import { uploadDocument } from "../../services/axios/kyc";

import { STATUS_CODE } from "../../constants/general";
import { useDispatch } from "react-redux";
import { updateMsgState } from "../../store/slices/messages-v2-slice";
import { useForm } from "react-hook-form";
import UploadDroplet from "../../components/UploadDroplet";
import Preview from "./preview/preview";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { refreshUserPic } from "../../store/slices/user-slice";

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                        borderColor: "#43D9BB",
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: "0px",
                    backgroundColor: "white"
                }
            }
        }
    }
})

const useStyles = makeStyles(() => ({
    cancelButton: {
        height: "40px",
        fontSize: "14px",
        fontWeight: 500,
        border: "1px solid #D0D5DD",
        color: "#344054",
        textTransform: "capitalize",
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "#e5e9f1",
            border: "1px solid #e5e9f1",
            boxShadow: 'none'
        }
    },

    saveButton: {
        width: "65px",
        height: "40px",
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",
        backgroundColor: "#43D9BB",
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: "#36b299",
        }
    },
}))

interface IImgState {
    logoFile: any | null,
    is_new: boolean,
    previewFile: any | null
}

const initialFile: IImgState = {
    logoFile: null,
    is_new: false,
    previewFile: null
}

export const ImgContext: any = createContext<IImgState>(initialFile);

export default function BusinessDetails() {
    const classes = useStyles()

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [loadingAll, setLoadingAll] = useState(false);
    const [logoFile, setLogoFile] = useState<any>(null);
    const [newLogoFile, setNewLogoFile] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>(null);
    const [displayName, setDisplayName] = useState<any>("");
    const [description, setDescription] = useState<any>("");
    const [initialData, setInitialData] = useState<any>({
        logoFile: null,
        previewImage: null,
        displayName: "",
        description: ""
    });

    const [limitText, setLimitText] = useState(500);
    const onLimit = (e: any) => {
        setLimitText(500 - e.target.value.length)
        if (limitText !== 0) {
            setDescription(e.target.value);
        }
    }
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
    } = useForm({
        mode: 'onChange'
    });

    const [isUploaded, setIsUploaded] = useState(false);

    const onSave = async () => {

        setLoadingAll(true);

        let dataURL
        if (newLogoFile) {

            if (logoFile) {
                const payload = {
                    module: "upload-business-photo",
                    file: logoFile,
                    fileName: "business-profile-pic",
                }

                const res = await uploadDocument(payload);
                if (res === undefined) {
                    dispatch(updateMsgState({ state: "error", title: "Error", reason: "Image Upload Error", attempt: true, isMain: false }))
                    dataURL = initialData.previewImage;
                } else if (res && previewImage !== null) {
                    setIsUploaded(true);
                    dataURL = res?.data.data.url;

                    const details = {
                        companyName: getValues("companyName"),
                        description: description,
                        businessPhotoUrl: dataURL
                    }

                    const { data } = await uploadBusinessDetails(details);
                    if (data.statusCode === STATUS_CODE.OK) {
                        dispatch(updateMsgState({ state: "success", title: "Success", reason: "Business details has been updated.", attempt: true, isMain: false }))
                    }
                    await getBusinessDetailsFunc();
                    dispatch(refreshUserPic({ dataURL }));
                    setNewLogoFile(false);
                }
            } else {
                setIsUploaded(true);

                const details = {
                    companyName: getValues("companyName"),
                    description: description,
                    businessPhotoUrl: ""
                }

                const { data } = await uploadBusinessDetails(details);
                if (data.statusCode === STATUS_CODE.OK) {
                    dispatch(updateMsgState({ state: "success", title: "Success", reason: "Business details has been updated.", attempt: true, isMain: false }))
                }
                await getBusinessDetailsFunc();
                // dispatch(refreshUserPic({ dataURL }));
                setNewLogoFile(false);
            }
        } else {
            const details = {
                companyName: getValues("companyName"),
                description: description,
                businessPhotoUrl: previewImage
            }

            const { data } = await uploadBusinessDetails(details);
            if (data.statusCode === STATUS_CODE.OK) {
                dispatch(updateMsgState({ state: "success", title: "Success", reason: "Business details has been updated.", attempt: true, isMain: false }))
            }
            await getBusinessDetailsFunc();
        }

        setLoadingAll(false);
    }

    const getBusinessDetailsFunc = async () => {
        const res = await getBusinessDetails();

        const data = res?.data.data;
        const initData = initialData
        if (data.businessPhotoUrl) {
            setLogoFile(new File([], data.businessPhotoUrl.substring(data.businessPhotoUrl.lastIndexOf('/') + 1)));
            initData.logoFile = new File([], data.businessPhotoUrl.substring(data.businessPhotoUrl.lastIndexOf('/') + 1));
        }

        if (data.businessPhotoUrl) {
            setPreviewImage(data.businessPhotoUrl);
            initData.previewImage = data.businessPhotoUrl;
        }
        if (data.companyName) {
            setDisplayName(data.companyName);
            setValue('companyName', data.companyName)
            initData.displayName = data.companyName;
        }
        if (data.description) {
            setDescription(data.description);
            initData.description = data.description
            setLimitText(500 - data.description.length)
        }

        setInitialData(initData)
    }
    // console.log('inparent', logoFile, previewImage, newLogoFile)
    const onCancel = () => {
        setLogoFile(initialData.logoFile);
        setNewLogoFile(false);
        setPreviewImage(initialData.previewImage);
        setValue('companyName', initialData.displayName)
        setDisplayName(initialData.displayName);
        setDescription(initialData.description);
    }

    useEffect(() => {
        getBusinessDetailsFunc()
    }, [])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className="bd-root-box">
        <Box className={`developer-business-details-container ${themeState}-business-details-replacement`}>
            <div className="global-header-spacer"></div>
            <Box className="business-details">Business Details</Box>
            <Box className="subtitle">Update your Business Logo, Description, Display Name</Box>
            <Divider className="divider-spacer" />
            <div className="business-details-grid">
                <Grid item xs={9} className="form-panel">
                    <Box className="subheadings">
                        Business Logo
                    </Box>
                    <Box className="bd-logo">
                        <Box className="bounded-logo">
                            <div className="img-container">
                                {logoFile ?
                                    <img src={previewImage} />
                                    :
                                    <img src={BusinessLogo} />
                                }
                            </div>
                        </Box>
                        <Box width={'100%'}>
                            <ImgContext.Provider value={{ logoFile, setLogoFile, setPreviewImage, newLogoFile, setNewLogoFile, loadingAll }}>
                                <UploadDroplet uploaded={isUploaded} />
                            </ImgContext.Provider>
                        </Box>
                    </Box>
                    <Divider className="divider-spacer" />
                    <form onSubmit={handleSubmit(onSave)}>
                        <Box mt={2} mb={2}>
                            <Box className="subheadings input-text">
                                Display Name<span className="text-error-500">*</span>
                            </Box>
                            <Box>
                                <TextField
                                    className="input-field"
                                    fullWidth
                                    size="small"
                                    {...register("companyName", {
                                        required: "Display Name is empty",
                                    })}
                                    error={!!errors?.companyName}
                                    helperText={errors?.companyName?.message}
                                />
                            </Box>
                        </Box>
                        <Box mt={2} mb={2}>
                            <Box className="subheadings input-text">
                                Description
                            </Box>
                            <Box>
                                <ThemeProvider theme={theme}>
                                    <TextField
                                        className="input-description"
                                        inputProps={{
                                            maxLength: 500
                                        }}
                                        fullWidth
                                        value={description}
                                        multiline rows={7}
                                        style={{ padding: 0 }}
                                        onChange={(e) => onLimit(e)} />
                                </ThemeProvider>
                                <div className="characters-left text-sm font-normal text-gray-500">{limitText} characters left</div>
                            </Box>
                        </Box>
                        <Divider className="divider-spacer-last" />
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Box mr={2}>
                                <Button
                                    variant="outlined"
                                    className={`${classes.cancelButton} override-cancel-btn !w-[80px]`}
                                    size='small'
                                    disableRipple
                                    onClick={onCancel}
                                >
                                    Reset
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={`${classes.saveButton} ${themeState}-btn-global-style !w-[80px]`}
                                    size='small'
                                    disableRipple
                                    type="submit"
                                >
                                    {loadingAll ? <CircularProgress size={24} style={{ color: "#ffffff" }} /> : <span>Save</span>}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Grid>
                <Grid item xs={3} className="preview-panel">
                    <Preview previewImage={previewImage} displayName={initialData?.displayName} />
                </Grid>
            </div>
        </Box>
        <div className="global-bottom-spacer"></div>
    </Box>
}
