import { TextField, Button, Grid, Box } from '@mui/material';
import Facebook from '../../assets/images/icons/referral/facebook.svg';
import Email from '../../assets/images/icons/referral/email.svg';
import Telegram from '../../assets/images/icons/referral/telegram.svg';
import Twitter from '../../assets/images/icons/referral/twitter.svg';
import LinkedIn from '../../assets/images/icons/referral/linked.svg';
import ReferralBannerDefault from '../../assets/images/referral/referral-banner.jpg';
import ReferralBannerDark from '../../assets/images/referral/referral-banner-dark.png';
import ReferralBannerDefaultMobile from '../../assets/images/referral/referral-banner-mobile.jpg';
import ReferralBannerDarkMobile from '../../assets/images/referral/referral-banner-dark-mobile.png';
import ReferralBannerLevelUp from '../../assets/images/referral/v2/refer_a_friend_to_level_up.png'
import { useAuth } from '../../services/auth/authProvider';
import { useEffect, useState } from 'react';
import { getReferral } from '../../services/axios/referral';
import './styles.scss';
import { DomainInitial, THEME } from '../../constants/general';
import { useAppSelector } from '../../store/hooks';

function Referral() {
  const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [referralTextFields, setReferralTextFields] = useState<any[]>([]);
  const [socials, setSocials] = useState<any[]>([]);

  const copyToClipboard = (e: any, txt: string) => {
    e.target.textContent = 'Copied';
    setTimeout(() => {
      navigator.clipboard.writeText(txt);
      e.target.textContent = 'Copy';
    }, 1000);
  };

  let auth = useAuth();
  const user = !auth.user ? localStorage.getItem('user') : auth.user;
  useEffect(() => {
    const fetchData = async () => {
      async function getFromDb() {
        const res = await getReferral();
        const data = res.data;
        return data;
      }

      const data = await getFromDb();
      const code = data.referralCode;
      setReferralCode(code);
      const link = `https://${
        DomainInitial[process.env.REACT_APP_NODE_ENV || 'prod']
      }.thirdfi.org/register?referral=${code}`;
      setReferralLink(link);

      const referralTitle = 'Start your journey in DeFi. Sign up to ThirdFi now';
      const referralBody = `Build, invest, and manage DeFi products with all-in-one API integration. Sign up here ${link}. We help developers, traders, crypto startups, fintechs & tradFi to build, invest and manage DeFi products with simplicity and dev friendly tools.`;

      setReferralTextFields([
        {
          label: 'Your Referral Code',
          value: code,
        },
        {
          label: 'Your Referral Link',
          value: link,
        },
      ]);

      setSocials([
        {
          alt: 'Email',
          icon: Email,
          href: `mailto:?subject=${referralTitle}&body=${referralBody}`,
        },
        {
          alt: 'Twitter',
          icon: Twitter,
          href: `https://twitter.com/share?url=${link}&text=${referralTitle}&via=securo_dev`,
        },
        {
          alt: 'Facebook',
          icon: Facebook,
          href: `https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${referralTitle}`,
        },
        {
          alt: 'Telegram',
          icon: Telegram,
          href: `https://telegram.me/share/url?url=${link}&text=${referralTitle}`,
        },
        {
          alt: 'LinkedIn',
          icon: LinkedIn,
          href: `https://www.linkedin.com/shareArticle?url=${link}&title=${referralTitle}&summary=${referralBody}&source=${link}`,
        },
      ]);
    };
    fetchData();

    return () => { };
  }, [user]);

  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <div className={`w-full referral ${themeState}-referral`}>
      <div className="global-header-spacer w-full"></div>
      <div className="mb-14">
        
        <img
          src={ReferralBannerLevelUp}
          className="hidden sm:block w-full h-full"
          alt='thirdfi referral'
        />
        <img
          src={ReferralBannerLevelUp}
          className="sm:hidden w-full h-full"
          alt='thirdfi referral mobile'
        />
      </div>
      <div className="mx-[32px] pb-20">
        <div className="hightlights text-3xl text-gray-900 mb-12 font-medium">
          Refer your friends to level up
        </div>
        {referralTextFields.map((field) => {
          return (
            <div
              className="grid grid-cols-12 mb-10"
              key={field.label}
            >
              <div className="col-span-12 lg:col-span-6">
                <div className="text-label text-sm text-gray-700 font-medium mb-2">
                  {field.label}
                </div>
                <div className="flex h-[44px]">
                  <TextField
                    defaultValue={field.value}
                    variant="outlined"
                    fullWidth
                    disabled
                    className="input-field !rounded-r-none"
                  />
                  <Button
                    className="button-action is-copy !rounded-l-none"
                    variant="contained"
                    onClick={(e) => copyToClipboard(e, field.value)}
                    style={{ borderRadius: '8px' }}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        <div className="text-label text-gray-900 mb-6 font-medium">Share it on</div>
        <div className="flex gap-6">
          {socials.map((social) => {
            return (
              <div
                key={social.alt}
                className="pointer"
              >
                <a
                  rel="nofollow noopener noreferrer"
                  href={social.href}
                  target="_blank"
                  title={`Click to share via ${social.alt}`}
                >
                  <img
                    src={social.icon}
                    alt={social.alt}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Referral;
