import { Button, TextField, Divider, Grid, Card, Chip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import MainCard from '../SecuroLayouts/MainCard';
import CustomModal from '../../components/Modal';
import TrashIcon from '../../assets/images/icons/trash.svg';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '../../assets/images/icons/edit-pencil-box.svg';
import DeleteIcon from '../../assets/images/icons/delete-outline.svg';
import SecuroButtonWithLoader from '../../components/SecuroButtons/SecuroButtonWithLoader';
import moment from 'moment';
import "./styles.scss";

import {
  getWebhook,
  getWebhooks,
  createWebhook,
  deleteWebhook,
  updateWebhook,
} from '../../services/axios/webhooks';
import ToggleSwitch from '../ToggleSwitch';

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({

  cancelButton: {
    fontWeight: 700,
    border: "1px solid #D0D5DD",
    color: "#344054",
    textTransform: "capitalize",
    backgroundColor: "inherit",

  },

  saveButton: {
    height: "40px",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "capitalize",
    backgroundColor: "#43D9BB",
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: "#36b299"
    }
  }

}))

function getValue(obj: any, headerKey: string) {
  const value = obj[headerKey] || '';

  if (headerKey === 'isSandbox') {
    return value ? 'Sandbox' : 'Live';
  }

  return typeof value === 'function' ? value() : value;
}

function WebhookSetting() {
  const [webhooks, setWebhooks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState('');
  const [loadingWebhook, setLoadingWebhook] = useState(false);
  const [webhook, setWebhook] = useState<any>({});

  async function loadWebhooks() {
    setLoading(true);
    const res = await getWebhooks();
    const dataForRender = formatWebhooksForDisplay(res.data);
    setWebhooks(dataForRender);
    setLoading(false);
    return;
  }

  useEffect(() => {
    const fetchData = async () => {
      await loadWebhooks();
      return;
    };

    fetchData();

    return () => { };
  }, []);

  function formatWebhooksForDisplay(data: any) {
    const dataForRender = data.map((obj: any) => {
      obj.createdDate = moment(obj.createdDate).format('D MMM, YYYY hh:mm A');
      return {
        ...obj,
        actions: () => {
          return WebhookActions(obj);
        },
      };
    });
    return dataForRender;
  }

  function WebhookActions(webhook: any) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    async function confirmDelete(id: number) {
      setDeleting(true);
      try {
        await deleteWebhook(webhook.id);
      } catch (e) {
      } finally {
        const updatedWebhooks = webhooks.filter((obj) => obj.id !== id);
        setWebhooks([...updatedWebhooks]);
        setDeleting(false);
        setDeleteModal(false);
        await loadWebhooks();
      }
    }

    function promptConfirmDelete() {
      setDeleteModal(true);
    }

    function closeDeleteModal() {
      setDeleteModal(false);
    }

    function ModalContent() {
      return (
        <div style={{ textAlign: 'center' }}>
          <div
            style={{ color: '#667085', position: 'absolute', right: '2rem' }}
          >
            <CloseIcon
              fontSize="large"
              onClick={closeDeleteModal}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div />
            <img src={TrashIcon}></img>
          </div>

          <div
            className="highlights-2 text-lg font-medium text-gray-900 mt-8 mb-8"
            style={{
              overflowWrap: 'break-word',
            }}
          >
            Confirm delete
            <span className="highlights text-lg font-normal text-gray-500">
              &nbsp;{webhook.url}
            </span> webhook?
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '3rem',
            }}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={6}
              >
                <Button
                  variant="outlined"
                  className='modal-btn-cancel btn-rm'
                  fullWidth
                  onClick={() => closeDeleteModal()}
                >
                  <span style={{ color: '#344054' }}>Cancel</span>
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Button
                  variant="contained"
                  className='modal-btn-confirm btn-confrim'
                  fullWidth
                  onClick={() => confirmDelete(webhook.id)}
                >
                  Confirm Delete
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    return (
      <div className='action-gap'>
        <img
          src={EditIcon}
          style={{ color: '#98A2B3', cursor: 'pointer', width: '1.2rem' }}
          onClick={() => editWebhook(webhook.id)}
        />
        <img
          src={DeleteIcon}
          onClick={() => promptConfirmDelete()}
          style={{ color: '#98A2B3', cursor: 'pointer', width: '1.2rem' }}
        />

        <CustomModal
          open={deleteModal}
          content={ModalContent()}
          titleMain="Confirm revoke Webhook"
          handleOpen={(bool) => setDeleteModal(bool)}
        />
      </div>
    );
  }

  async function editWebhook(id: number) {
    setLoadingWebhook(true);
    const res = await getWebhook(id);
    const data = res.data;
    setWebhook(data);
    setLoadingWebhook(false);
  }

  function WebhookEdit() {
    const { register, handleSubmit } = useForm();

    async function save(data: any) {
      const updatedWebhook = { ...webhook, ...data };
      setWebhook(updatedWebhook);
      setSaving(true);
      
      try {
        const response = webhook.id
          ? await updateWebhook(updatedWebhook)
          : await createWebhook(updatedWebhook);

        if (response.status === 400) {
          setError(response.data.message[0]);
          return;
        }

        setSaved(true);
        setError('');

        await loadWebhooks();

        cancelEdit();
        setTimeout(() => {
          setSaved(false);
        }, 1500);
      } catch (e) {
        setError(JSON.stringify(e));
      } finally {
        setSaving(false);
      }
    }

    function EditWebhookBody() {
      const classes = useStyles()
      return (
        <>
          {error ? (
            <div
              style={{
                color: '#ff0000',
              }}
            >
              Error: {error}
            </div>
          ) : (
            <></>
          )}
          <Divider
            light
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
          />
          <div style={{ maxWidth: '480px' }}>
            <form onSubmit={handleSubmit(save)}>
              <div className='text-area'>
                <div className="text-label highlights">Endpoint URL</div>
                <div style={{ display: 'flex' }}>
                  <TextField
                    autoFocus
                    className='standard-text-field'
                    placeholder="Enter your endpoint URL"
                    type="text"
                    fullWidth
                    defaultValue={webhook.url}
                    {...register('url')}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between ',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <div
                  className='status'
                  style={{
                    fontWeight: '500',
                    fontSize: '14px',
                  }}
                >
                  Status: <span className="status-value">{webhook.enabled ? 'Active' : 'Inactive'}</span>
                </div>
                <ToggleSwitch
                  checked={webhook.enabled}
                  onChange={(e: any) =>
                    setWebhook({ ...webhook, enabled: e.target.checked })
                  }
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between ',
                  alignItems: 'center',
                }}
              >
                <div
                  className='status'
                  style={{
                    fontWeight: '500',
                    fontSize: '14px',
                  }}
                >
                  Sandbox
                </div>
                <ToggleSwitch
                  checked={webhook.isSandbox}
                  onChange={(e: any) =>
                    setWebhook({ ...webhook, isSandbox: e.target.checked })
                  }
                />
              </div>

              <Divider
                light
                style={{ marginTop: '30px' }}
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'end',
                  gap: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Button
                  disableRipple
                  disableFocusRipple
                  variant="outlined"
                  className="standard-btn cancel-btn"
                  onClick={() => cancelEdit()}
                  disabled={saving}
                >
                  <span style={{ color: '#344054' }}>Cancel</span>
                </Button>
                <SecuroButtonWithLoader
                  text={saved ? 'Saved' : 'Save'}
                  loadingText="Saving"
                  loading={saving}
                />
              </div>
            </form>
          </div>
        </>
      );
    }

    return (
      <>
        <MainCard
          title="Listen to ThirdFi events"
          titleStyle={{ fontSize: '18px', color: '#101828' }}
          subtitle="Setup your webhook endpoint to receive live events from ThirdFi."
          content={<EditWebhookBody />}
        />
      </>
    );
  }

  function cancelEdit() {
    setWebhook({});
  }

  function createNew() {
    setWebhook({ url: '', enabled: true });
  }

  function CreateNewWebhookButton() {
    const classes = useStyles()
    return (
      <Button
        variant="contained"
        color="primary"
        className={`${classes.saveButton} button-action`}
        disableRipple
        onClick={createNew}
      >
        + Add Endpoint
      </Button>
    );
  }

  function EndpointsTable() {
    const headers = [
      {
        title: 'URL',
        key: 'url',
        width: 7,
        class: 'url',
        rowStyle: {
          'overflow-wrap': 'break-word',
        },
      },
      {
        title: 'Live/Sandbox',
        key: 'isSandbox',
        width: 2,
        class: 'mode',
        style: {
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
          color: '#101828',
          fontWeight: '400',
          fontSize: '14px',
        },
        rowStyle: {
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
        },
      },
      {
        title: 'Status',
        key: 'enabled',
        width: 2,
        class: 'status',
        style: {
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
        },
        rowStyle: {
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 1,
        class: 'actions',
      },
    ];

    return (
      <div className="endpoints-table">
        {webhooks.length ? (
          <Card>
            <Grid
              className="header-pill-table-rows"
            >
              {headers.length &&
                headers.map((header: any) => {
                  return (
                    <Grid
                      className={header.class}
                      key={header.key}
                    >
                      <div className="pill-table-header--text">
                        {header.title}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            {webhooks.map((obj, index: number) => {
              return (
                <Grid
                  className="webhooks-pill-table-row"
                  key={`webhook-${index}`}
                >
                  {headers.map((header: any, headerIndex: number) => {
                    return (
                      <Grid
                        key={`${index}-${headerIndex}`}
                        className={header.class}
                      >
                        {header.key === 'enabled' ? (
                          <>
                            <Chip
                              label={obj.enabled ? 'Active' : 'Inactive'}
                              size="small"
                              style={{
                                color: obj.enabled ? '#027A48' : '#344054',
                                backgroundColor: obj.enabled
                                  ? '#ECFDF3'
                                  : '#F2F4F7',
                              }}
                            />
                          </>
                        ) : header.key === 'actions' ? (
                            <>{getValue(obj, header.key)}</>
                        ) : (
                          <div>{getValue(obj, header.key)}</div>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Card>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  function WebhookContent() {
    return (
      <div>
        <Divider
          light
          style={{ marginTop: '20px' }}
        />
        <MainCard
          title="Hosted Endpoints"
          titleStyle={{ fontSize: '14px' }}
          subtitle="Listen to live ThirdFi events"
          content={<EndpointsTable />}
        />
      </div>
    );
  }

  return (
    <>
      {Object.keys(webhook).length ? (
        <WebhookEdit />
      ) : (
        <MainCard
          title="Webhooks"
          subtitle="Add and edit webhooks here"
          titleRightContent={<CreateNewWebhookButton />}
          content={<WebhookContent />}
        />
      )}
    </>
  );
}

export default WebhookSetting;
