import { Divider, Grid, TextField, Button, Select, MenuItem, FormHelperText } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import "./customer.scss";
import { useEffect, useState } from "react";
import { getSingleCustomer, updateCustomer } from "../../services/axios/customer";

import CalendarIcon from "../../components/CalendarIcon";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { updateMsgState } from "../../store/slices/messages-v2-slice";
import { Controller, useForm } from "react-hook-form";
import { differenceInYears, parse } from "date-fns";
import dayjs from "dayjs";
import { EMAIL_PATTERN } from "../../constants/validation";
import { allCountries, CountryData } from 'country-region-data';
import goBackDeveloper from "../../assets/images/common/goBackDeveloper.svg";
import { useNavigate, useParams } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const useStyles = makeStyles(() => ({
    cancelButton: {
        height: "40px",
        fontSize: "14px",
        fontWeight: 700,
        border: "2px solid #D0D5DD",
        color: "#344054",
        textTransform: "capitalize",
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "#e5e9f1",
            border: "2px solid #e5e9f1",
            boxShadow: 'none'
        }
    },

    saveButton: {
        width: "65px",
        height: "40px",
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",
        backgroundColor: "#43D9BB",
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: "#36b299",
        }
    },

    root: {
        //flexDirection: "row-reverse",
        // borderRadius: "8px",
    }
}))

function EditCustomerForm(props: any) {
    //temporary
    const { goBackFunc, ID } = props;
    const classes = useStyles();
    const sandboxMode = useAppSelector(state => state.mode.sandboxMode);

    const params = useParams();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(`/customer/${params.id}`)
    }

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
        reset
    } = useForm({
        mode: 'onChange'
    });

    const [listOfCountry, setListOfCountry] = useState<any[]>([]);
    const [selectedCountryIndex, setSelectedCountryIndex] = useState<any>();
    const [isSelectedCountry, setIsSelectedCountry] = useState(false);
    const [selectedRegionIndex, setSelectedRegionIndex] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<any>("");

    const [region, setRegion] = useState<any[] | undefined>([]);

    useEffect(() => {
        const listCountry: any[] = [];
        allCountries.forEach((country: any) => {
            listCountry.push(country[0]);
        })
        setListOfCountry(listCountry);
    }, [])

    useDidMountEffect(() => {
        goBack();
    }, [sandboxMode])

    const handleCountry = (event: any) => {
        setSelectedRegionIndex(null);
        setIsSelectedCountry(true);
        const filter: any[] | undefined = allCountries.find((country, index) => country[0] === event.target.value);

        setRegion(filter?.at(2));
    }

    const getCustomerDetails = async (id: number) => {
        const res = await getSingleCustomer(id, sandboxMode);
        const customer = res.data.data;

        let getCountryIndex = allCountries.findIndex((item: CountryData, index) => item[0] === customer.country);

        setSelectedCountryIndex(customer.country)

        const filter: any[] | undefined = allCountries.at(getCountryIndex)?.[2];

        setSelectedRegionIndex(customer.state)
        if (customer.country !== null) {
            setIsSelectedCountry(true);
        }
        setRegion(filter);
        setSelectedStatus(customer.status);

        setValue('firstName', customer.firstName);
        setValue('lastName', customer.lastName);
        setValue('dateOfBirth', customer.dateofBirth);
        setValue('emailAddress', customer.emailAddress);
        setValue('streetAddress', customer.street);
        setValue('city', customer.city);
        setValue('state', customer.state);
        setValue('country', customer.country);
        setValue('postalCode', customer.postalCode);
        setValue('status', customer.status);
    }

    const dispatch = useDispatch();

    const onSave = async () => {
        if (params && params.id) {
            const payload = {
                id: params.id,
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                dateofBirth: getValues('dateOfBirth'),
                street: getValues('streetAddress'),
                city: getValues('city'),
                state: getValues('state'),
                country: getValues('country'),
                postalCode: getValues('postalCode').toString(),
                status: selectedStatus
            }
            await updateCustomer(parseInt(params.id), payload, sandboxMode)
            // await getCustomerDetails(ID)
            dispatch(updateMsgState({ state: "success", title: "Success", reason: "Customer details updated.", attempt: true, isMain: false }))

            goBack();
        } else {
            dispatch(updateMsgState({ state: "error", title: "Error", reason: "Something went wrong.", attempt: true, isMain: false }))
        }
    }

    const onCancel = () => {
        goBack();
    }

    const calculateAge = (dob: any): boolean => {
        const ddd = dayjs(dob).format('DD/MM/YYYY')
        const date = parse(ddd, "dd/MM/yyyy", new Date())
        const age = differenceInYears(new Date(), date)
        if (age <= 18) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (params && params.id) getCustomerDetails(parseInt(params.id));
    }, [])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`customer-details-container ${themeState}-customer-details`}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
                <Box className="customer">
                    <img src={goBackDeveloper} alt={"Go Back"} onClick={goBack} style={{ cursor: "pointer" }} />
                    Edit Customer
                </Box>
                <Box className="subtitle">
                    Edit your customer here
                </Box>
            </Box>
            {/* <Box>
                <Box style={{ fontWeight: 500, fontSize: "16px", cursor: "pointer" }} onClick={goBack}>
                    Go Back
                </Box>
            </Box> */}
        </Box>
        <Divider className="divider" />
        <form onSubmit={handleSubmit(onSave)}>
            <Box mt={2} mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            First Name
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your first name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("firstName", {
                                    required: "First Name is empty",
                                })}
                                error={!!errors?.firstName}
                                helperText={errors?.firstName?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            Last Name
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your last name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("lastName", {
                                    required: "Last Name is empty",
                                })}
                                error={!!errors?.lastName}
                                helperText={errors?.lastName?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            Date of Birth
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Controller
                                    control={control}
                                    name="dateOfBirth"
                                    rules={{
                                        validate: {
                                            min: (date) => calculateAge(date) || "Age must be above 18 years old"
                                        }
                                    }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <DatePicker
                                            className="mui-date-picker"
                                            {...field}
                                            inputRef={ref}
                                            components={{
                                                OpenPickerIcon: CalendarIcon
                                            }}
                                            PopperProps={{
                                                placement: "bottom-end",
                                                modifiers: [{
                                                    name: 'flip',
                                                    enabled: false,
                                                }]
                                            }}
                                            InputAdornmentProps={{ position: 'end' }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                name={name}
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                fullWidth
                                                size="small"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Date of Birth"
                                                }}
                                            />}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            Email
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("emailAddress", {
                                    required: "Email can't be empty",
                                    pattern: {
                                        value: EMAIL_PATTERN,
                                        message: 'Please enter a valid email',
                                    }
                                })}
                                error={!!errors?.emailAddress}
                                helperText={errors?.emailAddress?.message}
                                disabled
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider className="divider" />
            <Box my={3}>
                <Box className="customer" mb={2}>Address</Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box className="form-control-text">
                            Street
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your address"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("streetAddress", {
                                    required: "Street is empty",
                                })}
                                error={!!errors?.streetAddress}
                                helperText={errors?.streetAddress?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            Country
                        </Box>
                        <Box>
                            {selectedCountryIndex &&
                                <Select
                                    {...register("country", {
                                        required: "Country is empty",
                                        onChange: (e) => handleCountry(e)
                                    })}
                                    defaultValue={selectedCountryIndex !== null ? selectedCountryIndex : 0}
                                    error={!!errors?.country}
                                    fullWidth
                                    size="small"
                                >
                                    {listOfCountry.map((item: any, index: number) =>
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            }
                            {!selectedCountryIndex &&
                                <Select
                                    {...register("country", {
                                        required: "Country is empty",
                                        onChange: (e) => handleCountry(e)
                                    })}
                                    defaultValue={selectedCountryIndex !== null ? selectedCountryIndex : 0}
                                    error={!!errors?.country}
                                    fullWidth
                                    size="small"
                                >
                                    {listOfCountry.map((item: any, index: number) =>
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            }
                            {!!errors?.country &&
                                <FormHelperText><span className="text-error-500">Please select country</span></FormHelperText>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            State
                        </Box>
                        <Box>
                            {isSelectedCountry && region && region.length > 0 &&
                                <>
                                    <Select
                                        {...register("state", {
                                            required: "State is empty",
                                            onChange: (e) => {
                                                setSelectedRegionIndex(e.target.value)
                                            }
                                        })}
                                        defaultValue={selectedRegionIndex}
                                        error={selectedRegionIndex === null}
                                        fullWidth
                                        size="small"
                                    >
                                        {region.map((item: any, index: number) =>
                                            <MenuItem key={index} value={item[0]}>{item[0]}</MenuItem>
                                        )}
                                    </Select>
                                    {selectedRegionIndex === null &&
                                        <FormHelperText><span className="text-error-500">Please select state</span></FormHelperText>
                                    }
                                </>
                            }
                            {!isSelectedCountry &&
                                <Select disabled defaultValue={0}
                                    fullWidth
                                    size="small"
                                >
                                    <MenuItem selected={true} value={0}>- Please select country -</MenuItem>
                                </Select>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            City
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your city"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("city", {
                                    required: "City is empty",
                                })}
                                error={!!errors?.city}
                                helperText={errors?.city?.message}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="form-control-text">
                            Postal Code
                        </Box>
                        <Box>
                            <TextField id="outlined-basic"
                                placeholder="Enter your postal code"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...register("postalCode", {
                                    required: "Postal Code is empty",
                                })}
                                error={!!errors?.postalCode}
                                helperText={errors?.postalCode?.message}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box my={3}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box className="customer" mb={2}>Status</Box>
                        <Select
                            {...register("status", {
                                required: "Status is empty",
                                onChange: (e) => {
                                    setSelectedStatus(e.target.value)
                                }
                            })}
                            fullWidth
                            size="small"
                            value={selectedStatus}
                            error={!!errors?.state}
                        >
                            <MenuItem value="A">Active</MenuItem>
                            <MenuItem value="I">Inactive</MenuItem>
                        </Select>
                        {!!errors?.state &&
                            <FormHelperText><span className="text-error-500">Please select status</span></FormHelperText>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={6}>
                <Box mr={2}>
                    <Button
                        className={`${classes.cancelButton} override-cancel-btn !w-[80px]`}
                        size='small'
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        className={`${classes.saveButton} override-btn !w-[80px]`}
                        size='small'
                        type="submit"
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </form>
        <div className="global-bottom-spacer"></div>
    </Box>
}

export default EditCustomerForm;
