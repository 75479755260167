import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ITabState {
    tabView: number
}

const initialState = {
    tabView: 0
} as ITabState;

const tabSlice = createSlice({
    name: "tabView",
    initialState,
    reducers: {
        updateTabState(state, payload) {
            state.tabView = payload.payload
        }
    }
})

const baseInfo = (state: RootState) => state.app;

export default tabSlice.reducer;

export const { updateTabState } = tabSlice.actions;

export const getTabState = createSelector(baseInfo, tab => tab);