import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../constants/validation';
import { registerUser, checkReferralCode } from '../../services/axios/user';
import { error } from '../../store/slices/messages-slice';
// import '../ResetPassword/reset-password.scss';
import './index.scss';
import CreateAccountConfirmation from './confirmation';
import BackIcon from '../../assets/images/icons/back-arrow.svg';

import VisibilityOff from '../../assets/images/icons/visible-eye-off.svg';
import Visibility from '../../assets/images/icons/visible-eye.svg';
import FormErrorIcon from '../../assets/images/icons/form-error-icon.svg';
import CloseButton from '../../assets/images/icons/close-crossed-icon-warn.svg';
import EmailIcon from '../../assets/images/login/email.svg';
import SocialLogins from '../SocialLogins';
import { useAuth } from '../../services/auth/authProvider';
import ErrorIcon from '../../assets/images/icons/error-icon.svg';
import { useAppSelector } from '../../store/hooks';
import CompanyLogo from '../../assets/images/company';

interface State {
  password: string;
  showPassword: boolean;
}

function CreateAccount() {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const [view, setView] = useState(0);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [onExistEmail, setOnExistEmail] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  let auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState('');
  const [hasReferralQuery, setHasReferralQuery] = useState(false);
  const [invalidReferralCode, setInvalidReferralCode] = useState(false);
  const [useEmail, setUseEmail] = useState(false);

  useEffect(() => {
    const checkCode = async () => {
      const user = !auth.user ? localStorage.getItem('user') : auth.user;
      if (user) navigate('/', { replace: true });
      const code = searchParams.get('referral');
      if (code) {
        setHasReferralQuery(true);
        setReferralCode(code);
        const validCode = await checkReferralCode(code);
        if (!validCode || !validCode.data.data) setInvalidReferralCode(true);
      }
    };

    checkCode();
    return () => { };
  }, []);

  const updateReferralCode = (code: string) => {
    setReferralCode(code);
    setInvalidReferralCode(false);
  };

  const [showUnchecked, setShowUnchecked] = useState(false);
  const onSubmit = async (data: any) => {
    if (referralCode) {
      const validCode = await checkReferralCode(referralCode);
      if (!validCode || !validCode.data.data) {
        setInvalidReferralCode(true);
        return;
      }
    }
    const email = data.email;
    const password = data.password;
    const firstName = data.email;

    if (checked1) {
      try {
        const response: any = await registerUser(
          email,
          password,
          firstName,
          referralCode,
        );

        if (Object.keys(response.data.data).length === 0) {
          const errorMessage = response.data.message;
          dispatch(
            error({
              text: errorMessage,
              error: 'Something wrong',
            }),
          );
          reset();
          return;
        }
        setRegisteredEmail(email);
        setView(1);
      } catch (e) {
        // ;TODO
        setOnExistEmail(true);
      }
      setShowUnchecked(false);
    } else {
      setShowUnchecked(true);
    }
  };

  const [checked1, setChecked1] = useState(false);

  const handleChange1 = (event: any) => {
    setChecked1(event.target.checked);
  };

  const backToLogin = () => {
    setUseEmail(false);
    navigate('/main');
  };

  const textFieldTheme = createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginBottom: '0px',
            marginTop: 0,
          },
        },
      },
    },
  });

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });

  const [values2, setValues2] = useState<State>({
    password: '',
    showPassword: false,
  });
  const [showVisible, setShowVisible] = useState(true);
  const [showVisible2, setShowVisible2] = useState(true);
  const handleChange = (event: BaseSyntheticEvent, target: number) => {
    switch (target) {
      case 1:
        if (event.target.value) {
          setShowVisible(true);
        } else {
          setShowVisible(false);
        }
        break;
      case 2:
        if (event.target.value) {
          setShowVisible2(true);
        } else {
          setShowVisible2(false);
        }
        break;
    }
  };

  const handleClickShowPassword = (target: number) => {
    switch (target) {
      case 1:
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
        break;
      case 2:
        setValues2({
          ...values2,
          showPassword: !values2.showPassword,
        });
        break;
    }
  };

  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <>
      {view === 0 && (
        <div className={`register-page-wrapper ${themeState}-register-page`}>
          <div className="upper-wrapper">
            <div className="create-acc-logo-wrapper">
              <CompanyLogo mode={themeState} />
            </div>
            <div className="divider"></div>
            {showUnchecked && (
              <div
                style={{ position: 'absolute', top: '0', marginTop: '96px' }}
              >
                <div className="on-unchecked">
                  <div className="unchecked-msg text-sm font-medium text-error-500">
                    <img src={FormErrorIcon} />
                    Please click the check box to agree our T&C.
                  </div>
                  <div
                    className="on-close"
                    onClick={() => setShowUnchecked(false)}
                  >
                    <img src={CloseButton} />
                  </div>
                </div>
              </div>
            )}

            {invalidReferralCode ? (
              <div className="flex gap-4 bg-error border border-solid border-error-300 text-sm rounded-md px-4 py-4 w-full mb-4 align-baseline">
                <div className="pt-1">
                  <img
                    src={ErrorIcon}
                    className="max-w-[12px]"
                  ></img>
                </div>
                <div>
                  <div className="text-error-700 font-medium">
                    Referral code invalid
                  </div>
                  <div className="text-error-600">
                    Please check your referral link.
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="intro-wrapper">
              <div className="mounted text-3xl font-semibold text-gray-900">
                Create an account
              </div>
            </div>

            <div
              className={`${hasReferralQuery && !useEmail ? 'block' : 'hidden'
                }`}
            >
              <div className="flex justify-items-center align-center flex-col mb-8">
                <div className="text-lg text-gray-700 text-center mb-2">
                  Referral Code
                </div>
                <div className="wrapped-input on-default pb-0">
                  <div className="font-semibold text-3xl border border-gray-300 border-solid text-center px-4 py-3 mounted">
                    {referralCode}
                  </div>
                </div>
              </div>
            </div>

            <div className={`mb-12 ${!useEmail ? 'block' : 'hidden'}`}>
              <SocialLogins vertical={true} />

              <div
                className="mounted-social mt-6 flex gap-6 items-center cursor-pointer rounded-md border border-gray-300 border-solid  px-6 py-4"
                onClick={(e) => setUseEmail(true)}
              >
                <img
                  src={EmailIcon}
                  width="30px"
                />
                <div className="text-gray-500 text-md">Continue with Email</div>
              </div>
            </div>

            <div className={`${useEmail ? 'block' : 'hidden'}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="register-container">
                  <div
                    className={`wrapped-input ${!!errors.email ? `on-error` : `on-default`
                      }`}
                  >
                    <div className="text-input-title text-sm font-medium text-gray-900">
                      Email<span className="asterisk">*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter your email"
                      style={{ width: '100%' }}
                      {...register('email', {
                        required: "Email can't be empty",
                        pattern: {
                          value: EMAIL_PATTERN,
                          message: 'Please enter a valid email',
                        },
                      })}
                      InputProps={{
                        endAdornment: (
                          <div className="hide-adorment">
                            {!!errors?.email && (
                              <div>
                                <img src={FormErrorIcon} />
                              </div>
                            )}
                          </div>
                        ),
                      }}
                      error={!!errors?.email}
                      helperText={
                        <>
                          {errors?.email && (
                            <div className="text-sm font-normal text-error-500">
                              {errors?.email?.message}
                            </div>
                          )}
                          {onExistEmail && (
                            <div className="text-sm font-normal text-error-500">
                              Your email was used. Please use another one.
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <div
                    className={`wrapped-input ${!!errors.password ? `on-error` : `on-default`
                      }`}
                  >
                    <div className="text-input-title text-sm font-medium text-gray-900">
                      Password<span className="asterisk">*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter your password"
                      type={values.showPassword ? 'text' : 'password'}
                      variant="outlined"
                      style={{ width: '100%' }}
                      {...register('password', {
                        required: "Password can't be empty",
                        pattern: {
                          value: PASSWORD_PATTERN,
                          message:
                            'Password must be a combination of at least 1 uppercase, 1 lower case, 1 number and 1 special character with min length of 8',
                        },
                        onChange: (e) => handleChange(e, 1),
                      })}
                      InputProps={{
                        endAdornment: (
                          <div className="hide-adorment">
                            {!!errors?.password && (
                              <div>
                                <img src={FormErrorIcon} />
                              </div>
                            )}
                            {showVisible && errors?.password === undefined && (
                              <div
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(1)}
                              >
                                {!values.showPassword ? (
                                  <img src={VisibilityOff} />
                                ) : (
                                  <img src={Visibility} />
                                )}
                              </div>
                            )}
                          </div>
                        ),
                      }}
                      error={!!errors?.password}
                      helperText={
                        <>
                          {errors?.password && (
                            <div className="text-sm font-normal text-error-500">
                              {errors?.password?.message}
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <div
                    className={`wrapped-input ${!!errors.confirmedPassword ? `on-error` : `on-default`
                      }`}
                  >
                    <div className="text-input-title text-sm font-medium text-gray-900">
                      Confirm Password<span className="asterisk">*</span>
                    </div>
                    <TextField
                      id="outlined-basic"
                      placeholder="Confirm your password"
                      type={values2.showPassword ? 'text' : 'password'}
                      variant="outlined"
                      style={{ width: '100%' }}
                      {...register('confirmedPassword', {
                        required: "Confirmed Password can't be empty",
                        onChange: (e) => handleChange(e, 2),
                        validate: (val: string) => {
                          if (watch('password') !== val) {
                            return 'Password does not match';
                          }
                        },
                      })}
                      InputProps={{
                        endAdornment: (
                          <div className="hide-adorment">
                            {!!errors?.confirmedPassword && (
                              <div>
                                <img src={FormErrorIcon} />
                              </div>
                            )}

                            {showVisible2 &&
                              errors?.confirmedPassword === undefined && (
                                <div
                                  aria-label="toggle password visibility"
                                  onClick={() => handleClickShowPassword(2)}
                                >
                                  {!values2.showPassword ? (
                                    <img src={VisibilityOff} />
                                  ) : (
                                    <img src={Visibility} />
                                  )}
                                </div>
                              )}
                          </div>
                        ),
                      }}
                      error={!!errors?.confirmedPassword}
                      helperText={
                        <>
                          {errors?.confirmedPassword && (
                            <div className="text-sm font-normal text-error-500">
                              {errors?.confirmedPassword?.message}
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <div
                    className={`wrapped-input ${invalidReferralCode ? `on-error` : `on-default`
                      }`}
                  >
                    <div className="text-input-title text-sm font-medium text-gray-900">
                      Referral Code (Optional)
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      style={{ width: '100%' }}
                      value={referralCode}
                      onChange={(e) => updateReferralCode(e.target.value)}
                      disabled={hasReferralQuery}
                      helperText={
                        <>
                          {invalidReferralCode && (
                            <div className="text-sm font-normal text-error-500">
                              Invalid referral code
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <FormGroup>
                    <div className="wrapped-input">
                      <div className="wrapped-options">
                        <Checkbox
                          checked={checked1}
                          onChange={handleChange1}
                        />
                        <div className="agreement text-xs font-medium text-gray-500 leading-5">
                          Investments in the ThirdFi products are speculative
                          investments that involve high degrees of risk,
                          including loss of invested funds. ThirdFi Products are
                          not suitable for any investor that cannot afford loss
                          of the entire investment. Carefully consider each
                          Product’s investment objectives, risk factors, fees
                          and expenses before investing. This and other
                          information can be found in each Product’s factsheet,
                          which may be obtained from ThirdFi's website.
                        </div>
                      </div>
                    </div>

                    <div className="btn-decoration">
                      <div className="btn-padding">
                        <Button
                          className={`btn on-invalid-state`}
                          variant="contained"
                          type="submit"
                        >
                          <div className="text-base font-medium text-white">
                            Sign up
                          </div>
                        </Button>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </form>
            </div>

            <div className="wrapped-input text-center">
              <div className="policy text-sm font-normal text-gray-500">
                <span>
                  By creating an account, you agree to our
                  <div className="links">
                    <a
                      href="https://thirdfi.s3.us-east-2.amazonaws.com/AML+%26+KYC+policy.pdf"
                      target="_BLANK"
                      rel="noopener noreferrer"
                      className="text-primary kyc-text"
                    >
                      KYC Policy
                    </a>
                    ,&nbsp;{' '}
                    <a
                      href="https://thirdfi.s3.us-east-2.amazonaws.com/Terms+of+Service.pdf"
                      target="_BLANK"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      Terms of Service&nbsp;
                    </a>
                    and&nbsp;
                    <a
                      href="https://thirdfi.s3.us-east-2.amazonaws.com/Privacy+Policy.pdf"
                      target="_BLANK"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </span>
              </div>
            </div>
            <div className="wrapped-input">
              <div
                onClick={backToLogin}
                className="back-btn text-sm font-medium text-gray-500"
              >
                <img src={BackIcon} /> Back to login
              </div>
            </div>
          </div>
          <div className="about-wrapped-register text-base font-normal text-gray-400 mt-4">
            © ThirdFi 2023. All Rights Reserved.
          </div>
        </div>
      )}
      {view === 1 && <CreateAccountConfirmation email={registeredEmail} />}
    </>
  );
}

export default CreateAccount;
