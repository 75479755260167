import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

import DocSubmission from "../pages/Kyc";
import ViewBase from "../components/ViewBase";

import "../components/app.scss";
import Main from "../pages/Main";
import ResetPassword from "../components/ResetPassword";

import Recovery from "../components/ResetPassword/recovery";
import ChangePassword from "../components/ResetPassword/changePassword";
import ThemedSnackBar, { SnackBarProps } from "../components/Snackbar";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AlertColor } from "@mui/material";
import { useAuth } from "../services/auth/authProvider";
import CreateAccount from "../components/CreateAccount";

import useGetProfile from "../hooks/user";

import AccountComponent from "../pages/My Profile";
import Settings from "../pages/Settings";

import useStrategyPerformance from "../hooks/strategyPerformance";
import usePortfolioAllocation from "../hooks/portfolioAllocation";
import { useInactivityChecker } from "../hooks/inactiveUser";
import { useRefreshTokens, useOnPageRefresh } from "../hooks/refreshToken";
import AlertBox from "../components/AlertBox";
import { notify } from "../store/slices/messages-slice";
import { updateSlackConnectionStatus, updateTelegramConnectionStatus } from "../store/slices/social-media-slice";
import DeveloperTools from "../pages/DeveloperTools";
import TransactionLisiting from "../pages/TransactionListing";
import Customer from "../pages/Customer";
import BusinessDetails from "../pages/BusinessDetails";
import Reporting from "../pages/Reporting";
import Referral from "../pages/Referral";
import DeveloperDashboard from "../pages/DeveloperDashboard";

import Snacky from "./CustomSnackbar";
import { updateBellIconBadgeStatus } from "../store/slices/bell-icon-badge-slice";
import { useThemeSwitch } from "../utils/theme-switch";
import Switched from "../components/Lottie/Switched";
import TransactionDetails from "../pages/TransactionListing/transactionDetails";
import CustomerDetails from "../pages/Customer/CustomerDetails";
import EditCustomerForm from "../pages/Customer/editCustomerForm";
import APILoggingDetails from "../pages/DeveloperTools/APILogging/APILoggingDetails";
import WebhookEventsDetails from "../pages/DeveloperTools/WebhookEvents/WebhookEvents";
import useDev from "../hooks/useDev";
import ResetPasswordV2 from "../components/ResetPasswordV2";

function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();

  const user = !auth.user ? localStorage.getItem("user") : auth.user;

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

function App() {
  useGetProfile();
  // useProducts();
  // usePortfolio();
  // useRecentTransaction();
  useStrategyPerformance();
  usePortfolioAllocation();
  useInactivityChecker();
  useRefreshTokens();
  useOnPageRefresh();
  useThemeSwitch();
  useDev();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const ignoredPage = ['main', 'forget-password', 'register', ''];

  const status = useAppSelector(state => state.inactivityStatus.inactiveStatus)

  const msg = useAppSelector((state) => state.message);
  const msgv2 = useAppSelector((state) => state.messagev2.messagesv2);

  const [messageText, setMessageText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [notificationId, setNotificationId] = useState(0);
  const [fromGetUnreadUserNotification, setFromGetUnreadUserNotification] = useState<Boolean | null>(null)
  const [severity, setSeverity] = useState<AlertColor>("info");
  const [snackbar, setSnackBar] = useState(false);

  useEffect(() => {
    if (msg.message !== undefined && msg.message !== null) {
      const message: any = msg.message;
      setSeverity(message.severity);
      if (message.text) setMessageText(message.text);
      if (message.title) setTitleText(message.title);
      if (message.createdAt) setCreatedAt(message.createdAt);
      if (message.id) setNotificationId(message.id);
      if (message.fromGetUnreadUserNotification) setFromGetUnreadUserNotification(message.fromGetUnreadUserNotification);
      setSnackBar(true);
    }
  }, [msg]);

  let snackbarProps: SnackBarProps = {
    message: messageText,
    open: snackbar,
    handleOpen: setSnackBar,
    autoHideDuration: 5000,
    type: severity,
    title: titleText,
    createdAt: createdAt,
    id: notificationId,
    fromGetUnreadUserNotification
  };
  const logoutState = useAppSelector(state => state.logout.showLogout);

  return (
    <>
      <Switched />
      <ViewBase>
        <div className={`flex-row flex-content-center ${ignoredPage.includes(location.pathname.replace('/', '')) ?
          '' : 'maximize-height'}`}>
          {<AlertBox show={status} type="session" />}
          {<AlertBox show={logoutState} type="logout" />}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/forget-password" element={<Recovery />} />
            <Route path="/register" element={<CreateAccount />} />
            <Route path="*" element={<Main />} />
            <Route element={<RequireAuth />}>
              <Route path="/reset-password" element={<ResetPasswordV2 />} />
              <Route path="/my-account" element={< AccountComponent />} />
              <Route path="/verify" element={<DocSubmission />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/settings">
                <Route index={true} element={<Settings />}></Route>
                <Route index={false} path=":id"></Route>
              </Route>
              <Route path="/developer-dashboard" element={<DeveloperDashboard />}></Route>
              <Route path="/api-logging">
                <Route index={true} element={<DeveloperTools />} />
                <Route index={false} path=":id" element={<APILoggingDetails />} />
              </Route>
              <Route path="/webhook-events">
                <Route index={true} element={<DeveloperTools />}></Route>
                <Route index={false} path=":id" element={<WebhookEventsDetails />}></Route>
              </Route>
              <Route path="/transaction-listing">
                <Route index={true} element={<TransactionLisiting />}></Route>
                <Route index={false} path=":id" element={<TransactionDetails />}></Route>
              </Route>
              <Route path="/customer">
                <Route index={true} element={<Customer />} />
                <Route index={false} path=":id" element={<CustomerDetails />} />
                <Route index={false} path=":id/edit" element={<EditCustomerForm />} />
              </Route>
              <Route path="/business-details" element={<BusinessDetails />}></Route>
              <Route path="/reporting" element={<Reporting />}></Route>
              <Route path="/referral-program" element={<Referral />}></Route>
            </Route>
          </Routes>
        </div>
        <ThemedSnackBar {...snackbarProps} />
        <Snacky payload={msgv2} />
      </ViewBase>
    </>
  );
}

export default App;
