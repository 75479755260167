import React from 'react';

export const STATUS_CODE_STYLE = (status: any) => {

    if (!status) {
        return <div className="status-code-bounded-neutral">null</div>
    }
    if (status === "") {
        return <div className="status-code-bounded-neutral">null</div>
    }

    if ((status.toString())[0] === "2") {
        return <div className="status-code-bounded-success">{status}</div>
    }
    if (status.toString()[0] === "4") {
        return <div className="status-code-bounded-error">{status}</div>
    }
    if (status.toString()[0] === "5") {
        return <div className="status-code-bounded-error">{status}</div>
    }
}