import axios, { getJWT } from "./axios";

export const getAPILogs = async (page: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/api-logs?limit=${10}&page=${page}&sandbox=${sandbox}`, header);
    return res;
}

export const getSingleAPILog = async (id: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/api-logs/${id}?sandbox=${sandbox}`, header);
    return res;
}

export const getAPILogsFromSearch = async (url: string, page: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/api-logs?url=${url}&limit=${10}&page=${page}&sandbox=${sandbox}`, header);
    return res;
}

export const getAPILogsTimeSeries = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/api-logs/time-series?startDate=${start}&endDate=${end}&sandbox=${sandbox}`, header);
    return res;
}

export const getAPIErrorsTimeSeries = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/api-logs/time-series-errors?startDate=${start}&endDate=${end}&sandbox=${sandbox}`, header);
    return res;
}