import { createSlice } from '@reduxjs/toolkit';

export interface IBellIconBadge {
    showBellIconBadge: boolean
}

const initialState: IBellIconBadge = {
    showBellIconBadge: false
}

const bellIconBadgeSlice = createSlice({
    name: 'bellIconBadgeSlice',
    initialState,
    reducers: {
        updateBellIconBadgeStatus(state) {
            state.showBellIconBadge = !state.showBellIconBadge
        }
    }
})

export const { updateBellIconBadgeStatus } = bellIconBadgeSlice.actions

export default bellIconBadgeSlice.reducer