import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IDrawerState {
    drawerData: boolean
}

const initialState = {
    drawerData: false
} as IDrawerState;

const drawerSlice = createSlice({
    name: "drawerData",
    initialState,
    reducers: {
        updateDrawerState(state, payload) {
            state.drawerData = payload.payload
        }
    }
})

const baseInfo = (state: RootState) => state.app;

export default drawerSlice.reducer;

export const { updateDrawerState } = drawerSlice.actions;

export const getDrawerState = createSelector(baseInfo, drawer => drawer);