import SvgIcon from '@mui/material/SvgIcon';

export default function CalIcon() {
    return (
        <SvgIcon viewBox="0 1 17 20">
            <svg width="16" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.168 8.33464H1.16797M12.0013 1.66797V5.0013M5.33464 1.66797V5.0013M5.16797 18.3346H12.168C13.5681 18.3346 14.2682 18.3346 14.8029 18.0622C15.2733 17.8225 15.6558 17.44 15.8955 16.9696C16.168 16.4348 16.168 15.7348 16.168 14.3346V7.33464C16.168 5.9345 16.168 5.23444 15.8955 4.69966C15.6558 4.22925 15.2733 3.8468 14.8029 3.60712C14.2682 3.33464 13.5681 3.33464 12.168 3.33464H5.16797C3.76784 3.33464 3.06777 3.33464 2.53299 3.60712C2.06259 3.8468 1.68014 4.22925 1.44045 4.69966C1.16797 5.23444 1.16797 5.9345 1.16797 7.33464V14.3346C1.16797 15.7348 1.16797 16.4348 1.44045 16.9696C1.68014 17.44 2.06259 17.8225 2.53299 18.0622C3.06777 18.3346 3.76784 18.3346 5.16797 18.3346Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    )
}