import "./index.scss";

interface ISecuroButtonProps {
  color?: string;
  style?: any;
  clickHandler?: any;
  title: string;
}

function SecuroTextButtons(props: ISecuroButtonProps) {
  const style = {
    color: props.color || 'red',
    ...props.style,
  };

  return (
    <button
      className="securo-text-button"
      style={style}
      onClick={(e) => props.clickHandler(e)}
    >
      {props.title}
    </button>
  );
}

export default SecuroTextButtons;
