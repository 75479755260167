import ApiKey from '../../components/ApiKey';
import WebhookSetting from '../../components/WebhookSetting';
import { Box, Tabs, Tab, Typography, useMediaQuery, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import NotificationSettings from '../../components/NotificationSettings';
import { useAppSelector } from '../../store/hooks';
import { useDispatch } from 'react-redux';
import { updateTabState } from '../../store/slices/common-slice';
import "./styles.scss";
import { BrowserRouter as Router, Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

export const bindPage: any = {
  0: "api-setup",
  1: "sandbox-setup",
  2: "notification-settings",
  3: "webhook-setup"
}

function Settings() {
  const tabState = useAppSelector(state => state.tab.tabView);
  const [view, setView] = useState<number>();
  const isDesktop = useMediaQuery('(min-width: 720px)');

  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search.replace('?', '');

  useEffect(() => {
      setView(0);
  }, [])

  useEffect(() => {
    // Object.values(bindPage).findIndex((item: any) => item === search) 
    // ;TODO can be usable for unknown page checking

    setView(Object.values(bindPage).findIndex((item: any) => item === search))
  }, [search])
  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <>
          {/* {!developerPortal &&
            <div className="individual-setting-page">
              <Box>
              {search === bindPage[2] && <NotificationSettings />}
              </Box>
            </div>
          } */}

            <div className={`setting-page ${themeState}-settings-tabs`}>
              {isDesktop && 
              <Tabs
                key={view}
                value={view}
                onChange={(e: SyntheticEvent<Element, Event>, newView: number) => {
                    // dispatch(updateTabState(newView))
                    setView(newView)
                    navigate({
                      search: `?${bindPage[newView]}`
                    })
                }}
                aria-label="basic tabs example"
              >
                  <Tab
                    className='tab-config'
                    label="API Setup"
                    style={{ textTransform: 'capitalize' }}
                    value={0}
                  />
                  <Tab
                    className='tab-config'
                    label="Sandbox Setup"
                    style={{ textTransform: 'capitalize' }}
                    value={1}
                  />
                  <Tab
                    className='tab-config'
                    label="Webhook Setup"
                    style={{ textTransform: 'capitalize' }}
                    value={3}
                  />
              </Tabs>
              }
              {(!isDesktop) &&
                <Box className='DARK-select-settings'>
                    <Select
                        className="standard-select-options"
                        value={tabState}
                        defaultValue={0}
                        onChange={(e: any) => {
                          setView(e.target.value)
                          navigate({
                            search: `?${bindPage[e.target.value]}`
                          })
                        }}
                        fullWidth
                    >
                        <MenuItem value={0}>API Setup</MenuItem>
                        <MenuItem value={1}>Sandbox Setup</MenuItem>
                        <MenuItem value={3}>Webhooks</MenuItem>
                    </Select>
                </Box>
              }
              {search === bindPage[0] && <ApiKey />}
              {search === bindPage[1] && <ApiKey isSandbox={true} />}
              {search === bindPage[3]  && <WebhookSetting />}
            </div>
      </>
  );
}
export default Settings;
