import { Box, Button, ButtonGroup, Divider, MenuItem, Select, SelectChangeEvent, Skeleton, useMediaQuery } from "@mui/material";
import CommonTable from "../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../components/DeveloperToolsComponents/EmptyCard";
import { ReactComponent as ExportIcon } from "../../assets/images/icons/exportIcon.svg";
import { CSVLink } from "react-csv";

import "./transactionListingStyles.scss";
import TransactionDetails, {
    AAVE_ACTION_TYPE_PREFIX,
    AAVE_SESSION_TYPE_PREFIX,
    VENUS_ACTION_TYPE_PREFIX,
    VENUS_SESSION_TYPE_PREFIX,
} from "./transactionDetails";
import { useEffect, useState } from "react";
import { getAllDeveloperTransactions, getDeveloperTransactionsFromFilter } from "../../services/axios/transaction-listing";
import { useAppSelector } from "../../store/hooks";
import { TRANSACTION_STATUS, TX_FILTER } from "../../constants/general";
import { Loading } from "../../components/Lottie/Loading";

function TransactionLisiting() {
    const headings = ['Date/Time', 'Amount', 'Type', 'Transaction Category', 'Status']
    const [displayTable, setDisplayTable] = useState(false);
    const [displayTrnasactionDetail, setDisplayTrnasactionDetail] = useState(false)
    const [contentRows, setContentRows] = useState<string[][]>([[]])
    const [pageNumber, setPageNumber] = useState(1);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [ID, setID] = useState(0);
    const [filter, setFilter] = useState("");
    const [exportArrayForCSV, setExportArrayForCSV] = useState<any[]>([]);
    const [exportArray, setExportArray] = useState<string[][]>([[]]);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const fillStatus: any = {
        "S": {
            text: "Success",
            class: "wrapped-status case-success text-success-700 on-status"
        },
        "P": {
            text: "Pending",
            class: "wrapped-status case-pending text-gray-500 on-status"
        },
        "F": {
            text: "Failed",
            class: "wrapped-status case-failed text-error-600 on-status"
        },
        "R": {
            text: "Refund",
            class: "wrapped-status case-failed text-error-600 on-status"
        },
        "V": {
            text: "Void",
            class: "wrapped-status case-failed text-error-600 on-status"
        },
        "failed": {
            text: "Failed",
            class: "wrapped-status case-failed text-error-600 on-status"
        },
        "expired": {
            text: "Expired",
            class: "wrapped-status case-failed text-error-600 on-status"
        },
        "open": {
            text: "Pending",
            class: "wrapped-status case-pending text-gray-500 on-status"
        },
        "completed": {
            text: "Success",
            class: "wrapped-status case-success text-success-700 on-status"
        },
        "pending": {
            text: "Pending",
            class: "wrapped-status case-pending text-gray-500 on-status"
        },
    }

    const mappingForExport = (log: any) => {
        const arr = [];
        arr[0] = (new Date(log.createAt)
            .toLocaleDateString
            (undefined,
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })).toString()
        arr[1] = parseFloat(log.amount).toFixed(12)

        if (log.actionType === "DEPOSIT" || log.actionType === "WITHDRAW") {
            if (log.session && log.product && log.product.symbol) {
                arr[1] = parseFloat(log.amount).toFixed(8) + " " + log.product.symbol
            } else {
                arr[1] = parseFloat(log.amount).toFixed(8) + " " + log.currency
            }
        }
        else if (log.actionType === "SWAP") {
            if (log.session && log.session.params) {
                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                if (parsedParams.exactIn) {
                    arr[1] = parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"
                } else {
                    arr[1] = parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"
                }

            }
        }
        else if (log.actionType === "DEX_LIQUIDITY") {
            if (log.session && log.session.params) {
                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                let amount0Token = parsedParams.pair.split("-")[0];
                let amount1Token = parsedParams.pair.split("-")[1];

                arr[1] = `Amount0: ${amount0} ${amount0Token}; Amount1: ${amount1} ${amount1Token}`
            }
        }
        else if (log.actionType === "DEX_LIQUIDITY_2") {
            if (log.session && log.session.params) {
                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                let amount = parseFloat(parsedParams.amount).toFixed(12);

                if (parsedParams.addLiquidity) {
                    arr[1] = `Amount: ${amount} ${parsedParams.inputTokenSymbol}`
                } else {
                    arr[1] = `Amount: ${amount} ${parsedParams.pair} LP`
                }
            }
        }
        else if (log.actionType === "APPROVE") {
            if (log.session && log.session.params) {
                const parsedParams = (typeof log.session.params === 'string') ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params : log.session.params
                if (log.session.type === "deposit" || log.session.type === "withdraw") {
                    arr[1] = parseFloat(log.session.amount).toFixed(8) + " " + log.product.symbol;
                } else if (parsedParams.exactIn === true) {
                    arr[1] = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"
                } else if (parsedParams.exactIn === false) {
                    arr[1] = parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"
                } else if (log.session.type == "dex_liquidity_2" && (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false)) {
                    let amount = parseFloat(parsedParams.amount).toFixed(12);
                    if (parsedParams.addLiquidity) {
                        arr[1] = `Amount: ${amount} ${parsedParams.inputTokenSymbol}`
                    } else {
                        arr[1] = `Amount: ${amount} ${parsedParams.pair} LP`
                    }
                } else if (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false) {
                    let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                    let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                    let amount0Token = parsedParams.pair.split("-")[0];
                    let amount1Token = parsedParams.pair.split("-")[1];

                    arr[1] = `Amount0: ${amount0} ${amount0Token}; Amount1: ${amount1} ${amount1Token}`
                } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                    arr[1] = getAmountOfAaveSession(log.session.type, log.session.params, log.data);
                } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                    arr[1] = getAmountOfVenusSession(log.session.type, log.session.params, log.data);
                }
            }
        }
        else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
            if (log.session && log.session.params) {
                arr[1] = getAmountOfAaveSession(log.session.type, log.session.params, log.data);
            }
        }
        else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
            if (log.session && log.session.params) {
                arr[1] = getAmountOfVenusSession(log.session.type, log.session.params, log.data);
            }
        }

        arr[2] = log.actionType.toLowerCase()
        if (!log.payment && log.session) {
            if (log.session.type === "deposit") arr[2] = "Deposit"
            else if (log.session.type === "withdraw") arr[2] = "Withdraw"
            else if (log.session.type === "dex_swap") arr[2] = "Swap"
            else if (log.session.type === "dex_liquidity") {
                const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                if (parsedParams.addLiquidity) {
                    arr[2] = "Add Liquidity"
                } else {
                    arr[2] = "Remove Liquidity"
                }
            } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                arr[2] = getTypeOfAaveSession(log.session.type);
            } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                arr[2] = getTypeOfVenusSession(log.session.type);
            }
        }

        arr[3] = log.transactionCategory.toLowerCase()

        if (log.payment) {
            arr[4] = fillStatus[log.payment.status].text
        } else if (log.session) {
            arr[4] = fillStatus[log.session.status].text
        } else {
            arr[4] = fillStatus[log.status].text
        }

        arr[4] = fillStatus[log.status].text
        arr[5] = log.id.toString();

        return arr;
    }

    const getAllDeveloperTransactionsFunc = async (_pageNumber: number) => {
        try {
            const transactionsData = await getAllDeveloperTransactions(_pageNumber, sandboxMode);

            const transactionsArray = transactionsData.data.data;
            const totalLength = transactionsData.data.total;
            const resultArray: string[][] = [];
            const _exportArray: string[][] = [];

            if (transactionsArray.length > 0) {
                setDisplayTable(true)
            } else {
                setDisplayTable(false)
            }

            transactionsArray.map((log: any, i: number) => {
                const arr = [];

                arr[0] = <div className="standard-table-cell-date">{(new Date(log.createAt)
                    .toLocaleDateString
                    (undefined,
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        })).toString()}</div>

                arr[1] = <div className="text-sm font-normal text-gray-500">{parseFloat(log.amount).toFixed(8)}</div>
                if (log.actionType === "DEPOSIT" || log.actionType === "WITHDRAW") {
                    if (log.session && log.product && log.product.symbol) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.product.symbol}</div>
                    } else {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.currency}</div>
                    }
                }
                else if (log.actionType === "SWAP") {
                    if (log.session && log.session.params) {
                        const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                        if (parsedParams.exactIn) {
                            arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                        } else {
                            arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                        }

                    }
                }
                else if (log.actionType === "DEX_LIQUIDITY") {
                    if (log.session && log.session.params) {
                        const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                        let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                        let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                        let amount0Token = parsedParams.pair.split("-")[0];
                        let amount1Token = parsedParams.pair.split("-")[1];

                        arr[1] = <div className="standard-table-cell">
                            {`Amount0: ${amount0} ${amount0Token}`}
                            <br></br>
                            {`Amount1: ${amount1} ${amount1Token}`}
                        </div>
                    }
                }
                else if (log.actionType === "DEX_LIQUIDITY_2") {
                    if (log.session && log.session.params) {
                        const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                        let amount = parseFloat(parsedParams.amount).toFixed(12);

                        if (parsedParams.addLiquidity) {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                            </div>
                        } else {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.pair} LP`}
                            </div>
                        }
                    }
                }
                else if (log.actionType === "APPROVE") {
                    if (log.session && log.session.params) {
                        const parsedParams = (typeof log.session.params === 'string') ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params : log.session.params
                        if (log.session.type === "deposit" || log.session.type === "withdraw") {
                            arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + log.product.symbol}</div>
                        } else if (parsedParams.exactIn === true) {
                            arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                        } else if (parsedParams.exactIn === false) {
                            arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                        } else if (log.session.type == "dex_liquidity_2" && (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false)) {
                            let amount = parseFloat(parsedParams.amount).toFixed(12);
                            if (parsedParams.addLiquidity) {
                                arr[1] = <div className="standard-table-cell">
                                    {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                                </div>
                            } else {
                                arr[1] = <div className="standard-table-cell">
                                    {`Amount: ${amount} ${parsedParams.pair} LP`}
                                </div>
                            }
                        } else if (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false) {
                            let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                            let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                            let amount0Token = parsedParams.pair.split("-")[0];
                            let amount1Token = parsedParams.pair.split("-")[1];

                            arr[1] = <div className="standard-table-cell">
                                {`Amount0: ${amount0} ${amount0Token}`}
                                <br></br>
                                {`Amount1: ${amount1} ${amount1Token}`}
                            </div>
                        } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                            arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                        } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                            arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                        }
                    }
                }
                else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                    if (log.session && log.session.params) {
                        arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                    }
                }
                else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                    if (log.session && log.session.params) {
                        arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                    }
                }

                arr[2] = <div className="standard-table-cell">{log.actionType.toLowerCase()}</div>
                if (!log.payment && log.session) {
                    if (log.session.type === "deposit") arr[2] = <div className="standard-table-cell">Deposit</div>
                    else if (log.session.type === "withdraw") arr[2] = <div className="standard-table-cell">Withdraw</div>
                    else if (log.session.type === "dex_swap") arr[2] = <div className="standard-table-cell">Swap</div>
                    else if (log.session.type === "dex_liquidity" || log.session.type === "dex_liquidity_2") {
                        const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                        if (parsedParams.addLiquidity) {
                            arr[2] = <div className="standard-table-cell">Add Liquidity</div>
                        } else {
                            arr[2] = <div className="standard-table-cell">Remove Liquidity</div>
                        }
                    } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                        arr[2] = getTypeHtmlOfAaveSession(log.session.type);
                    } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                        arr[2] = getTypeHtmlOfVenusSession(log.session.type);
                    }
                }

                arr[3] = <div className="standard-table-cell">{log.transactionCategory.toLowerCase()}</div>

                if (log.payment) {
                    arr[4] = <div className={fillStatus[log.payment.status].class}>{fillStatus[log.payment.status].text}</div>;
                } else if (log.session) {
                    arr[4] = <div className={fillStatus[log.session.status].class}>{fillStatus[log.session.status].text}</div>;
                } else {
                    arr[4] = <div className={fillStatus[log.status].class}>{fillStatus[log.status].text}</div>;
                }

                arr[5] = log.id.toString();

                resultArray[i] = arr;
            })

            transactionsArray.map((log: any, i: number) => {
                _exportArray[i] = mappingForExport(log);
            })

            setExportArray(_exportArray)
            exportArrayFunc(_exportArray)
            setContentRows(resultArray)
            setPageNumber(_pageNumber)

            if (_pageNumber * 10 >= totalLength || transactionsArray.length === 0) {
                setDisplayShowMore(false)
            } else {
                setDisplayShowMore(true)
            }
        } catch (err) {
            setDisplayTable(false);
            setDisplayTrnasactionDetail(false);
        } finally {
            setTimeout(() => {
                setDisplaySkeleton(false)
            }, 1500)
        }
    }

    const displayShowMoreFunc = async () => {
        let _pageNumber = pageNumber + 1;
        let transactionsData = await getDeveloperTransactionsFromFilter(filter, _pageNumber, sandboxMode);

        const transactionsArray = transactionsData.data.data;
        const totalLength = transactionsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: any[][] = [];

        transactionsArray.map((log: any, i: number) => {
            const arr = [];
            arr[0] = <div className="standard-table-cell-date">{(new Date(log.createAt)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })).toString()}</div>
            arr[1] = <div className="text-sm font-normal text-gray-500">{parseFloat(log.amount).toFixed(8)}</div>
            if (log.actionType === "DEPOSIT" || log.actionType === "WITHDRAW") {
                if (log.session && log.product && log.product.symbol) {
                    arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.product.symbol}</div>
                } else {
                    arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.currency}</div>
                }
            }
            else if (log.actionType === "SWAP") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    if (parsedParams.exactIn) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                    } else {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                    }

                }
            }
            else if (log.actionType === "DEX_LIQUIDITY") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                    let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                    let amount0Token = parsedParams.pair.split("-")[0];
                    let amount1Token = parsedParams.pair.split("-")[1];

                    arr[1] = <div className="standard-table-cell">
                        {`Amount0: ${amount0} ${amount0Token}`}
                        <br></br>
                        {`Amount1: ${amount1} ${amount1Token}`}
                    </div>
                }
            }
            else if (log.actionType === "DEX_LIQUIDITY_2") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    let amount = parseFloat(parsedParams.amount).toFixed(12);

                    if (parsedParams.addLiquidity) {
                        arr[1] = <div className="standard-table-cell">
                            {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                        </div>
                    } else {
                        arr[1] = <div className="standard-table-cell">
                            {`Amount: ${amount} ${parsedParams.pair} LP`}
                        </div>
                    }
                }
            }
            else if (log.actionType === "APPROVE") {
                if (log.session && log.session.params) {
                    const parsedParams = (typeof log.session.params === 'string') ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params : log.session.params
                    if (log.session.type === "deposit" || log.session.type === "withdraw") {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + log.product.symbol}</div>
                    } else if (parsedParams.exactIn === true) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                    } else if (parsedParams.exactIn === false) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                    } else if (log.session.type == "dex_liquidity_2" && (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false)) {
                        let amount = parseFloat(parsedParams.amount).toFixed(12);
                        if (parsedParams.addLiquidity) {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                            </div>
                        } else {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.pair} LP`}
                            </div>
                        }
                    } else if (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false) {
                        let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                        let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                        let amount0Token = parsedParams.pair.split("-")[0];
                        let amount1Token = parsedParams.pair.split("-")[1];

                        arr[1] = <div className="standard-table-cell">
                            {`Amount0: ${amount0} ${amount0Token}`}
                            <br></br>
                            {`Amount1: ${amount1} ${amount1Token}`}
                        </div>
                    } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                        arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                    } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                        arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                    }
                }
            }
            else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                if (log.session && log.session.params) {
                    arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                }
            }
            else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                if (log.session && log.session.params) {
                    arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                }
            }

            arr[2] = <div className="standard-table-cell">{log.actionType.toLowerCase()}</div>

            if (!log.payment && log.session) {
                if (log.session.type === "deposit") arr[2] = <div className="standard-table-cell">Deposit</div>
                else if (log.session.type === "withdraw") arr[2] = <div className="standard-table-cell">Withdraw</div>
                else if (log.session.type === "dex_swap") arr[2] = <div className="standard-table-cell">Swap</div>
                else if (log.session.type === "dex_liquidity" || log.session.type === "dex_liquidity_2") {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    if (parsedParams.addLiquidity) {
                        arr[2] = <div className="standard-table-cell">Add Liquidity</div>
                    } else {
                        arr[2] = <div className="standard-table-cell">Remove Liquidity</div>
                    }
                } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                    arr[2] = getTypeHtmlOfAaveSession(log.session.type);
                } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                    arr[2] = getTypeHtmlOfVenusSession(log.session.type);
                }
            }

            arr[3] = <div className="standard-table-cell">{log.transactionCategory.toLowerCase()}</div>

            if (log.payment) {
                arr[4] = <div className={fillStatus[log.payment.status].class}>{fillStatus[log.payment.status].text}</div>;
            } else if (log.session) {
                arr[4] = <div className={fillStatus[log.session.status].class}>{fillStatus[log.session.status].text}</div>;
            } else {
                arr[4] = <div className={fillStatus[log.status].class}>{fillStatus[log.status].text}</div>;
            }

            arr[5] = log.id.toString();

            resultArray[i] = arr;
        })

        transactionsArray.map((log: any, i: number) => {
            _exportArray[i] = mappingForExport(log);
        })

        if (_pageNumber * 10 >= totalLength || transactionsArray.length === 0) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        setExportArray([...exportArray, ..._exportArray])
        exportArrayFunc([...exportArray, ..._exportArray])
        setContentRows([...contentRows, ...resultArray])
        setPageNumber(_pageNumber)
    }

    const getAmountHtmlOfAaveSession = (sessionType: string, sessionParams: any, transactionData: string|undefined) => {
        if (sessionType === "aave_supply") {
            return <div className="standard-table-cell">
                {sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "aave_withdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return <div className="standard-table-cell">
                {sessionParams.amount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied'): sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "aave_borrow") {
            return <div className="standard-table-cell">
                {sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "aave_repay") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return <div className="standard-table-cell">
                {sessionParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "aave_supplyAndBorrow") {
            return <div className="standard-table-cell">
                {sessionParams.supplyAmount} {sessionParams.supplySymbol}
                <br></br>
                {sessionParams.borrowAmount} {sessionParams.borrowSymbol}
            </div>
        } else if (sessionType === "aave_repayAndWithdraw") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return <div className="standard-table-cell">
                {sessionParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.repayAmount} {sessionParams.repaySymbol}
                <br></br>
                {sessionParams.withdrawalAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied'): sessionParams.withdrawalAmount} {sessionParams.withdrawalSymbol}
            </div>
        } else if (sessionType === "aave_swapBorrowRateMode") {
            return <div className="standard-table-cell"></div>
        } else if (sessionType === "aave_claimRewards") {
            return <div className="standard-table-cell"></div>
        }
    }

    const getAmountOfAaveSession = (sessionType: string, sessionParams: any, transactionData: string|undefined) => {
        if (sessionType === "aave_supply") {
            return `${sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "aave_withdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return `${sessionParams.amount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "aave_borrow") {
            return `${sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "aave_repay") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return `${sessionParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "aave_supplyAndBorrow") {
            return `${sessionParams.supplyAmount} ${sessionParams.supplySymbol}, ${sessionParams.borrowAmount} ${sessionParams.borrowSymbol}`;
        } else if (sessionType === "aave_repayAndWithdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return `${sessionParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.repayAmount} ${sessionParams.repaySymbol}`
                + `, ${sessionParams.withdrawalAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : sessionParams.withdrawalAmount} ${sessionParams.withdrawalSymbol}`;
        } else if (sessionType === "aave_swapBorrowRateMode") {
            return '';
        } else if (sessionType === "aave_claimRewards") {
            return '';
        }
    }

    const getTypeHtmlOfAaveSession = (sessionType: string) => {
        if (sessionType === "aave_supply") {
            return <div className="standard-table-cell">Supply</div>
        } else if (sessionType === "aave_withdraw") {
            return <div className="standard-table-cell">Withdraw</div>
        } else if (sessionType === "aave_borrow") {
            return <div className="standard-table-cell">Borrow</div>
        } else if (sessionType === "aave_repay") {
            return <div className="standard-table-cell">Repay</div>
        } else if (sessionType === "aave_supplyAndBorrow") {
            return <div className="standard-table-cell">Supply and Borrow</div>
        } else if (sessionType === "aave_repayAndWithdraw") {
            return <div className="standard-table-cell">Repay and Withdraw</div>
        } else if (sessionType === "aave_swapBorrowRateMode") {
            return <div className="standard-table-cell">Swaps borrow rate mode</div>
        } else if (sessionType === "aave_claimRewards") {
            return <div className="standard-table-cell">Claim rewards</div>
        }
    }

    const getTypeOfAaveSession = (sessionType: string) => {
        if (sessionType === "aave_supply") {
            return "Supply";
        } else if (sessionType === "aave_withdraw") {
            return "Withdraw";
        } else if (sessionType === "aave_borrow") {
            return "Borrow";
        } else if (sessionType === "aave_repay") {
            return "Repay";
        } else if (sessionType === "aave_supplyAndBorrow") {
            return "Supply, Borrow";
        } else if (sessionType === "aave_repayAndWithdraw") {
            return "Repay, Withdraw";
        } else if (sessionType === "aave_swapBorrowRateMode") {
            return "Swaps borrow rate mode";
        } else if (sessionType === "aave_claimRewards") {
            return "Claim rewards";
        }
    }

    const getAmountHtmlOfVenusSession = (sessionType: string, sessionParams: any, transactionData: string|undefined) => {
        if (sessionType === "venus_supply") {
            return <div className="standard-table-cell">
                {sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "venus_withdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return <div className="standard-table-cell">
                {sessionParams.vTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied'): sessionParams.vTokenAmount} {sessionParams.vTokenSymbol}
            </div>
        } else if (sessionType === "venus_borrow") {
            return <div className="standard-table-cell">
                {sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "venus_repay") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return <div className="standard-table-cell">
                {sessionParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.amount} {sessionParams.symbol}
            </div>
        } else if (sessionType === "venus_repayAndWithdraw") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return <div className="standard-table-cell">
                {sessionParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.repayAmount} {sessionParams.repaySymbol}
                <br></br>
                {sessionParams.withdrawalVTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied'): sessionParams.withdrawalVTokenAmount} {sessionParams.withdrawalVTokenSymbol}
            </div>
        } else if (sessionType === "venus_claimRewards") {
            return <div className="standard-table-cell"></div>
        }
    }

    const getAmountOfVenusSession = (sessionType: string, sessionParams: any, transactionData: string|undefined) => {
        if (sessionType === "venus_supply") {
            return `${sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "venus_withdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            return `${sessionParams.vTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : sessionParams.vTokenAmount} ${sessionParams.vTokenSymbol}`;
        } else if (sessionType === "venus_borrow") {
            return `${sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "venus_repay") {
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return `${sessionParams.amount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.amount} ${sessionParams.symbol}`;
        } else if (sessionType === "venus_repayAndWithdraw") {
            const estimatedAmountToWithdraw = transactionData ? JSON.parse(transactionData).estimatedAmountToWithdraw : undefined;
            const estimatedAmountToRepay = transactionData ? JSON.parse(transactionData).estimatedAmountToRepay : undefined;
            return `${sessionParams.repayAmount < 0 ? (estimatedAmountToRepay !== undefined ? estimatedAmountToRepay : 'The entire debt of') : sessionParams.repayAmount} ${sessionParams.repaySymbol}`
                + `, ${sessionParams.withdrawalVTokenAmount < 0 ? (estimatedAmountToWithdraw !== undefined ? estimatedAmountToWithdraw : 'All of the supplied') : sessionParams.withdrawalVTokenAmount} ${sessionParams.withdrawalVTokenSymbol}`;
        } else if (sessionType === "venus_claimRewards") {
            return '';
        }
    }

    const getTypeHtmlOfVenusSession = (sessionType: string) => {
        if (sessionType === "venus_supply") {
            return <div className="standard-table-cell">Supply</div>
        } else if (sessionType === "venus_withdraw") {
            return <div className="standard-table-cell">Withdraw</div>
        } else if (sessionType === "venus_borrow") {
            return <div className="standard-table-cell">Borrow</div>
        } else if (sessionType === "venus_repay") {
            return <div className="standard-table-cell">Repay</div>
        } else if (sessionType === "venus_repayAndWithdraw") {
            return <div className="standard-table-cell">Repay and Withdraw</div>
        } else if (sessionType === "venus_claimRewards") {
            return <div className="standard-table-cell">Claim rewards</div>
        }
    }

    const getTypeOfVenusSession = (sessionType: string) => {
        if (sessionType === "venus_supply") {
            return "Supply";
        } else if (sessionType === "venus_withdraw") {
            return "Withdraw";
        } else if (sessionType === "venus_borrow") {
            return "Borrow";
        } else if (sessionType === "venus_repay") {
            return "Repay";
        } else if (sessionType === "venus_repayAndWithdraw") {
            return "Repay, Withdraw";
        } else if (sessionType === "venus_claimRewards") {
            return "Claim rewards";
        }
    }

    const [defaultActive, setDefaultActive] = useState<string>(TRANSACTION_STATUS.ALL);
    const filterFunc = async (filterValue: string) => {
        setDefaultActive(filterValue);
        let _pageNumber = 1
        let transactionsData = await getDeveloperTransactionsFromFilter(filterValue, _pageNumber, sandboxMode);
        let transactionsArray = transactionsData.data.data;
        let totalLength = transactionsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: string[][] = [];

        transactionsArray.map((log: any, i: number) => {
            const arr = [];
            arr[0] = <div className="standard-table-cell-date">{(new Date(log.createAt)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })).toString()}</div>
            arr[1] = <div className="text-sm font-normal text-gray-500">{parseFloat(log.amount).toFixed(8)}</div>
            if (log.actionType === "DEPOSIT" || log.actionType === "WITHDRAW") {
                if (log.session && log.product && log.product.symbol) {
                    arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.product.symbol}</div>
                } else {
                    arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + log.currency}</div>
                }
            }
            else if (log.actionType === "SWAP") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    if (parsedParams.exactIn) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                    } else {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                    }
                }
            }
            else if (log.actionType === "DEX_LIQUIDITY") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                    let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                    let amount0Token = parsedParams.pair.split("-")[0];
                    let amount1Token = parsedParams.pair.split("-")[1];

                    arr[1] = <div className="standard-table-cell">
                        {`Amount0: ${amount0} ${amount0Token}`}
                        <br></br>
                        {`Amount1: ${amount1} ${amount1Token}`}
                    </div>
                }
            }
            else if (log.actionType === "DEX_LIQUIDITY_2") {
                if (log.session && log.session.params) {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    : log.session.params
                    let amount = parseFloat(parsedParams.amount).toFixed(12);

                    if (parsedParams.addLiquidity) {
                        arr[1] = <div className="standard-table-cell">
                            {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                        </div>
                    } else {
                        arr[1] = <div className="standard-table-cell">
                            {`Amount: ${amount} ${parsedParams.pair} LP`}
                        </div>
                    }
                }
            }
            else if (log.actionType === "APPROVE") {
                if (log.session && log.session.params) {
                    const parsedParams = (typeof log.session.params === 'string') ? typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params : log.session.params
                    if (log.session.type === "deposit" || log.session.type === "withdraw") {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + log.product.symbol}</div>
                    } else if (parsedParams.exactIn === true) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenIn + " " + "(Token In)"}</div>
                    } else if (parsedParams.exactIn === false) {
                        arr[1] = <div className="standard-table-cell">{parseFloat(log.session.amount).toFixed(8) + " " + parsedParams.tokenOut + " " + "(Token Out)"}</div>
                    } else if (log.session.type == "dex_liquidity_2" && (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false)) {
                        let amount = parseFloat(parsedParams.amount).toFixed(12);
                        if (parsedParams.addLiquidity) {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.inputTokenSymbol}`}
                            </div>
                        } else {
                            arr[1] = <div className="standard-table-cell">
                                {`Amount: ${amount} ${parsedParams.pair} LP`}
                            </div>
                        }
                    } else if (parsedParams.addLiquidity === true || parsedParams.addLiquidity === false) {
                        let amount0 = parseFloat(parsedParams.amount0).toFixed(8);
                        let amount1 = parseFloat(parsedParams.amount1).toFixed(8);
                        let amount0Token = parsedParams.pair.split("-")[0];
                        let amount1Token = parsedParams.pair.split("-")[1];

                        arr[1] = <div className="standard-table-cell">
                            {`Amount0: ${amount0} ${amount0Token}`}
                            <br></br>
                            {`Amount1: ${amount1} ${amount1Token}`}
                        </div>
                    } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                        arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                    } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                        arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                    }
                }
            }
            else if (log.actionType.startsWith(AAVE_ACTION_TYPE_PREFIX)) {
                if (log.session && log.session.params) {
                    arr[1] = getAmountHtmlOfAaveSession(log.session.type, log.session.params, log.data);
                }
            }
            else if (log.actionType.startsWith(VENUS_ACTION_TYPE_PREFIX)) {
                if (log.session && log.session.params) {
                    arr[1] = getAmountHtmlOfVenusSession(log.session.type, log.session.params, log.data);
                }
            }

            arr[2] = <div className="standard-table-cell">{log.actionType.toLowerCase()}</div>
            if (!log.payment && log.session) {
                if (log.session.type === "deposit") arr[2] = <div className="standard-table-cell">Deposit</div>
                else if (log.session.type === "withdraw") arr[2] = <div className="standard-table-cell">Withdraw</div>
                else if (log.session.type === "dex_swap") arr[2] = <div className="standard-table-cell">Swap</div>
                else if (log.session.type === "dex_liquidity" || log.session.type === "dex_liquidity_2") {
                    const parsedParams = typeof log.session.params == 'string' 
                    ? JSON.parse(log.session.params)
                    : log.session.params
                    if (parsedParams.addLiquidity) {
                        arr[2] = <div className="standard-table-cell">Add Liquidity</div>
                    } else {
                        arr[2] = <div className="standard-table-cell">Remove Liquidity</div>
                    }
                } else if (log.session.type.startsWith(AAVE_SESSION_TYPE_PREFIX)) {
                    arr[2] = getTypeHtmlOfAaveSession(log.session.type);
                } else if (log.session.type.startsWith(VENUS_SESSION_TYPE_PREFIX)) {
                    arr[2] = getTypeHtmlOfVenusSession(log.session.type);
                }
            }

            arr[3] = <div className="standard-table-cell">{log.transactionCategory.toLowerCase()}</div>

            if (log.payment) {
                arr[4] = <div className={fillStatus[log.payment.status].class}>{fillStatus[log.payment.status].text}</div>;
            } else if (log.session) {
                arr[4] = <div className={fillStatus[log.session.status].class}>{fillStatus[log.session.status].text}</div>;
            } else {
                arr[4] = <div className={fillStatus[log.status].class}>{fillStatus[log.status].text}</div>;
            }

            arr[5] = log.id.toString();
            resultArray[i] = arr;
        })

        transactionsArray.map((log: any, i: number) => {
            _exportArray[i] = mappingForExport(log);
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        setExportArray(_exportArray)
        exportArrayFunc(_exportArray)
        setContentRows(resultArray)
        setPageNumber(_pageNumber)
        setFilter(filterValue)
    }

    const exportArrayFunc = async (resultArray: any[]) => {
        const arr: any[] = []
        resultArray.map(r => {
            arr.push({
                "Date/Time": r[0],
                "Amount": r[1],
                "Action Type": r[2],
                "Product": r[3],
                "Status": r[4]
            })
        })
        setExportArrayForCSV(arr)
    }

    useEffect(() => {
        setDisplayTrnasactionDetail(false)
    }, [sandboxMode])

    useEffect(() => {
        if (!displayTrnasactionDetail) {
            getAllDeveloperTransactionsFunc(1)
        }
    }, [sandboxMode])

    // useEffect(() => {
    //     getAllDeveloperTransactionsFunc(1)
    // }, [])

    const matches = useMediaQuery('(max-width:720px)');

    const handleChange = (event: SelectChangeEvent) => {
        switch (event.target.value) {
            case TX_FILTER.ALL:
                filterFunc("");
                break;
            case TX_FILTER.SUCCESS:
                filterFunc(TX_FILTER.SUCCESS);
                break;
            case TX_FILTER.PENDING:
                filterFunc(TX_FILTER.PENDING);
                break;
            case TX_FILTER.FAILED:
                filterFunc(TX_FILTER.FAILED);
                break;
            default:
                break;
        }
    };

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <div className="dev-transactions-root">
        <Box className={`developer-transaction-container ${themeState}-transaction-listing`}>
            <div className="global-header-spacer"></div>
            {!displayTrnasactionDetail && <Box>
                <Box display="flex" justifyContent="space-between" className="upper-panel">
                    <Box>
                        <Box className="transaction">Transactions</Box>
                        <Box className="text-sm font-normal text-gray-500">View your transactions here</Box>
                    </Box>
                    <Box className="responsive-group" display="flex" justifyContent="space-between">
                        <Box>
                            {!matches &&
                                <ButtonGroup variant="outlined" aria-label="outlined primary button group" size="small" style={{ boxShadow: "none" }}>
                                    <Button className={`filterBtn ${defaultActive === TRANSACTION_STATUS.ALL ? `default-active` : null}`} disableRipple onClick={() => filterFunc("")}>{TRANSACTION_STATUS.ALL}</Button>
                                    <Button className="filterBtn" disableRipple onClick={() => filterFunc(TX_FILTER.SUCCESS)}>{TRANSACTION_STATUS.SUCCESS}</Button>
                                    <Button className="filterBtn" disableRipple onClick={() => filterFunc(TX_FILTER.PENDING)}>{TRANSACTION_STATUS.PENDING}</Button>
                                    <Button className="filterBtn" disableRipple onClick={() => filterFunc(TX_FILTER.FAILED)}>{TRANSACTION_STATUS.FAILED}</Button>
                                </ButtonGroup>
                            }
                            {matches &&
                                <div>
                                    <Select
                                        fullWidth
                                        defaultValue={TX_FILTER.ALL}
                                        onChange={handleChange}
                                        className="standard-select-options"
                                    >
                                        <MenuItem value={TX_FILTER.ALL}>{TRANSACTION_STATUS.ALL}</MenuItem>
                                        <MenuItem value={TX_FILTER.SUCCESS}>{TRANSACTION_STATUS.SUCCESS}</MenuItem>
                                        <MenuItem value={TX_FILTER.PENDING}>{TRANSACTION_STATUS.PENDING}</MenuItem>
                                        <MenuItem value={TX_FILTER.FAILED}>{TRANSACTION_STATUS.FAILED}</MenuItem>
                                    </Select>
                                </div>
                            }
                        </Box>
                        <Box className="mobile-flex">
                            <CSVLink data={exportArrayForCSV} filename={"developer_transaction_logs" + ".csv"}>
                                <Button className="exportBtn">
                                    <ExportIcon />
                                    Export
                                </Button>
                            </CSVLink>
                        </Box>
                    </Box>
                </Box>
                <Divider className="divider" />
            </Box>}

            {/* {displaySkeleton ? <Box> */}
            {/* <Lottie animationData={Loading} loop={true}/> */}
            {/* </Box> : */}
            <Box>
                {!displayTrnasactionDetail && <div className="transactions-table">
                    {displaySkeleton && displayTable && <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>{Loading()}</div>}
                    {displayTable && <CommonTable
                        headings={headings}
                        rows={contentRows}
                        displaySearchIcon={true}
                        functionToCall={setDisplayTrnasactionDetail}
                        hasRowId={true}
                        setID={setID}
                        displayShowMore={displayShowMore}
                        setDisplayShowMore={displayShowMoreFunc}
                        navigationURL="/transaction-listing"
                    />}

                </div>}
                {displayTrnasactionDetail && <Box pb={6}>
                    <TransactionDetails functionToCall={setDisplayTrnasactionDetail} ID={ID} />
                </Box>}
                {!displayTable && <EmptyCard title="No Transactions" subtitle="You haven't made any transactions yet" />}
            </Box>
            {/* } */}
            <div className="global-bottom-spacer"></div>
        </Box>
    </div>
}

export default TransactionLisiting;