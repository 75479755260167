import Lottie, { useLottie } from 'lottie-react';
import LoadingJSON from './Loading.json';
import './loading.scss';
export const Loading = (type?: string) => {

    return (
        <div className='inject'><Lottie animationData={LoadingJSON} loop={true}/></div>
    )
}

