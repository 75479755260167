import axios, { getJWT } from "./axios";

export const uploadBusinessDetails = async (payload: any) => {
    const header = await getJWT();
    const res = await axios.patch(`/api/v1/user/business`, payload, header);
    return res;
}

export const getBusinessDetails = async () => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/user/get-profile?isBusiness=true`, header);
    return res;
}