import TechStarsIcon from "./techstars.svg";
import TechStarsIconDark from "./techstars_dark.svg";
import AlphabitIcon from "./alphabit.svg";
import AlphabitIconDark from "./alphabit_dark.svg";
import OutlierVentures from "./outlier_ventures.svg";
import OutlierVenturesDark from "./outlier_ventures_dark.svg";

import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as Github } from "./github.svg";
import { ReactComponent as Discord } from "./discord.svg";
import { ReactComponent as Medium } from "./medium.svg";
import { ReactComponent as Youtube } from "./youtube.svg";

import "./index.scss";

export const Investor: any = {
    DEFAULT: {
        TECHSTARS: <img src={TechStarsIcon}/>,
        ALPHABIT: <img src={AlphabitIcon}/>,
        OUTLIERVENTURES: <img src={OutlierVentures}/>
    },
    DARK: {
        TECHSTARS: <img src={TechStarsIconDark}/>,
        ALPHABIT: <img src={AlphabitIconDark}/>,
        OUTLIERVENTURES: <img src={OutlierVenturesDark}/>
    }
}

export const Social: any = {
    DEFAULT: {
        TWITTER: <Twitter/>,
        LINKEDIN: <Linkedin/>,
        GITHUB: <Github/>,
        DISCORD: <Discord/>,
        MEDIUM: <Medium/>,
        YOUTUBE: <Youtube/>
    },
    DARK: {
        TWITTER: <Twitter/>,
        LINKEDIN: <Linkedin/>,
        GITHUB: <Github/>,
        DISCORD: <Discord className="DARK-twitter-svg"/>,
        MEDIUM: <Medium className="DARK-medium-svg"/>,
        YOUTUBE: <Youtube className="DARK-youtube-svg"/>
    }
}