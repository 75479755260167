import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EMAIL_PATTERN } from '../../constants/validation';
import { userForgetPassword } from '../../services/axios/auth';
import { error, success } from '../../store/slices/messages-slice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SecuroIcon from '../../assets/images/common/securo-finance.svg';
import ResetKey from './reset-password.svg';
import Mailbox from '../../assets/images/icons/mailbox-icon.svg';
import BackIcon from '../../assets/images/icons/back-arrow.svg';
import FormErrorIcon from '../../assets/images/icons/form-error-icon.svg';
import "./reset-password.scss";
import { updateMsgState } from '../../store/slices/messages-v2-slice';
import { useAppSelector } from '../../store/hooks';

function RecoveryLink() {
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const matches = useMediaQuery('(max-width:600px)');
  const matches1 = useMediaQuery('(max-width:997px)');

  const dispatch = useDispatch();

  const [successReset, setIsSuccess] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async (data: any) => {
    const registeredEmail = data.email;

    const result: any = await userForgetPassword(registeredEmail);

    if (result.data.errorMessage) {
      // Dispatch error message
      dispatch(
        setNotFound(true)
      );
    }

    // Success
    if (result.status === 201) {
      setEmail(data.email)
      setIsSuccess(true);
      reset();
      // Dispatch success message
      dispatch(updateMsgState({ state: "success", title: "Success", reason: "The reset instruction is sent to your email. Please check your inbox.", attempt: true, isMain: true }))
    }

    // Redirect user to main page
    // navigate("/main")
  };

  const resend = async () => {

    const registeredEmail = email;

    const result: any = await userForgetPassword(registeredEmail);

    if (result.data.errorMessage) {
      // Dispatch error message
      dispatch(
        setNotFound(true)
      );
    }

    // Success
    if (result.status === 201) {
      setIsSuccess(true);
      // Dispatch success message
      dispatch(updateMsgState({ state: "success", title: "Success", reason: "The reset instruction is sent to your email. Please check your inbox.", attempt: true, isMain: true }))
    }

  }

  const stateBinder = (mode: boolean) => {
    const Obj = {
      DEFAULT: {
        icon: ResetKey,
        header: "Forgot Password?",
        info_text: "No worries, we’ll send you reset instructions.",
        form: true,
      }, 
      SUCCESS: {
        icon: Mailbox,
        header: "Check your email",
        info_text: <>We sent a password reset link to <div className='text-base font-medium text-gray-500'>{email}</div></>,
        form: false,
      }, 
    }
    
    if(mode) {
      return Obj.SUCCESS;
    } else {
      return Obj.DEFAULT;
    }
  }
  
  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <>
      <div className={`reset-password-wrapper ${themeState}-forgot-password`}>
        <div className="reset-logo-wrapper">
          <div className='circular-bordered'><img src={stateBinder(successReset).icon} /></div>
        </div>
        <div className='reset-form-container'>   
          <form onSubmit={handleSubmit(onSubmit)}>
              
              <div className='reset-info'>
                <div className="text-3xl font-semibold text-gray-900">
                  <div className='mounted'>{stateBinder(successReset).header}</div>
                </div>

                <div className="text-base font-normal text-gray-500 text-center">
                  {stateBinder(successReset).info_text}
                </div>
              </div>

              {stateBinder(successReset).form &&
                <>
                  <div className="reset-wrapped-input form-mode">
                    <div className="text-input-title text-base font-medium text-gray-700">Email</div>
                      <TextField
                        placeholder="Enter your email"
                        variant="outlined"
                        fullWidth
                        onKeyDown={() => setNotFound(false)}
                        {...register('email', {
                          required: 'Email Required',
                          pattern: {
                            value: EMAIL_PATTERN,
                            message: 'Your email format was incorrect. Please double check and try again.',
                          },
                        })}
                        InputProps={{
                          endAdornment:
                          <div className='hide-adorment'>
                          {(!!errors?.email || notFound === true) &&
                            <div><img src={FormErrorIcon}/></div>
                          }
                          </div>
                        }}
                        error={!!errors?.email || notFound}
                        helperText={
                        <>
                        {errors?.email &&
                          <div className='text-sm font-normal text-error-500'>{errors?.email?.message}</div>
                        }
                        {notFound === true &&
                          <div className='text-sm font-normal text-error-500'>User not found</div>
                        }
                        </>
                        }
                      />
                  </div>

                  <div className="reset-wrapped-input">
                    <div className="btn-padding">
                        <Button
                          className={`btn on-invalid-state`}
                          variant="contained"
                          type="submit"
                          fullWidth
                        >
                          <div className='text-base font-medium text-white'>Reset password</div>
                        </Button>
                      </div>
                  </div>
                </>
              }

              {!stateBinder(successReset).form &&
                  <div className="reset-wrapped-input">
                    {/* <div className="btn-padding">
                        <Button
                          onClick={() => window.open(`https://${email.split('@').at(1)}`)}
                          className={`btn on-invalid-state`}
                          variant="contained"
                          type="submit"
                          fullWidth
                        >
                          <div className='text-base font-medium text-white'>Open email app</div>
                        </Button>
                    </div> */}
                    <div className='help-resend text-base font-normal text-gray-500 text-center'>
                      Didn't receive the mail? <span onClick={() => resend()} className='resend-text text-base font-normal text-primary'>Click to resend</span>
                    </div>
                  </div>
              }

              <div className="reset-wrapped-input">
                    <div className='back-btn text-sm font-medium text-gray-500'
                      onClick={() => navigate('/main')}
                    >
                      <img src={BackIcon}/>
                      Back to login
                    </div>
              </div>
              

              {/* <Grid item xs={12}>
                          <div className="footer-container-wrapper flex-row align-items-center flex-content-center">
                              
                          </div>
                      </Grid>

                      {successReset&&<Grid item xs={12}>
                        <span id="status-message">Recovery link Sent ! Please check your mailbox.</span>
                      </Grid>}

                      <Grid item xs={12}>
                          <div className="footer-container-wrapper">
                              <Button href="#" id="forget-password" onClick={() => navigate("/main")} startIcon={<ArrowBackIcon/>}>Back</Button>
                          </div> 
                      </Grid> */}
            
          </form>
        </div>
        <div className="about-wrapped text-base font-normal text-gray-400">© ThirdFi 2023. All Rights Reserved.</div>
      </div>
    </>
  );
}

export default RecoveryLink;
