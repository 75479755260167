import { Box, Divider } from '@mui/material';
import "./index.scss";
import * as React from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  titleRightContent?: React.ReactNode;
  content?: React.ReactNode;
  titleStyle?: any;
}

function MainCard(props: Props) {
  return (
    <Box>
      <div className="main-card">
        <h2
          className="card-title"
          style={props.titleStyle}
        >
          {props.title}
        </h2>

        <div className='create-new-action'>{props.titleRightContent}</div>
      </div>

      <div
        className="card-subtitle"
        style={{
          color: '#667085',
          fontWeight: '400',
          fontSize: '14px',
        }}
      >
        {props.subtitle}
      </div>
      <div>{props.content}</div>
    </Box>
  );
}

export default MainCard;
