import React, { useEffect, useState } from "react";
import "./view-base.scss";
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { DRAWER_WIDTH, TRANSITION_DURATION } from "../../constants/style";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Titles } from "../Header";
import { useAuth } from "../../services/auth/authProvider";
import { ReactComponent as Twitter } from "../../assets/images/icons/social/twitter.svg";
import { ReactComponent as Linkedin } from "../../assets/images/icons/social/linkedin.svg";
import { ReactComponent as Github } from "../../assets/images/icons/social/github.svg";
import { ReactComponent as Discord } from "../../assets/images/icons/social/discord.svg";
import { ReactComponent as Medium } from "../../assets/images/icons/social/medium.svg";
import { ReactComponent as Youtube } from "../../assets/images/icons/social/youtube.svg";
import { ReactComponent as Techstars } from "../../assets/images/icons/social/techstars.svg";
import { ReactComponent as Alphabit } from "../../assets/images/icons/social/alphabit.svg";
import { ReactComponent as OutlierVentures } from "../../assets/images/icons/social/outlier_ventures.svg";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Verification from "../../pages/Verification";
import { useAppSelector } from "../../store/hooks";
import { TransitionProps } from "@mui/material/transitions";
import { useDispatch } from "react-redux";
import { updateDrawerState } from "../../store/slices/drawer-slice";
import MenuSelection from "../../pages/MenuSelection";
import { pathHelper } from "../../utils/path-helper";
import ResetPassword from "../ResetPassword";
import ResetPasswordV2 from "../ResetPasswordV2";
import { Investor, Social } from "../../assets/images/icons/social";
import ServerStatus from "../ServerState";

interface IViewBaseProps {
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
    content: {
        width: "100vw",
        // padding: theme.spacing(1),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: TRANSITION_DURATION,
        }),
        height: "100%",
        overflow: "auto",
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: TRANSITION_DURATION,
        }),
        marginLeft: 0,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/** Handling for smaller screen size */
function ViewBase({ children }: IViewBaseProps) {
    const location = useLocation();
    const inboundSpecial = "/profile-completion";
    const classes = useStyles();
    const matches = useMediaQuery("(max-width: 720px)")
    const { user } = useAuth()

    const [inboundCompletion, setInboundCompletion] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (location.pathname === inboundSpecial) {
            setInboundCompletion(true);
        } else {
            setInboundCompletion(false);
        }
    }, [inboundCompletion])
    // useEffect(() => {
    //     console.log(`view base user`, user, !!user);
    // }, [user])

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        dispatch(updateDrawerState(false))
    };
    const { drawerData } = useAppSelector(state => state.drawer);
    const themeState = useAppSelector((state) => state.theme.themeState);
    const { pathname } = location;

    return (
        <>
            <Dialog
                className={`Selection-MuiPaper-root`}
                fullScreen
                open={drawerData}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <MenuSelection />
            </Dialog>
            <div className={`view-base-root ${!!user ? '' : 'not-authorized'}`} id="scroll-area">
            {!inboundCompletion &&
                <>
                    {!!user && !matches && !pathHelper(pathname) &&
                        <>
                            <Sidebar />
                        </>
                    }

                    <div className={`${classes.content} ${matches && classes.contentShift} fixed-content ${!user ? `default-bg ${themeState}-bg` : `${themeState}-on-authenticated-bg`}`} id="overall-content">
                        {!pathHelper(pathname) &&
                            <Header />
                        }
                        {children}
                        {user && matches && !pathHelper(pathname) &&
                            <div className={`mobile-footer ${themeState}-footer`}>
                                <div className="wrapped-links">
                                    <div className="focused-text-mobile">Server State</div>
                                    <ServerStatus/>
                                    <div className="follow-us">
                                        <div className="focused-text-mobile">Follow us</div>
                                        <div className="img-flex">
                                            <a href="https://twitter.com/thirdfiorg" target="_blank" rel="noreferrer">{Social[themeState].TWITTER}</a>
                                            <a href="https://www.linkedin.com/company/thirdfiorg/" target="_blank" rel="noreferrer">{Social[themeState].LINKEDIN}</a>
                                            <a href="https://github.com/thirdfi" target="_blank" rel="noreferrer">{Social[themeState].GITHUB}</a>
                                            <a href="https://discord.com/invite/tcZ42jHZ5N" target="_blank" rel="noreferrer">{Social[themeState].DISCORD}</a>
                                            {/* <a href="https://thirdfi.medium.com/" target="_blank" rel="noreferrer">{Social[themeState].MEDIUM}</a> */}
                                            <a href="https://www.youtube.com/channel/UCTJoXywam24P7nWlqCdPGZQ" target="_blank" rel="noreferrer">{Social[themeState].YOUTUBE}</a>
                                        </div>
                                    </div>
                                    {/* <div className="follow-us">
                                        <div className="focused-text-mobile">Accelerated By</div>
                                        <div className="img-flex">
                                            <div>{Investor[themeState].TECHSTARS}</div>
                                            <div>{Investor[themeState].ALPHABIT}</div>
                                            <div>{Investor[themeState].OUTLIERVENTURES}</div>
                                        </div>
                                    </div> */}
                                    <div className="follow-us">
                                        <div className="focused-text-mobile">Documentation</div>
                                        <div className="img-flex docs">
                                            <a href="https://docs.thirdfi.org/" target="_blank" rel="noreferrer">Gitbook</a>
                                            <a href="https://thirdfi.readme.io/reference/introduction" target="_blank" rel="noreferrer">Readme</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {user && !matches && !pathHelper(pathname) &&
                        <div className="foot-absolute">
                            <div className={`app-footer ${themeState}-footer`}>
                                <div className="focused-text">Server State</div>
                                <ServerStatus/>
                                <div className="focused-text">Follow Us</div>
                                <a href="https://twitter.com/thirdfiorg" target="_blank" rel="noreferrer">{Social[themeState].TWITTER}</a>
                                <a href="https://www.linkedin.com/company/thirdfiorg/" target="_blank" rel="noreferrer">{Social[themeState].LINKEDIN}</a>
                                <a href="https://github.com/thirdfi" target="_blank" rel="noreferrer">{Social[themeState].GITHUB}</a>
                                <a href="https://discord.com/invite/tcZ42jHZ5N" target="_blank" rel="noreferrer">{Social[themeState].DISCORD}</a>
                                {/* <a href="https://securo.medium.com/" target="_blank" rel="noreferrer">{Social[themeState].MEDIUM}</a> */}
                                <a href="https://www.youtube.com/channel/UCTJoXywam24P7nWlqCdPGZQ" target="_blank" rel="noreferrer">{Social[themeState].YOUTUBE}</a>
                                {/* <div className="focused-text-2 accelerated-by">
                                    Accelerated By
                                    <div className="accelerated-by-images">
                                        <div>{Investor[themeState].TECHSTARS}</div>
                                        <div>{Investor[themeState].ALPHABIT}</div>
                                        <div>{Investor[themeState].OUTLIERVENTURES}</div>
                                    </div>
                                </div> */}
                                <div className="font-sm font-medium text-gray-700 documentation">
                                    <div className="focused-text-3">Documentation:</div>
                                    <div className="documentation-links">
                                        <a href="https://docs.thirdfi.org/" target="_blank" rel="noreferrer">Gitbook</a>
                                        <span>|</span>
                                        <a href="https://thirdfi.readme.io/reference/introduction" target="_blank" rel="noreferrer">Readme</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            </div>
        </>
    );
}

export default ViewBase;
