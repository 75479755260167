import axios, { getJWT } from './axios';

function formatURL(id?: number) {
  let url = `/api/v1/webhooks/`;
  if (id) url += id;

  return url;
}

export const getWebhooks = async () => {
  const header = await getJWT();
  const res = await axios.get(formatURL(), header);
  return res.data;
};

export const createWebhook = async (body: any) => {
  try {
    const header = await getJWT();
    const res = await axios.post(formatURL(), body, header);
    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e.response;
    }
  }
};

export const deleteWebhook = async (id: number) => {
  const header = await getJWT();
  const res = await axios.delete(formatURL(id), header);
  return res.data;
};

export const getWebhook = async (id: number) => {
  const header = await getJWT();
  const res = await axios.get(formatURL(id), header);
  return res.data;
};

export const updateWebhook = async (body: any) => {
  try {
    const header = await getJWT();
    const res = await axios.patch(formatURL(body.id), body, header);
    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e.response;
    }
  }
};
