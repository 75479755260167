import { Grid, Typography, useMediaQuery } from '@mui/material';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {
  const matches1 = useMediaQuery('(max-width:997px)');

  return (
    <Grid container>
        <div className="about-tabs">
            <div className="highlights text-lg font-medium text-gray-900">Change Password</div>
            <div className="text-sm font-normal text-gray-500">Update your password here.</div>
        </div>
        <ResetPasswordForm requireCurrentPassword={true} />
    </Grid>
  );
}

export default ResetPassword;
