import { useState } from "react";



export default function HoverableText({text}:{text: string}) {
    const [longTextHover, setLongTextHover] = useState({mode: false});
    const linearLongText = (mode: boolean) => {
        const canvas = document.createElement('canvas');
        let context: any = canvas.getContext('2d');
        context.font = getComputedStyle(document.body).font;
  
        const textWidth = context.measureText(text).width;
  
        let stylesheet = document.styleSheets[0];
  
        const keyframes = `@keyframes animation-name {
          0% {
            transform: translateX(0px)
          }
          100% {
            transform: translateX(-${textWidth - 138.82}px)
          }
        }`
  
        stylesheet.insertRule(keyframes, stylesheet.cssRules.length);
  
        if(mode) {
            setLongTextHover({mode: mode})
        } else {
            setLongTextHover({mode: mode})
        }
    }

    return (
        <div onMouseEnter={() => linearLongText(true)} onMouseLeave={() => linearLongText(false)} className="collection">
            <div 
                className="long-text-normal"
                id="long-text-name"
                style={{
                    animationTimingFunction: longTextHover.mode ? "linear" : "unset", 
                    animation: longTextHover.mode ? "animation-name 8s infinite" : "unset",
                }}
            >
                {text}
            </div>
        </div>
    )
}