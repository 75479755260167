import { Box, Divider, TextField, useMediaQuery, Card, Grid, Button, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import AreaChart from "../../components/AreaChart";
import "./developerDashboard.scss";
import CalendarIcon from "../../components/CalendarIcon";
import LgInfoCircle from "../../assets/images/icons/large-info-circle.svg";

import { makeStyles } from "@mui/styles";
import { getAPIErrorsTimeSeries, getAPILogsTimeSeries } from "../../services/axios/apiLogging";
import { getWebhookLogsTimeSeries } from "../../services/axios/webhookLogs";
import TestData from "../../components/TestData";
import { useAppSelector } from "../../store/hooks";
import ChartLoader from "../../components/Loader";

const useStyles = makeStyles(() => ({

    root: {
        flexDirection: "row-reverse",
        borderRadius: "8px",
    }

}))

interface ChartState {
    request: {
        isLoading: boolean
    };
    api_errors: {
        isLoading: boolean
    };
    webhooks: {
        isLoading: boolean
    };
}

function DeveloperDashboard() {

    const classes = useStyles();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate1, setStartDate1] = useState<Date | null>(null);
    const [endDate1, setEndDate1] = useState<Date | null>(null);
    const [APIRequestsYAxis, setAPIRequestsYAxis] = useState([]);
    const [APIRequestsXAxis, setAPIRequestsXAxis] = useState([]);
    const [APIErrorsYAxis, setAPIErrorsYAxis] = useState([]);
    const [APIErrorsXAxis, setAPIErrorsXAxis] = useState([]);
    const [webhookLogsYAxis, setWebhookLogsYAxis] = useState([]);
    const [webhookLogsXAxis, setWebhookLogsXAxis] = useState([]);
    const [noDataTextAPIRequests, setNoDataTextAPIRequests] = useState('');
    const [noDataTextAPIErrors, setNoDataAPIErrors] = useState('');
    const [noDataTextWebhooks, setNoDataWebhooks] = useState('');
    const [timePeriodAPIRequests, setTimePeriodAPIRequests] = useState(0);
    const [timePeriodAPIErrors, setTimePeriodAPIErrors] = useState(0);
    const [timePeriodWebhooks, setTimePeriodWebhooks] = useState(0);

    let loadState: ChartState = {
        request: {
            isLoading: true
        },
        api_errors: {
            isLoading: true
        },
        webhooks: {
            isLoading: true
        }
    }

    const [isLoading, setIsLoading] = useState<ChartState>(loadState)

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const getAPILogsTimeSeriesFunc = async (start: string, end: string) => {
        setNoDataTextAPIRequests("Loading...")
        const res = await getAPILogsTimeSeries(start, end, sandboxMode);

        if (!res.data.data) {
            setNoDataTextAPIRequests("No data available")
            setAPIRequestsXAxis([])
            setAPIRequestsYAxis([])
            setTimePeriodAPIRequests(0)
            return;
        }

        setIsLoading(prev =>
        (
            { ...prev, request: { isLoading: false } }
        )
        )

        setAPIRequestsXAxis(res.data.data.x)
        setAPIRequestsYAxis(res.data.data.y)

        const yArray = res.data.data.y
        const val = yArray.reduce((total: number, y: number) => total + y)
        setTimePeriodAPIRequests(val)
    }

    const getAPIErrorsTimeSeriesFunc = async (start: string, end: string) => {
        setNoDataAPIErrors("Loading...")
        const res = await getAPIErrorsTimeSeries(start, end, sandboxMode);
        if (!res.data.data) {
            setNoDataAPIErrors("No data available")
            setAPIErrorsXAxis([])
            setAPIErrorsYAxis([])
            setTimePeriodAPIErrors(0)
            return;
        }

        setIsLoading(prev =>
        (
            { ...prev, api_errors: { isLoading: false } }
        )
        )

        setAPIErrorsXAxis(res.data.data.x)
        setAPIErrorsYAxis(res.data.data.y)

        const yArray = res.data.data.y
        const val = yArray.reduce((total: number, y: number) => total + y)
        setTimePeriodAPIErrors(val)
    }

    const getWebhookLogsTimeSeriesFunc = async (start: string, end: string) => {
        setNoDataWebhooks("Loading...")
        const res = await getWebhookLogsTimeSeries(start, end, sandboxMode);
        if (!res.data.data) {
            setNoDataWebhooks("No data available")
            setWebhookLogsXAxis([])
            setWebhookLogsYAxis([])
            setTimePeriodWebhooks(0)
            return;
        }

        setIsLoading(prev =>
        (
            { ...prev, webhooks: { isLoading: false } }
        )
        )
        setWebhookLogsXAxis(res.data.data.x)
        setWebhookLogsYAxis(res.data.data.y)

        const yArray = res.data.data.y
        const val = yArray.reduce((total: number, y: number) => total + y)
        setTimePeriodWebhooks(val)
    }

    const handleSearch = () => {
        if (startDate && endDate) {
            getAPILogsTimeSeriesFunc(startDate.toString(), endDate.toString())
        } else {
            const start = (new Date(Date.now() - 864e5 * 6)).toString()
            const end = (new Date(Date.now())).toString()
            setStartDate(new Date(start))
            setEndDate(new Date(end))
            getAPILogsTimeSeriesFunc(start, end)
        }
    }

    const handleSearch1 = () => {
        if (startDate1 && endDate1) {
            getAPIErrorsTimeSeriesFunc(startDate1.toString(), endDate1.toString())
            getWebhookLogsTimeSeriesFunc(startDate1.toString(), endDate1.toString())
        } else {
            const start = (new Date(Date.now() - 864e5 * 6)).toString()
            const end = (new Date(Date.now())).toString()
            setStartDate1(new Date(start))
            setEndDate1(new Date(end))
            getAPIErrorsTimeSeriesFunc(start, end)
            getWebhookLogsTimeSeriesFunc(start, end)
        }
    }

    useEffect(() => {
        const start = (new Date(Date.now() - 864e5 * 6)).toString()
        const end = (new Date(Date.now())).toString()
        setStartDate(new Date(start))
        setEndDate(new Date(end))
        setStartDate1(new Date(start))
        setEndDate1(new Date(end))
        getAPILogsTimeSeriesFunc(start, end)
        getAPIErrorsTimeSeriesFunc(start, end)
        getWebhookLogsTimeSeriesFunc(start, end)
    }, [sandboxMode])

    const matches = useMediaQuery('(max-width:720px)');
    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box style={{ width: "100%" }}>
        <Box className={`dev-reporting-root ${themeState}-reporting`}>
            <div className="global-header-spacer"></div>
            {/* <TestData /> */}
            <Box className="developer-reporting-container">
                <Box display="flex" justifyContent="space-between" className="upper-panel">
                    <Box>
                        <Box className="title">API requests</Box>
                        <Box className="subtitle">View your API requests here</Box>
                    </Box>
                    <Box className="date-panel" display="flex" justifyContent="space-between" mb={1}>
                        <Box className="date-range-group">
                            <div className="date-range">
                                <Box className="date-picker-input">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={startDate}
                                            components={{
                                                OpenPickerIcon: CalendarIcon
                                            }}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.root
                                                }
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Start Date"
                                                }}
                                            />}
                                            maxDate={endDate !== null ? endDate : null}
                                        />
                                        <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{endDate !== null && startDate === null ? "Please enter start date" : ""}</Box>
                                    </LocalizationProvider>
                                </Box> <span className={`${themeState}-apply`}>_</span>
                                <Box className="date-picker-input">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            components={{
                                                OpenPickerIcon: CalendarIcon
                                            }}
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.root
                                                }
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "End Date"
                                                }}
                                            />}
                                            minDate={startDate !== null ? startDate : null}
                                        />
                                        <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{startDate !== null && endDate === null ? "Please enter end date" : ""}</Box>
                                    </LocalizationProvider>
                                </Box>
                            </div>
                            <Box>
                                <Button className="standard-search-btn" onClick={handleSearch} disabled={startDate === null || endDate === null}>
                                    Search
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box className="api-request-panel">
                    <Card className="card-container" sx={{ border: "1px solid #EAECF0" }}>
                        <div className="text-base font-medium text-gray-900 inner-title" style={{ display: "flex", gap: "10px" }}>
                            API Requests
                            {/* <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip> */}
                            <span className="inner-value" style={{ fontSize: "14px", backgroundColor: "#ECFDF3", padding: "2px 10px 2px 8px", borderRadius: "16px" }}>
                                {timePeriodAPIRequests} requests
                            </span>
                        </div>
                        <div className="chart-holster">
                            {isLoading.request.isLoading &&
                                <ChartLoader />
                            }
                            <AreaChart
                                series={[
                                    {
                                        name: 'API requests',
                                        data: APIRequestsYAxis,
                                    },
                                ]}
                                colors={['#9ABFF5']}
                                categories={APIRequestsXAxis}
                                height={matches ? '150%' : '200%'}
                                noDecimals={true}
                            // noDataText={noDataTextAPIRequests}
                            />
                        </div>
                    </Card>
                </Box>
            </Box>
            <Box className="developer-reporting-container" pb={6}>
                <Box className="report-overview-panel">
                    <Box display="flex" justifyContent="space-between" className="upper-panel">
                        <Box>
                            <Box className="title">API errors and Webhooks</Box>
                            <Box className="subtitle">View your API errors and Webhooks here</Box>
                        </Box>
                        <Box className="date-panel" display="flex" justifyContent="space-between" mb={1}>
                            <Box className="date-range-group">
                                <div className="date-range">
                                    <Box className="date-picker-input">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={startDate1}
                                                components={{
                                                    OpenPickerIcon: CalendarIcon
                                                }}
                                                onChange={(newValue) => {
                                                    setStartDate1(newValue);
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.root
                                                    }
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    fullWidth
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Start Date"
                                                    }}
                                                />}
                                                maxDate={endDate1 !== null ? endDate1 : null}
                                            />
                                            <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{endDate1 !== null && startDate1 === null ? "Please enter start date" : ""}</Box>
                                        </LocalizationProvider>
                                    </Box> <span className={`${themeState}-apply`}>_</span>
                                    <Box className="date-picker-input">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                components={{
                                                    OpenPickerIcon: CalendarIcon
                                                }}
                                                value={endDate1}
                                                onChange={(newValue) => {
                                                    setEndDate1(newValue);
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.root
                                                    }
                                                }}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    fullWidth
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "End Date"
                                                    }}
                                                />}
                                                minDate={startDate1 !== null ? startDate1 : null}
                                            />
                                            <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{startDate1 !== null && endDate1 === null ? "Please enter end date" : ""}</Box>
                                        </LocalizationProvider>
                                    </Box>
                                </div>
                                <Box>
                                    <Button className="standard-search-btn" onClick={handleSearch1} disabled={startDate1 === null || endDate1 === null}>
                                        Search
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box className="multigrid-container">
                        <Grid className="wrapped-grid" container spacing={3}>
                            <Grid item xs={6}>
                                <Card className="card-container" sx={{ border: "1px solid #EAECF0" }}>
                                    <div className="text-base font-medium text-gray-900 inner-title" style={{ display: "flex", gap: "10px" }}>
                                        API errors
                                        {/* <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip> */}
                                        <span className="inner-value" style={{ fontSize: "14px", backgroundColor: "#ECFDF3", padding: "2px 10px 2px 8px", borderRadius: "16px" }}>
                                            {timePeriodAPIErrors} errors
                                        </span>
                                    </div>
                                    <div className="chart-holster">
                                        {isLoading.api_errors.isLoading &&
                                            <ChartLoader />
                                        }
                                        <AreaChart
                                            series={[
                                                {
                                                    name: 'API errors',
                                                    data: APIErrorsYAxis,
                                                },
                                            ]}
                                            colors={['#9ABFF5']}
                                            categories={APIErrorsXAxis}
                                            height={matches ? '150%' : '150%'}
                                            noDecimals={true}
                                        // noDataText={noDataTextAPIErrors}
                                        />
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="card-container" sx={{ border: "1px solid #EAECF0" }}>
                                    <div className="text-base font-medium text-gray-900 inner-title" style={{ display: "flex", gap: "10px" }}>
                                        Webhooks
                                        {/* <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip> */}
                                        <span className="inner-value" style={{ fontSize: "14px", backgroundColor: "#ECFDF3", padding: "2px 10px 2px 8px", borderRadius: "16px" }}>
                                            {timePeriodWebhooks} events
                                        </span>
                                    </div>
                                    <div className="chart-holster">
                                        {isLoading.webhooks.isLoading &&
                                            <ChartLoader />
                                        }
                                        <AreaChart
                                            series={[
                                                {
                                                    name: 'Webhook Logs',
                                                    data: webhookLogsYAxis,
                                                },
                                            ]}
                                            colors={['#9ABFF5']}
                                            categories={webhookLogsXAxis}
                                            height={matches ? '150%' : '150%'}
                                            noDecimals={true}
                                        // noDataText={noDataTextWebhooks}
                                        />
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            <div className="global-bottom-spacer"></div>
        </Box >
    </Box>
}

export default DeveloperDashboard;