import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../services/auth/authProvider";
import { getStrategies } from "../../services/axios/strategyPerformance";
import { updateStrategyPerformanceList } from "../../store/slices/strategy-performance-slice";

function useStrategyPerformance() {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const location = useLocation();

    const findStrategies = useCallback(async () => {
        const response = await getStrategies();
        const strategies = response.data.data;

        const symbol: any = {
            "Market Weighted Index": "MWI",
            "Blockchain Network Index": "BNI",
            "Low-risk Crypto Index": "LCI"
        }

        const mappedStrategies: any[] = [];
        strategies.map((item: any) => {
            mappedStrategies.push({
                ...item,
                symbol: symbol[item.productName]
            })
        })

        dispatch(updateStrategyPerformanceList(mappedStrategies))
    }, [])

    // useEffect(() => {
    //     if (user)
    //         setInterval(() => {
    //             findStrategies();
    //         }, 5000)

    // }, [user])

    useEffect(() => {
        if (user && location.pathname === "/portfolio-performance") {
            findStrategies();
        }
    }, [user, location])

    // useEffect(() => {
    //     findStrategies();
    // }, [])
}

export default useStrategyPerformance;