import { createSelector, createSlice } from "@reduxjs/toolkit";
import { delay } from "lodash";
import { useEffect } from "react";
import { THEME } from "../../constants/general";
import { RootState } from "../store";

export interface IAppState {
    themeState: string;
    switched: boolean;
}

const initialState = {
    themeState: 'DARK',
    switched: false
} as IAppState;

const themingSlice = createSlice({
    name: "mode",
    initialState,
    reducers: {
        initThemeState(state) {
            localStorage.setItem('MODE', 'DARK');
            
            document.body.classList.add('DARK-Mui-Config')
            if(state.themeState === 'DEFAULT') {
                document.body.removeAttribute('class') 
            }
        },
        updateThemeState(state, { payload }) {
            localStorage.setItem('MODE', payload);
            payload !== THEME.DARK ? document.body.removeAttribute('class') : document.body.classList.add('DARK-Mui-Config');
            state.themeState = payload;
        },
        enableLoader(state, { payload }) {
            state.switched = payload;
        }
    }
})

const baseInfo = (state: RootState) => state.app;

export default themingSlice.reducer;

export const { updateThemeState, initThemeState, enableLoader } = themingSlice.actions;

export const getThemeState = createSelector(baseInfo, mode => mode);