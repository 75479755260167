import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IAppState {
    productListIndividual: any
}

const initialState = {
    productListIndividual: null
} as IAppState;

const productSliceIndividual = createSlice({
    name: "productIndividual",
    initialState,
    reducers: {
        updateProductListIndividual(state, { payload }) {
            state.productListIndividual = payload.products
        }
    }
})

const baseInfo = (state: RootState) => state.app;

export default productSliceIndividual.reducer;

export const { updateProductListIndividual } = productSliceIndividual.actions;

export const getProductIndividualState = createSelector(baseInfo, productIndividual => productIndividual);