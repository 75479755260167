import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateIsResetPassword, updateUserStatus } from "../../store/slices/app-slice";
import { close, error } from "../../store/slices/messages-slice";
import { resetPortfolioBreakdownList } from "../../store/slices/portfolio-breakdown-slice";
import { resetRecentTransactionData } from "../../store/slices/recent-transaction-slice";
import { resetUserData } from "../../store/slices/user-slice";
import { login as userLogin } from "../axios/auth";
import { resetPortfolioAllocationAndStrategyPerformanceList } from "../../store/slices/strategy-performance-slice";
import { Navigate } from "react-router-dom";
import { updateMsgState } from "../../store/slices/messages-v2-slice";
import { MESSAGE_TYPE, STATUS_CODE } from "../../constants/general";
import { signOut } from 'firebase/auth';
import {
  auth,
} from "../firebase";

interface AuthContextType {
    user: any;
    login: (emailAddress: string, password: string) => void;
    logout: () => void;
    condition: any;
    message: any;
    isPending: boolean;
    setLoginSessionStorage: (authData: any) => void;
}

let AuthContext = React.createContext<AuthContextType>({
    user: null,
    login: (emailAddress: string, password: string) => { },
    logout: () => { },
    condition: null,
    message: null,
    isPending: false,
    setLoginSessionStorage: (authData: any) => {}
});

export function useAuth() {
    return React.useContext(AuthContext)
}

function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<any>(null);
    const [accessToken, setAccessToken] = useState("");
    const [expiresIn, setExpiresIn] = useState(null);
    const [condition, setCondition] = useState("");
    const [message, setMessage] = useState(false);
    const [isPending, setIsPending] = React.useState<boolean>(false);
    const dispatch = useDispatch()

    useEffect(() => {
        const token = localStorage.getItem("access_token")
        const loginUser: any = localStorage.getItem("user")

        if (token !== null && token !== undefined) {
            setAccessToken(token)
        }

        if (loginUser !== undefined && loginUser !== null) {
            const userStatus = JSON.parse(loginUser)?.status;
            dispatch(updateUserStatus({ userStatus }));
            setUser(JSON.parse(loginUser))
        }
    }, [])

    const login = async (emailAddress: string, password: string) => {
        const response = await userLogin(emailAddress, password);
        setCondition(response?.data?.statusCode.toString());
        setMessage(response?.data?.errorMessage);
        if (response?.data?.statusCode  === STATUS_CODE.UNAUTHORIZED) {
            const errorMessage = response?.data?.errorMessage;
            dispatch(updateMsgState({state: "error", title: "Login failed.", reason: errorMessage, attempt: true, isMain: true}));
            return;
        }

        if(response?.data?.statusCode === STATUS_CODE.INTERNAL_SERVER_ERROR) {
            dispatch(updateMsgState({state: "error", title: "Login failed.", reason: "Internal server error", attempt: true, isMain: true}))
        }

        // if(response?.data?.statusCode === STATUS_CODE.UNAUTHORIZED) {
        //     dispatch(updateMsgState({state: "error", title: "Login failed.", reason: "Internal server error", attempt: true, isMain: true}))
        // }

        if (response?.data?.data !== undefined && response?.data?.data !== null) {
          setLoginSessionStorage(response.data.data)
        }
    }

    const setLoginSessionStorage = (authData: any, password?: string) => {
      const {
        access_token: accessToken,
        user,
        expiresIn
      } = authData
      const isReset = user.isReset; // user choose to reset password before

      if (isReset) {
        dispatch(updateIsResetPassword({ isReset: true, password }))
      }

      dispatch(updateUserStatus({ userStatus: user.status }));

      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("expiresIn", expiresIn);
      localStorage.setItem("user", JSON.stringify(user));

      if(localStorage.getItem("session_user_id") !== user?.id.toString()) {
        localStorage.removeItem("DEV_MODE");
      }
      localStorage.setItem("session_user_id", user?.id);

      setUser(user);
      setAccessToken(accessToken);

      setExpiresIn(expiresIn);
      if(user.status === 'P') {
        setIsPending(true);
      }
    }

    const logout = async () => {
        setUser(null);
        setAccessToken("");
        setExpiresIn(null);

        // Reset dashboard data
        dispatch(resetUserData());
        dispatch(resetRecentTransactionData());
        dispatch(resetPortfolioBreakdownList());
        dispatch(resetPortfolioAllocationAndStrategyPerformanceList());
        dispatch(close());

        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        // firebase
        signOut(auth)
    }

    const contextValue = {
        user,
        login,
        logout,
        condition,
        message,
        isPending, 
        setLoginSessionStorage
    }

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider
