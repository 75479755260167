import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ILogoutState {
    showLogout: boolean
}

const initialState = {
    showLogout: false,
} as ILogoutState;

const logoutSlice = createSlice({
    name: "showLogout",
    initialState,
    reducers: {
        updateLogoutState(state, payload) {
            state.showLogout = payload.payload
        }
    }
})

const baseInfo = (state: RootState) => state.app;

export default logoutSlice.reducer;

export const { updateLogoutState } = logoutSlice.actions;

export const getLogoutState = createSelector(baseInfo, show => show);