import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateModeStatus } from "../../store/slices/mode-toggle-slice";

function useDev() {

    const dispatch = useDispatch();

    useEffect(() => {
        const sandboxMode = localStorage.getItem("SANDBOX_MODE");

        if (sandboxMode === "1") {
            dispatch(updateModeStatus(true))
        } else {
            dispatch(updateModeStatus(false))
        }

    }, [])
}

export default useDev;