import { Box, Button, Divider, InputAdornment, Skeleton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CommonTable from "../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../components/DeveloperToolsComponents/EmptyCard";
import { ReactComponent as ExportIcon } from "../../assets/images/icons/exportIcon.svg";
import { CSVLink } from "react-csv";

import "./customer.scss";
import CustomerDetails from "./CustomerDetails";
import EditCustomerForm from "./editCustomerForm";
import { getCustomers, getCustomersFromSearch } from "../../services/axios/customer";
import CreateCustomerForm from "./createCustomerForm";
import { makeStyles } from "@mui/styles";
import searchIcon from "../../assets/images/icons/search.svg";
import { useAppSelector } from "../../store/hooks";

const useStyles = makeStyles(() => ({

    onCreate: {
        borderRadius: "8px",
        height: "40px",
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "none",
        backgroundColor: "#43D9BB",
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: "#36b299"
        }
    }

}))

const statusMappings: { [x: string]: string } = {
    'A': 'Active',
    'I': 'Inactive',
    'S': 'Suspended',
    'T': 'Terminated',
    'U': 'Unverified',
    'P': 'Pending',
    'R': 'Rejected'
}

function Customer() {

    const headings = ['First Name', 'Last Name', 'Date of Birth', 'Email', 'Address', 'Status']

    const [contentRows, setContentRows] = useState<string[][]>([[]]);
    const [ID, setID] = useState(0);

    const [displayTable, setDisplayTable] = useState(false);
    const [displayCustomer, setDisplayCustomer] = useState(false);
    const [displayEditCustomerForm, setDisplayEditCustomerForm] = useState(false);
    const [displayCreateCustomerForm, setDisplayCreateCustomerForm] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [exportArray, setExportArray] = useState<any[]>([]);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);
    const [iconValArray, setIconValArray] = useState<any[]>([]);

    const classes = useStyles();
    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const getCustomersFunc = async (_pageNumber: number) => {

        try {
            const customerData = await getCustomers(_pageNumber, sandboxMode);
            const customerArray = customerData.data.data;
            let totalLength = customerData.data.total;
            const resultArray: string[][] = [];
            const _iconValArray: any[] = [];

            if (customerArray.length > 0) setDisplayTable(true)

            customerArray.map((customer: any, i: number) => {
                const arr = [];
                arr[0] = customer.firstName ? customer.firstName : "-";
                arr[1] = customer.lastName ? customer.lastName : "-";
                arr[2] = customer.dateofBirth ? (new Date(customer.dateofBirth)
                    .toLocaleDateString
                    (undefined,
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',

                        })).toString() : "-";
                arr[3] = customer.emailAddress;
                arr[4] = `${customer.street ? customer.street : "-"}, ${customer.city ? customer.city : "-"}, ${customer.state ? customer.state : "-"}, ${customer.country ? customer.country : "-"}`
                if (!customer.street && !customer.city && !customer.state && !customer.country) {
                    arr[4] = "-"
                }
                arr[5] = statusMappings[customer.status];
                arr[6] = customer.id.toString();
                resultArray[i] = arr;

                // if (customer.status === "I" || customer.status === "S" || customer.status === "T" || customer.status === "U") {
                //     _iconValArray[i] = true
                // } else {
                //     _iconValArray[i] = false
                // }
            })

            setContentRows(resultArray)
            exportArrayFunc(resultArray)
            setIconValArray(_iconValArray)
            setPageNumber(_pageNumber)
            setSearchValue("")

            if (_pageNumber * 10 >= totalLength) {
                setDisplayShowMore(false)
            } else {
                setDisplayShowMore(true)
            }
        } catch (err) {
            setDisplayTable(false)
            setDisplayCustomer(false)
            setDisplayEditCustomerForm(false)
            setDisplayCreateCustomerForm(false)
        } finally {
            setDisplaySkeleton(false)
        }

    }

    const customersfuncForSearch = async (emailAddress: string) => {
        let _pageNumber = 1
        let CustomerData;

        if (searchValue === "") {
            CustomerData = await getCustomers(_pageNumber, sandboxMode)
        } else {
            CustomerData = await getCustomersFromSearch(emailAddress, _pageNumber, sandboxMode);
        }

        let customerArray = CustomerData.data.data;
        let totalLength = CustomerData.data.total;
        const resultArray: string[][] = [];
        const _iconValArray: any[] = [];

        customerArray.map((customer: any, i: number) => {
            const arr = [];
            arr[0] = customer.firstName ? customer.firstName : "-"
            arr[1] = customer.lastName ? customer.lastName : "-";
            arr[2] = customer.dateofBirth ? (new Date(customer.dateofBirth)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',

                    })).toString() : "-";
            arr[3] = customer.emailAddress;
            arr[4] = `${customer.street ? customer.street : "-"}, ${customer.city ? customer.city : "-"}, ${customer.state ? customer.state : "-"}, ${customer.country ? customer.country : "-"}`
            if (!customer.street && !customer.city && !customer.state && !customer.country) {
                arr[4] = "-"
            }
            arr[5] = statusMappings[customer.status];
            arr[6] = customer.id.toString();
            resultArray[i] = arr;

            // if (customer.status === "I" || customer.status === "S" || customer.status === "T" || customer.status === "U") {
            //     _iconValArray[i] = true
            // } else {
            //     _iconValArray[i] = false
            // }
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        exportArrayFunc(resultArray)
        setContentRows(resultArray)
        setIconValArray(_iconValArray)
        setPageNumber(_pageNumber)
    }

    const exportArrayFunc = async (resultArray: any[]) => {
        const arr: any[] = []
        resultArray.map(r => {
            arr.push({
                "First Name": r[0],
                "Last Name": r[1],
                "Date of Birth": r[2],
                "Email address": r[3],
                "Address": r[4],
                "Status": r[5]
            })
        })
        setExportArray(arr)
    }

    const displayShowMoreFunc = async () => {
        let _pageNumber = pageNumber + 1;
        let CustomerData;

        if (searchValue === "") {
            CustomerData = await getCustomers(_pageNumber, sandboxMode)
        } else {
            CustomerData = await getCustomersFromSearch(searchValue, _pageNumber, sandboxMode);
        }

        let customerArray = CustomerData.data.data;
        let totalLength = CustomerData.data.total;
        const resultArray: string[][] = [];
        const _iconValArray: any[] = []

        customerArray.map((customer: any, i: number) => {
            const arr = [];
            arr[0] = customer.firstName ? customer.firstName : "-"
            arr[1] = customer.lastName ? customer.lastName : "-";
            arr[2] = customer.dateofBirth ? (new Date(customer.dateofBirth)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',

                    })).toString() : "-";
            arr[3] = customer.emailAddress;
            arr[4] = `${customer.street ? customer.street : "-"}, ${customer.city ? customer.city : "-"}, ${customer.state ? customer.state : "-"}, ${customer.country ? customer.country : "-"}`
            if (!customer.street && !customer.city && !customer.state && !customer.country) {
                arr[4] = "-"
            }
            arr[5] = statusMappings[customer.status];
            arr[6] = customer.id.toString();
            resultArray[i] = arr;

            // if (customer.status === "I" || customer.status === "S" || customer.status === "T" || customer.status === "U") {
            //     _iconValArray[i] = true
            // } else {
            //     _iconValArray[i] = false
            // }
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        exportArrayFunc([...contentRows, ...resultArray])
        setContentRows([...contentRows, ...resultArray])
        setIconValArray([...iconValArray, ..._iconValArray])
        setPageNumber(_pageNumber)
    }

    const handleSearch = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            const _searchVal = searchValue;
            await customersfuncForSearch(_searchVal);
        }
    }

    const handleSearchWithButton = async () => {
        const _searchVal = searchValue;
        await customersfuncForSearch(_searchVal);
    }

    // useEffect(() => {
    //     getCustomersFunc(pageNumber)
    // }, [])

    useEffect(() => {
        if (!displayCustomer) {
            getCustomersFunc(1)
        }
    }, [sandboxMode])

    useEffect(() => {
        setDisplayCustomer(false)
        setDisplayEditCustomerForm(false)
        setDisplayCreateCustomerForm(false)
    }, [sandboxMode])

    useEffect(() => {
        if (!displayCreateCustomerForm) {
            getCustomersFunc(1)
        }
    }, [displayCreateCustomerForm])

    // useEffect(() => {
    //     if (!displayCustomer) {
    //         getCustomersFunc(1)
    //     }
    // }, [displayCustomer])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box style={{ width: "100%" }}>
        <div className="dev-customer-details-root">
            <div className="global-header-spacer"></div>
            <Box className={`developer-customer-container ${themeState}-customer`}>
                {/* <TestData /> */}
                {!displayCustomer && !displayEditCustomerForm && !displayCreateCustomerForm && <Box>
                    <Box display="flex" justifyContent="space-between" className="upper-panel">
                        <Box>
                            <Box className="customer">Customer</Box>
                            <Box className="subtitle">View your customer here</Box>
                        </Box>
                        <Box className="input-search-panel" display="flex" justifyContent="space-between">
                            <Box className="search-group">
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>
                                        )
                                    }}
                                    className="input-api"
                                    id="outlined-basic"
                                    placeholder="Search customers by email"
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => setSearchValue(event?.target.value)}
                                    onKeyDown={handleSearch}
                                    value={searchValue}
                                    fullWidth
                                />
                            </Box>
                            <Button className="groupButton" onClick={handleSearchWithButton}>
                                Search
                            </Button>
                            <Button className="create-new-btn" onClick={() => setDisplayCreateCustomerForm(true)}>
                                + Create New Customer
                            </Button>
                            <Box>
                                <CSVLink data={exportArray} filename={"customers" + ".csv"}>
                                    <Button className="exportBtn">
                                        <ExportIcon />
                                        Export
                                    </Button>
                                </CSVLink>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="divider" />
                </Box>}
                {displaySkeleton ? <Box>
                    <Skeleton variant="rounded" />
                    <br></br>
                    <Skeleton variant="rounded" height={400} />
                </Box> :
                    <Box>
                        {!displayEditCustomerForm && !displayCreateCustomerForm && <Box className="customer-content">
                            {!displayCustomer && <Box mt={2}>
                                {displayTable && <CommonTable
                                    headings={headings}
                                    rows={contentRows}
                                    displaySearchIcon={true}
                                    functionToCall={setDisplayCustomer}
                                    hasRowId={true}
                                    setID={setID}
                                    displayShowMore={displayShowMore}
                                    setDisplayShowMore={displayShowMoreFunc}
                                    iconValArray={iconValArray}
                                    title="Cannot display details until customer approval"
                                    firstColumnBold={true}
                                    navigationURL="/customer"
                                />}
                                {!displayTable && !displayCreateCustomerForm && <EmptyCard
                                    title="No Customer Yet"
                                    subtitle="There is no customer yet. Please start to create now."
                                    buttonContent="+ Create New Customer"
                                    functionToCall={setDisplayCreateCustomerForm}
                                />}
                            </Box>}
                            {displayCustomer && <Box mt={"-32px"}>
                                <CustomerDetails functionToCall={setDisplayCustomer} editFunction={setDisplayEditCustomerForm} ID={ID} />
                            </Box>}
                        </Box>}
                        {displayEditCustomerForm && <Box>
                            <EditCustomerForm goBackFunc={setDisplayEditCustomerForm} ID={ID} />
                        </Box>}
                        {!displayEditCustomerForm && !displayCustomer && displayCreateCustomerForm && <Box>
                            <CreateCustomerForm goBackFunc={setDisplayCreateCustomerForm} ID={ID} />
                        </Box>}
                    </Box>}
            </Box>
        </div>
    </Box>
}

export default Customer;