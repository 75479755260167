import { Box, Button, Divider, InputAdornment, Skeleton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CommonTable from "../../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../../components/DeveloperToolsComponents/EmptyCard";
import searchIcon from "../../../assets/images/icons/search.svg";
import { ReactComponent as ExportIcon } from "../../../assets/images/icons/exportIcon.svg";
import { CSVLink } from "react-csv";
import "./apiLogging.scss";
import APILoggingDetails from "./APILoggingDetails";
import { getAPILogs, getAPILogsFromSearch } from "../../../services/axios/apiLogging";
import { useAppSelector } from "../../../store/hooks";
import { STATUS_CODE_STYLE } from "../../../constants/general-status";

function APILogging() {

    const headings = ['Date/Time', 'API Method', 'API Endpoint', 'Status']
    const themeState = useAppSelector((state) => state.theme.themeState);

    const [contentRows, setContentRows] = useState<string[][]>([[]]);
    const [ID, setID] = useState(0);

    const [displayTable, setDisplayTable] = useState(false);
    const [displayAPILogging, setDisplayAPILogging] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [exportArrayForCSV, setExportArrayForCSV] = useState<any[]>([]);
    const [exportArray, setExportArray] = useState<string[][]>([[]]);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const mapStyles = (log: any) => {
        const arr = [];
        arr[0] = <div className="standard-table-cell-date">{
            (new Date(log.createdDate)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })).toString()
        }</div>
        arr[1] = <div className="standard-table-cell">{log.method}</div>
        arr[2] = <div>{log.url}</div>
        arr[3] = STATUS_CODE_STYLE(log.statusCode)
        arr[4] = log.id.toString();

        return arr;
    }

    const mappingForExport = (log: any) => {
        const arr = [];
        arr[0] = (new Date(log.createdDate)
            .toLocaleDateString
            (undefined,
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })).toString()

        arr[1] = log.method
        arr[2] = log.url
        arr[3] = log.statusCode.toString()
        arr[4] = log.id.toString();
        return arr;
    }

    const APILogsfunc = async (_pageNumber: number) => {
        try {
            const APILogsData = await getAPILogs(_pageNumber, sandboxMode);

            const logsArray = APILogsData.data.data;
            const totalLength = APILogsData.data.total;
            const resultArray: string[][] = [];
            const _exportArray: string[][] = [];

            if (logsArray.length > 0) setDisplayTable(true)

            logsArray.map((log: any, i: number) => {
                const arr = [];

                resultArray[i] = mapStyles(log);
            })

            logsArray.map((log: any, i: number) => {
                _exportArray[i] = mappingForExport(log);
            })

            setExportArray(_exportArray)
            exportArrayFunc(_exportArray)
            setContentRows(resultArray)
            setPageNumber(_pageNumber)
            setSearchValue("")

            if (_pageNumber * 10 >= totalLength) {
                setDisplayShowMore(false)
            } else {
                setDisplayShowMore(true)
            }
        } catch (err) {
            setDisplayTable(false);
            setDisplayAPILogging(false);
        } finally {
            setDisplaySkeleton(false)
        }
    }

    const APILogsfuncForSearch = async (url: string) => {
        let _pageNumber = 1
        let APILogsData = await getAPILogsFromSearch(url, _pageNumber, sandboxMode);
        let logsArray = APILogsData.data.data;
        let totalLength = APILogsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: string[][] = [];

        logsArray.map((log: any, i: number) => {
            const arr = [];
            resultArray[i] = mapStyles(log);
        })

        logsArray.map((log: any, i: number) => {
            _exportArray[i] = mappingForExport(log);
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        setExportArray(_exportArray)
        exportArrayFunc(_exportArray)
        setContentRows(resultArray)
        setPageNumber(_pageNumber)
    }

    const exportArrayFunc = async (exportArray: any[]) => {
        const arr: any[] = []
        exportArray.map(r => {
            arr.push({
                "Date/Time": r[0],
                "API Method": r[1],
                "API Endpoint": r[2],
                "Status": r[3]
            })
        })
        setExportArrayForCSV(arr)
    }

    const handleSearch = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            const _searchVal = searchValue.replace("/api/v1/", "")
            await APILogsfuncForSearch(_searchVal)
        }
    }

    const handleSearchWithButton = async () => {
        const _searchVal = searchValue.replace("/api/v1/", "")
        await APILogsfuncForSearch(_searchVal)
    }

    const displayShowMoreFunc = async () => {
        let _pageNumber = pageNumber + 1;
        let APILogsData = await getAPILogsFromSearch(searchValue, _pageNumber, sandboxMode);
        let logsArray = APILogsData.data.data;
        let totalLength = APILogsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: string[][] = [];

        logsArray.map((log: any, i: number) => {
            resultArray[i] = mapStyles(log);
        })

        logsArray.map((log: any, i: number) => {
            _exportArray[i] = mappingForExport(log);
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        setExportArray([...exportArray, ..._exportArray])
        exportArrayFunc([...exportArray, ..._exportArray])
        setContentRows([...contentRows, ...resultArray])

        setPageNumber(_pageNumber)
    }

    const goBackFunc = async (goBack: boolean) => {
        setDisplayAPILogging(goBack);
    }

    // useEffect(() => {
    //     APILogsfunc(pageNumber)
    // }, [])

    useEffect(() => {
        setDisplayAPILogging(false)
    }, [sandboxMode])

    useEffect(() => {
        if (!displayAPILogging) {
            APILogsfunc(1)
        }
    }, [sandboxMode])

    return <Box className={`developer-api-logging-container ${themeState}-developer-tools`}>
        {!displayAPILogging && <Box>
            <Box className="upper-panel" display="flex" justifyContent="space-between">
                <Box>
                    <Box className="api-logging">API Logging</Box>
                    <Box className="subtitle">View your API Logging here</Box>
                </Box>
                <Box className="action-wrapper" display="flex" justifyContent="space-between">
                    <div>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start"><img src={searchIcon} /></InputAdornment>
                                )
                            }}
                            id="outlined-basic"
                            className="input-field"
                            placeholder="Search API Endpoint"
                            variant="outlined"
                            size="small"
                            onChange={(event) => setSearchValue(event?.target.value)}
                            onKeyDown={handleSearch}
                            value={searchValue}
                            fullWidth
                        />
                    </div>
                    <div className="btn-wrapper">
                        <Button className="standard-btn search-btn" onClick={handleSearchWithButton}>
                            Search
                        </Button>
                        <CSVLink className="csv-btn" data={exportArrayForCSV} filename={"api_logs" + ".csv"}>
                            <Button fullWidth className="standard-btn export-btn">
                                <Box>
                                    <ExportIcon />
                                </Box>
                                Export
                            </Button>
                        </CSVLink>
                    </div>
                </Box>
            </Box>
            <Divider className="divider" />
        </Box>}
        {displaySkeleton ? <Box>
            <Skeleton variant="rounded" />
            <br></br>
            <Skeleton variant="rounded" height={400} />
        </Box> :
            <Box className="padded-options">
                {!displayAPILogging && <Box mt={2}>
                    {displayTable &&
                        <CommonTable
                            headings={headings}
                            rows={contentRows}
                            displaySearchIcon={true}
                            functionToCall={setDisplayAPILogging}
                            hasRowId={true}
                            setID={setID}
                            displayShowMore={displayShowMore}
                            setDisplayShowMore={displayShowMoreFunc}
                            navigationURL="/api-logging"
                        />
                    }
                    {!displayTable && <EmptyCard title="No API Logging" subtitle="No API Logging attempt yet" />}
                </Box>}
                {displayAPILogging && <Box>
                    <APILoggingDetails functionToCall={goBackFunc} ID={ID} />
                </Box>}
            </Box>}
    </Box>
}

export default APILogging;