import { AlertColor } from "@mui/material/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Message = IMessage & {
    severity: AlertColor | undefined;
};

export interface MessagesState {
    message: Message | null;
}

interface IMessage {
    text: String;
    error?: any;
    title?: String;
    createdAt?: String;
    id?: Number;
    fromGetUnreadUserNotification?: Boolean;
}

// Adds a message to the store
const createMessage = function (state: MessagesState, severity: AlertColor | undefined, text: IMessage) {
    const message: Message = {
        severity,
        ...text,
    };
    state.message = message;
};
const initialState: MessagesState = {
    message: null,
};
const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        // Creates an error message
        error(state, action: PayloadAction<IMessage>) {
            createMessage(state, "error", action.payload);
        },
        // Creates an information message
        info(state, action: PayloadAction<IMessage>) {
            createMessage(state, "info", action.payload);
        },
        warning(state, action: PayloadAction<IMessage>) {
            createMessage(state, "warning", action.payload);
        },
        success(state, action: PayloadAction<IMessage>) {
            createMessage(state, "success", action.payload);
        },
        notify(state, action: PayloadAction<IMessage>) {
            createMessage(state, undefined, action.payload);
        },
        // Closes a message
        close(state) {
            state.message = null;
        },
    },
});

export const { error, info, close, warning, success, notify } = messagesSlice.actions;

export default messagesSlice.reducer;
