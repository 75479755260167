import { useEffect, useState } from "react";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useAppSelector } from "../../store/hooks";
import { Tooltip } from "@mui/material";

type serverState = 'Healthy' | 'Under Maintenance' | 'Loading' | 'Offline';

export default function ServerStatus() {
    const [color, setColor] = useState<'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error' | undefined>('secondary');
    const serverState = useAppSelector(state => state.serverState.state);
    useEffect(() => {
        if(serverState && serverState === 'Healthy') {
            setColor('primary');
        } else if(serverState && serverState === 'Under Maintenance') {
            setColor('error');
        } else if(serverState && serverState === 'Offline') {
            setColor('secondary');
        }
    }, [serverState]);
    return (
        <div className="flex flex-row gap-2 justify-center items-center">
            <Tooltip title={serverState}><SignalCellularAltIcon color={color}/></Tooltip>
            <Tooltip title={serverState === 'Healthy' ? 'Connection Secured' : ''}><VerifiedUserIcon fontSize='small' color={color}/></Tooltip>
        </div>
    );
}

