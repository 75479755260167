import { Box, Divider, TextField, Card, useMediaQuery, Grid, Button, ButtonGroup, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import AreaChart from "../../components/AreaChart";
import "./reporting.scss";

import LgInfoCircle from "../../assets/images/icons/large-info-circle.svg";
import CalendarIcon from "../../components/CalendarIcon";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "../../store/hooks";
import { getDeveloperTransactionsTimeSeries, getDeveloperTransactionsTotalDeposit, getDeveloperTransactionsTotalWithdraw } from "../../services/axios/transaction-listing";
import { getCustomersTimeSeries } from "../../services/axios/customer";
import TestData from "../../components/TestData";
import ChartLoader from "../../components/Loader";
import ThousandSeperator from "../../utils/thousand-seperator";

const useStyles = makeStyles(() => ({

    root: {
        flexDirection: "row-reverse",
        borderRadius: "8px",
    }

}))

function Reporting() {

    const classes = useStyles();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [transactionVolumeYAxis, setTransactionVolumeYAxis] = useState([]);
    const [transactionVolumeXAxis, setTransactionVolumeXAxis] = useState([]);
    const [newCustomersYAxis, setNewCustomersYAxis] = useState([]);
    const [newCustomersXAxis, setNewCustomersXAxis] = useState([]);
    const [depositAmount, setDepositAmount] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [noDataTextTransactionVolume, setNoDataTextTransactionVolume] = useState('');
    const [noDataTextCustomers, setNoDataTextCustomers] = useState('');
    const [timePeriodTransactionVolume, setTimePeriodTransactionVolume] = useState(0);
    const [timePeriodNewCustomers, setTimePeriodNewCustomers] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode);
    const matches = useMediaQuery('(max-width:720px)');

    const getDeveloperTransactionVolumeFunc = async (start: string, end: string) => {
        setNoDataTextTransactionVolume('Loading...')
        const res = await getDeveloperTransactionsTimeSeries(start, end, sandboxMode)
        setIsLoading(false);
        if (!res.data.data) {
            setNoDataTextTransactionVolume("No data available")
            setTransactionVolumeXAxis([])
            setTransactionVolumeYAxis([])
            setTimePeriodTransactionVolume(0)
            return;
        }

        setTransactionVolumeXAxis(res.data.data.x)
        setTransactionVolumeYAxis(res.data.data.y)

        const yArray = res.data.data.y
        const val = yArray.reduce((total: number, y: number) => total + y)
        setTimePeriodTransactionVolume(val)
    }

    const getDepositAmountFunc = async (start: string, end: string) => {
        const res = await getDeveloperTransactionsTotalDeposit(start, end, sandboxMode);
        if (res.data.data) {
            setDepositAmount(res.data.data)
        }
    }

    const getWithdrawAmountFunc = async (start: string, end: string) => {
        const res = await getDeveloperTransactionsTotalWithdraw(start, end, sandboxMode);
        if (res.data.data) {
            setWithdrawAmount(res.data.data)
        }
    }

    const getNewCustomersFunc = async (start: string, end: string) => {
        setNoDataTextCustomers('Loading...')
        const res = await getCustomersTimeSeries(start, end, sandboxMode)

        if (!res.data.data) {
            setNoDataTextCustomers("No data available")
            setNewCustomersXAxis([])
            setNewCustomersYAxis([])
            setTimePeriodNewCustomers(0)
            return;
        }
        setNewCustomersXAxis(res.data.data.x)
        setNewCustomersYAxis(res.data.data.y)

        const yArray = res.data.data.y
        const val = yArray.reduce((total: number, y: number) => total + y)
        setTimePeriodNewCustomers(val)
    }

    const handleSearch = () => {
        if (startDate && endDate) {
            getDeveloperTransactionVolumeFunc(startDate.toString(), endDate.toString())
            getDepositAmountFunc(startDate.toString(), endDate.toString())
            getWithdrawAmountFunc(startDate.toString(), endDate.toString())
            getNewCustomersFunc(startDate.toString(), endDate.toString())
        } else {
            const start = (new Date(Date.now() - 864e5 * 6)).toString()
            const end = (new Date(Date.now())).toString()
            setStartDate(new Date(start))
            setEndDate(new Date(end))
            getDeveloperTransactionVolumeFunc(start, end)
            getDepositAmountFunc(start, end)
            getWithdrawAmountFunc(start, end)
            getNewCustomersFunc(start, end)
        }
    }

    useEffect(() => {
        const start = (new Date(Date.now() - 864e5 * 6)).toString()
        const end = (new Date(Date.now())).toString()
        getDeveloperTransactionVolumeFunc(start, end)
        getDepositAmountFunc(start, end)
        getWithdrawAmountFunc(start, end)
        getNewCustomersFunc(start, end)
        setStartDate(new Date(start))
        setEndDate(new Date(end))
    }, [sandboxMode])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`reporting ${themeState}-dashboard-replacement`}>
        {/* <TestData /> */}
        <div className="global-header-spacer"></div>
        <Box className="action-panel" display="flex" justifyContent="space-between">
            <Box>
                <Box className={`title`}>
                    Transaction Volume
                </Box>
                <Box className="text-sm font-normal text-gray-500">
                    Transaction volume (DeFi protocols)
                </Box>
            </Box>
            <Box className={`date-range-group ${themeState}-date-picker`} mt={"10px"}>
                <div className="date-range">
                    <Box className="date-picker-input">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={startDate}
                                components={{
                                    OpenPickerIcon: CalendarIcon
                                }}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.root
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: "Start Date"
                                    }}
                                />}
                                maxDate={endDate !== null ? endDate : null}
                            />
                            <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{endDate !== null && startDate === null ? "Please enter start date" : ""}</Box>
                        </LocalizationProvider>
                    </Box> <span className={`${themeState}-apply`}>_</span>
                    <Box className="date-picker-input">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={endDate}
                                components={{
                                    OpenPickerIcon: CalendarIcon
                                }}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.root
                                    }
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: "End Date"
                                    }}
                                />}
                                minDate={startDate !== null ? startDate : null}
                            />
                            <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{startDate !== null && endDate === null ? "Please enter end date" : ""}</Box>
                        </LocalizationProvider>
                    </Box>
                </div>
                <Box>
                    <Button className={`standard-search-btn ${themeState}-btn-global-style`} onClick={handleSearch} disabled={startDate === null || endDate === null}>
                        Search
                    </Button>
                </Box>
            </Box>
        </Box>
        <Divider className={`${themeState}-divider-spacer`}/>
        <div className="dashboard-root-box">
            <Grid className="upper-grid">
                <div className="chart-panel">
                    <Card className="card-wrap" sx={{ border: "1px solid #EAECF0" }}>
                        <div className="headings-group text-base font-medium text-gray-900" style={{ display: "flex", gap: "10px" }}>
                            <span className="about">Transaction Volume (DeFi Protocols)</span>
                            {/* <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip> */}
                            <span className="time-period" style={{ fontSize: "14px", backgroundColor: "#ECFDF3", padding: "2px 10px 2px 8px", borderRadius: "16px" }}>
                                $ {timePeriodTransactionVolume.toFixed(2)}
                            </span>
                        </div>

                        <div className="chart-holster">
                            {isLoading &&
                                <ChartLoader />
                            }
                            <AreaChart
                                series={[
                                    {
                                        name: 'Transaction Volume (Defi Protocols)',
                                        data: transactionVolumeYAxis,
                                    },
                                ]}
                                colors={['#9ABFF5']}
                                categories={transactionVolumeXAxis}
                                height={matches ? '90%' : '90%'}
                            />
                        </div>
                    </Card>
                </div>
                <div className="upper-side-panel">
                    <div className="section-deposit">
                        <Card className="card-data-grid" sx={{ border: "1px solid #EAECF0" }}>
                            <div className="tda-payment text-base font-medium text-gray-900">
                                Total Deposit Amount (Payment)
                                <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip>
                            </div>
                            <div className="tda-value">${ThousandSeperator(depositAmount.toString())}</div>                        </Card>
                    </div>
                    <div className="section-withdraw">
                        <Card className="card-data-grid" sx={{ border: "1px solid #EAECF0" }}>
                            <div className="twa-payment text-base font-medium text-gray-900">
                                Total Withdraw Amount (Payment)
                                <Tooltip title="Total withdraw of the selected period." arrow><img src={LgInfoCircle} /></Tooltip>
                            </div>
                            <div className="twa-value">${ThousandSeperator(withdrawAmount.toString())}</div>                        </Card>
                    </div>
                </div>
            </Grid>
        </div >

        <Box className="new-customer" pb={6}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Box className="dashboard title">New Customers</Box>
            </Box>
            <Divider className={`${themeState}-divider-spacer`}/>
            <Box my={2}>
                <Card style={{ padding: "20px" }} sx={{ border: "1px solid #EAECF0" }}>
                    <div className="inner-title" style={{ display: "flex", gap: "10px" }}>
                        New Customers
                        {/* <Tooltip title="Total amount of the selected period." arrow><img src={LgInfoCircle} /></Tooltip> */}
                        <span className="inner-value" style={{ fontSize: "14px", backgroundColor: "#ECFDF3", padding: "2px 10px 2px 8px", borderRadius: "16px" }}>
                            {timePeriodNewCustomers} new customers
                        </span>
                    </div>

                    <div className="chart-holster">
                        {isLoading &&
                            <ChartLoader />
                        }
                        <AreaChart
                            noDecimals={true}
                            series={[
                                {
                                    name: 'New Customers',
                                    data: newCustomersYAxis,
                                },
                            ]}
                            colors={['#9ABFF5']}
                            categories={newCustomersXAxis}
                            height={matches ? '150%' : '220%'}
                        />
                    </div>
                </Card>
            </Box>
        </Box>
    </Box >
}

export default Reporting;