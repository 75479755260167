import { IUser } from '../../interfaces/user';
import axios, { getJWT } from './axios';

export const registerUser = async (
  emailAddress: string,
  password: string,
  firstName: string,
  referralCode?: string,
) => {
  return await axios.post('/api/v1/user/register', {
    emailAddress,
    password,
    firstName,
    referralCode,
  });
};

export const getProfile = async () => {
  const headers = await getJWT();

  try {
    const response = await axios
      .get('/api/v1/user/get-profile', headers)
      .catch((error) => {
        throw new Error(error);
      });
    return response;
  } catch (error) {
    console.log(`Error thrown: `, error);
  }
};

export const getBuinessProfile = async (userID: any) => {
  const headers = await getJWT();

  try {
    const response = await axios
      .post('/api/v1/business-user/get-profile', { userID: userID }, headers)
      .catch((error) => {
        throw new Error(error);
      });

    return response;
  } catch (error) {
    console.log(`Error thrown: `, error);
  }
};

export const exchangeSocialToken = async (
  token: string,
  referralCode?: string,
) => {
  const headers = await getJWT();

  try {
    const response = await axios
      .post('/api/v1/auth/social-login/oauth', { token, referralCode }, headers)
      .catch((error) => {
        const response = error.response;
        if (response.data && response.data.message) {
          const msg = response.data.message[0];
          if (
            msg.startsWith('Account exist') 
          )
            return msg;
        }
        throw new Error(error);
      });

    return response;
  } catch (error) {
    console.log(`Error thrown: `, error);
  }
};

export const checkReferralCode = async (referralCode?: string) => {
  return await axios.get(
    `/api/v1/user/validate-referral-code?code=${referralCode}`,
  );
};
