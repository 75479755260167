import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import './style.scss';

interface ISecuroButtonWithLoaderProps {
  text: string;
  color?: any;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  successText?: string;
  style?: any;
  classes?: string;
  onClick?: () => void;
}

export default function SecuroButtonWithLoader({
  text,
  loading,
  loadingText,
  disabled,
  style,
  classes,
  onClick,
}: ISecuroButtonWithLoaderProps) {
  return (
    <Button
      className="standard-btn-action"
      onClick={onClick}
      type="submit"
    >
      <span className="text-sm font-medium text-white">
      {/* {!loading && (
        <CircularProgress
          size={15}
          thickness={5}
          color="info"
          style={{ marginRight: '1rem' }}
        />
      )} */}
      {loading && loadingText ? loadingText : text}
      </span>
    </Button>
  );
}
