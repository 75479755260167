import { initializeApp, getApp } from "firebase/app";
import { 
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBdb0MttRDUrbfgSrliA9P7ZPo1AQvE9s4",
  authDomain: "app.thirdfi.org",
  projectId: "securo-8968b",
  storageBucket: "securo-8968b.appspot.com",
  messagingSenderId: "758685787287",
  appId: "1:758685787287:web:d4a04d8283e24d62d675d2",
  measurementId: "G-XT6CCHYS46"
};

function createFirebaseApp(config: any) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

const firebaseApp = createFirebaseApp(firebaseConfig);

// Auth exports
export const auth = getAuth(firebaseApp);
export const _googleAuthProvider = new GoogleAuthProvider();
_googleAuthProvider.addScope('email')
export const googleAuthProvider = _googleAuthProvider
export const facebookAuthProvider = new FacebookAuthProvider();
export const githubAuthProvider = new GithubAuthProvider();
githubAuthProvider.addScope('user');
export const twitterAuthProvider = new TwitterAuthProvider();
export const microsoftAuthProvider = new OAuthProvider('microsoft.com');
