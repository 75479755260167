import MyProfile from "./normalAccount";
import { useAppSelector } from "../../store/hooks";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from "react";
import Kyc from "./components/kyc";
import ResetPassword from "../../components/ResetPassword";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
function Tabs(context?: any) {
    const [value, setValue] = useState('my-profile');
    const isResetPassword = useAppSelector((state) => state.app.isResetPassword);
    const isDesktop = useMediaQuery('(min-width: 720px)');
    const location = useLocation();

    useEffect(() => {
        if (location.search.replace('?', '') === 'kyc') {
            setValue('kyc')
        } else {
            setValue('my-profile')
        }
    }, [])
    useEffect(() => {
        if (isResetPassword) {
            setValue('password')
        }
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const [age, setAge] = useState('');

    const handleChange2 = (event: SelectChangeEvent) => {
        setValue(event.target.value);
    };
    const themeState = useAppSelector((state) => state.theme.themeState);

    return (
        <div className={`tabs-content ${themeState}-account`}>
            <MyProfile />
        </div>
    )
}

export default Tabs;
