import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import SessionIcon from "../../assets/images/alert/session-ended.svg";
import LogoutIcon from '../../assets/images/icons/logout-icon-v2.svg'
import CloseCrossedIcon from "../../assets/images/icons/close-crossed-general-icon.svg";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from '../../services/auth/authProvider';
import { updateInactivityStatus } from "../../store/slices/inactivity-slice";
import { updateModeStatus } from '../../store/slices/mode-toggle-slice';
import { updatePortalStatus } from '../../store/slices/portal-toggle-slice';
import { updateLogoutState } from '../../store/slices/logout-slice';
import { useAppSelector } from '../../store/hooks';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 9,
            color: '#fff',
            backdropFilter: "blur(8px)",
        },
        paper: {
            overflowY: "hidden",
            width: "400px",
            height: "248px",
            boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
            background: "#FFFFFF",
            borderRadius: "12px",
            zIndex: theme.zIndex.drawer + 99999,
            paddingLeft: "16px",
            paddingRight: "16px",
        },
        okButton: {
            ['@media (min-width:720px)']: {
                width: "352px",
            },
            ['@media (max-width:720px)']: {
                width: "100%",
            },
            textTransform: "none",
            height: "44px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#D92D20",
            borderRadius: "8px",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            '&:hover': {
                backgroundColor: "#D92D20"
            }
        },
        cancelButton: {
            ['@media (min-width:720px)']: {
                width: "352px",
            },
            ['@media (max-width:720px)']: {
                width: "100%",
            },
            textTransform: "none",
            height: "44px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#FFFFF",
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            '&:hover': {
                backgroundColor: "#FFFFF"
            }
        },
        close_icon: {
            position: 'absolute',
            right: 0,
            padding: '24px',
            cursor: 'pointer'
        }
    }),
);

function AlertBox({ show, type }: { show?: boolean, type?: string }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [openLogout, setOpenLogout] = useState(true);

    let { logout } = useAuth()
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(updateInactivityStatus(false));
        dispatch(updateModeStatus(false));
        dispatch(updatePortalStatus(false));
        logout();
        navigate("/");
    };

    const handleCloseLogout = () => {

        dispatch(updateLogoutState(false));
        logout();
        navigate("/");
    }
    const themeState = useAppSelector((state) => state.theme.themeState);

    return (
        <>
        {show && type === "session" &&
            <div className={`${themeState}-alertbox-modal}`}>
                <Backdrop className={classes.backdrop} open={open}>
                    <Dialog className={`${themeState}-alertbox-modal`} open={open} classes={{ paper: classes.paper }}>
                        <Box display="flex" justifyContent="center" mt={"24px"}>
                            <div><img style={{ width: "48px", height: "48px" }} src={SessionIcon} /></div>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={"20px"}>
                            <div className='highlights text-lg font-medium text-gray-900'>Your session has expired</div>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={"8px"}>
                            <div className='text-sm font-normal text-gray-500'>Please login again.</div>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={"32px"} mb={"24px"}>
                            <Button onClick={handleClose} className={classes.okButton}>
                                <div className='text-base font-medium text-white'>OK</div>
                            </Button>
                        </Box>
                    </Dialog>
                </Backdrop>
            </div>   
        }
        {show && type === "logout" &&
            <div>
                <Backdrop className={`${classes.backdrop}`} open={openLogout}>
                    <Dialog className={`${themeState}-alertbox-modal`} open={openLogout} classes={{ paper: classes.paper }}>
                        <div onClick={() => dispatch(updateLogoutState(false))} className={classes.close_icon}><img src={CloseCrossedIcon}/></div>
                        <Box display="flex" justifyContent="center" mt={"24px"}>
                            <div><img style={{ width: "48px", height: "48px" }} src={LogoutIcon} /></div>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={"20px"}>
                            <div className='highlights text-lg font-medium text-gray-900'>Log Out</div>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={"8px"}>
                            <div className='text-sm font-normal text-gray-500'>Are you sure you want to log out?</div>
                        </Box>
                        <Box display="flex" justifyContent="center" gap={"12px"} mt={"32px"} mb={"24px"}>
                            <Button onClick={() => dispatch(updateLogoutState(false))} className={`${classes.cancelButton} btn-cancel`}>
                                <span className='text-base font-medium text-gray-900'>Cancel</span>
                            </Button>
                            <Button onClick={handleCloseLogout} className={classes.okButton}>
                                <div className='text-base font-medium text-white'>Log Out</div>
                            </Button>
                        </Box>
                    </Dialog>
                </Backdrop>
            </div>   
        }
        </>
    );
}
export default AlertBox;