import { createSlice } from '@reduxjs/toolkit';

export interface IPortal {
    developerPortal: boolean
}

const initialState: IPortal = {
    developerPortal: false
}

const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {
        updatePortalStatus(state, action) {
            if(action.payload) {
                localStorage.setItem('DEV_MODE', '1');
            } else {
                localStorage.setItem('DEV_MODE', '0');
            }
            state.developerPortal = action.payload
        }
    }
})

export const { updatePortalStatus } = portalSlice.actions

export default portalSlice.reducer