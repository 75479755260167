import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FileSearchIcon from "../../../assets/images/icons/FileSearchIcon.svg";
import "./index.scss";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

interface ICommonTable {
    headings?: any[],
    rows: any[][],
    displaySearchIcon?: boolean,
    leftAlign?: boolean,
    functionToCall?: any,
    setID?: any,
    hasRowId?: boolean,
    displayShowMore?: boolean,
    setDisplayShowMore?: any,
    navigationURL?: string,
    iconValArray?: any[],
    title?: string,
    firstColumnBold?: boolean
}

export default function CommonTable(props: ICommonTable) {
    const { headings,
        rows,
        displaySearchIcon,
        leftAlign,
        functionToCall,
        hasRowId,
        setID,
        displayShowMore,
        setDisplayShowMore,
        navigationURL,
        iconValArray,
        title,
        firstColumnBold
    } = props;

    const navigate = useNavigate()

    const viewDetails = (id: string) => {
        if (functionToCall) functionToCall(true)
        setID(parseInt(id))
        if (navigationURL) navigate(`${navigationURL}/${id}`)
    }

    return (
        <Box className="sizing-option">
            <TableContainer className='table-container-root' component={Paper}>
                <Table aria-label="simple table" >
                    {headings && <TableHead>
                        <TableRow className='of-table-head'>
                            {headings && headings.map((heading: string, index: number) => <TableCell className='custom-cell text-xs font-medium text-gray-500' key={index}>
                                {heading}
                            </TableCell>)}
                            {displaySearchIcon && <TableCell className='custom-cell' align="right"></TableCell>}
                        </TableRow>
                    </TableHead>}
                    <TableBody>
                        {rows && rows.map((row: any, i: number) => (
                            <TableRow
                                key={i}
                                className="of-table-body"
                            >

                                {/* <TableCell className='text-gray-500 on-standard'>
                                {rows[i][0]}
                            </TableCell>
                            <TableCell className='text-gray-900 on-standard'>
                                {rows[i][1]}
                            </TableCell>
                            <TableCell className='text-gray-900 on-standard'>
                                {rows[i][2]}
                            </TableCell>
                            <TableCell className='text-gray-900 on-standard'>
                                {rows[i][3]}
                            </TableCell>
                            <TableCell className={`text-success-500 on-status`}>
                                <div className="wrapped-status case-success">
                                {rows[i][4]}
                                </div>
                            </TableCell> */}
                                {row.map((value: string, index: number) => {
                                    if (hasRowId && index === row.length - 1) {
                                        return;
                                    } else {
                                        return <TableCell
                                            className={`custom-cell ${index === 0 && !firstColumnBold ? `text-gray-500` : `text-gray-900`} on-standard`}
                                            key={index}
                                            style={{ maxWidth: "300px", overflowWrap: "break-word" }}
                                        >
                                            {value}
                                        </TableCell>
                                    }
                                })}

                                {
                                    (() => {
                                        if (displaySearchIcon && iconValArray && iconValArray.length > 0) {
                                            if (iconValArray[i]) {
                                                return <TableCell className='responsive-icon' align="right">
                                                    <Tooltip title={title} arrow>
                                                        <img src={FileSearchIcon} alt="More Info Icon" style={{ float: "right", marginRight: "10px" }} />
                                                    </Tooltip>
                                                </TableCell>
                                            } else {
                                                return <TableCell className='responsive-icon' align="right">
                                                    <img src={FileSearchIcon} alt="More Info Icon" style={{ cursor: "pointer", float: "right", marginRight: "10px" }} onClick={() => viewDetails(row[row.length - 1])} />
                                                </TableCell>
                                            }
                                        } else if (displaySearchIcon) {
                                            return <TableCell className='responsive-icon' align="right">
                                                <img src={FileSearchIcon} alt="More Info Icon" style={{ cursor: "pointer", float: "right", marginRight: "10px" }} onClick={() => viewDetails(row[row.length - 1])} />
                                            </TableCell>
                                        }
                                    })()
                                }
                                {/* 
                                {displaySearchIcon && <TableCell className='responsive-icon' align="right">
                                    <img src={FileSearchIcon} alt="More Info Icon" style={{ cursor: "pointer", float: "right", marginRight: "10px" }} onClick={() => viewDetails(row[row.length - 1])} />
                                </TableCell>} */}
                            </TableRow>
                        ))}
                        {displayShowMore && <TableRow>
                            <TableCell className='text-primary font-medium show-more'>
                                <a style={{ cursor: "pointer" }} onClick={setDisplayShowMore}>Show more</a>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                    {/* <TableFooter>
                    <TableCell className='of-table-footer'><div className='clickable-text text-sm font-medium text-primary'>Show more</div></TableCell>
                </TableFooter> */}
                </Table>
            </TableContainer>
        </Box>
    );
}
