import Lottie, { useLottie } from 'lottie-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import { enableLoader } from '../../../store/slices/theming-slice';
import SwitchedJSON from './switched.json';
import './switched.scss';
export default function Switched() {

    const themeSwitched = useAppSelector((state) => state.theme.switched);

    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(enableLoader(false));
        }, 1000 );
        return () => clearTimeout(timer);
    }, [themeSwitched]);
    
    if(themeSwitched) {
            return (
                <div className='inject-on-switched'>
                    <div className='conceal'>
                        <Lottie animationData={SwitchedJSON} loop={true}/>
                    </div>
                </div>
            )
    } else {
        return null
    }
}

