import { Box, Button, Divider, Skeleton, TextField } from "@mui/material";
import CommonTable from "../../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../../components/DeveloperToolsComponents/EmptyCard";
import { useEffect, useState } from "react";
import { ReactComponent as ExportIcon } from "../../../assets/images/icons/exportIcon.svg";
import "./webhook.scss";
import WebhookEventsDetails from "./WebhookEvents";
import { makeStyles } from "@mui/styles";
import { getAllWebhookLogs, getWebhookLogsWithinDateRange } from "../../../services/axios/webhookLogs";
import { CSVLink } from "react-csv";
import { useAppSelector } from "../../../store/hooks";
import { STATUS_CODE_STYLE } from "../../../constants/general-status";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => ({
    root: {
        flexDirection: "row-reverse",
        borderRadius: "8px",
    }
}))

function WebhookEvents() {

    const classes = useStyles();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const headings = ['Date/Time', 'Transaction ID', 'Webhook Attempts', 'Webhook Response Received']

    const [contentRows, setContentRows] = useState<any[][]>([[]]);
    const [ID, setID] = useState(0);

    const [displayTable, setDisplayTable] = useState(false);
    const [displayWebhookEvents, setDisplayWebhookEvents] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [exportArrayForCSV, setExportArrayForCSV] = useState<any[]>([]);
    const [exportArray, setExportArray] = useState<string[][]>([[]]);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const mapStyles = (log: any) => {
        const arr = [];
        arr[0] = <div className="standard-table-cell-date">{
            (new Date(log.createdDate)
                .toLocaleDateString
                (undefined,
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    })).toString()
        }</div>
        arr[1] = <div className="standard-table-cell">{log.transaction.id.toString()}</div>
        arr[2] = <div className="standard-table-cell">{log.tries}</div>
        arr[3] = STATUS_CODE_STYLE(log.responseCode);
        arr[4] = log.id.toString();

        return arr;
    }

    const mapForExport = (log: any) => {
        const arr = [];
        arr[0] = (new Date(log.createdDate)
            .toLocaleDateString
            (undefined,
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })).toString()

        arr[1] = log.transaction.id.toString()
        arr[2] = log.tries
        arr[3] = log.responseCode
        arr[4] = log.id.toString();

        return arr;
    }

    const getWebhookLogsFunc = async (_pageNumber: number) => {
        try {
            const webhookLogsData = await getAllWebhookLogs(_pageNumber, sandboxMode);

            const logsArray = webhookLogsData.data.data;
            const totalLength = webhookLogsData.data.total;
            const resultArray: any[][] = [];
            const _exportArray: any[][] = [];

            if (logsArray.length > 0) setDisplayTable(true)

            logsArray.map((log: any, i: number) => {
                resultArray[i] = mapStyles(log)
            })

            logsArray.map((log: any, i: number) => {
                _exportArray[i] = mapForExport(log)
            })

            setExportArray(_exportArray)
            exportArrayFunc(_exportArray)
            setContentRows(resultArray)
            setPageNumber(_pageNumber)
            setStartDate(null)
            setEndDate(null)

            if (_pageNumber * 10 >= totalLength) {
                setDisplayShowMore(false)
            } else {
                setDisplayShowMore(true)
            }
        } catch (err) {
            setDisplayTable(false)
            setDisplayWebhookEvents(false)
        } finally {
            setDisplaySkeleton(false)
        }
    }

    const getWebhookLogsFuncForSearch = async (start: string, end: string) => {
        let _pageNumber = 1
        let WebhookLogsData = await getWebhookLogsWithinDateRange(start, end, _pageNumber, sandboxMode);
        let logsArray = WebhookLogsData.data.data;
        let totalLength = WebhookLogsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: any[][] = [];

        logsArray.map((log: any, i: number) => {
            resultArray[i] = mapStyles(log);
        })

        logsArray.map((log: any, i: number) => {
            _exportArray[i] = mapForExport(log)
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        exportArrayFunc(_exportArray)
        exportArrayFunc(_exportArray)
        setContentRows(resultArray)
        setPageNumber(_pageNumber)
    }

    const displayShowMoreFunc = async () => {
        let _pageNumber = pageNumber + 1;
        let webhookLogsData;

        if (startDate && endDate) {
            webhookLogsData = await getWebhookLogsWithinDateRange(startDate.toString(), endDate.toString(), _pageNumber, sandboxMode);
        } else {
            webhookLogsData = await getAllWebhookLogs(_pageNumber, sandboxMode);
        }

        let logsArray = webhookLogsData.data.data;
        let totalLength = webhookLogsData.data.total;
        const resultArray: string[][] = [];
        const _exportArray: any[][] = [];

        logsArray.map((log: any, i: number) => {
            resultArray[i] = mapStyles(log);
        })

        logsArray.map((log: any, i: number) => {
            _exportArray[i] = mapForExport(log)
        })

        if (_pageNumber * 10 >= totalLength) {
            setDisplayShowMore(false)
        } else {
            setDisplayShowMore(true)
        }

        setExportArray([...exportArray, ..._exportArray])
        exportArrayFunc([...exportArray, ..._exportArray])
        setContentRows([...contentRows, ...resultArray])

        setPageNumber(_pageNumber)
    }

    const handleSearch = () => {
        if (startDate && endDate) {
            getWebhookLogsFuncForSearch(startDate.toString(), endDate.toString())
        } else {
            getWebhookLogsFunc(1)
        }
    }

    const exportArrayFunc = async (resultArray: any[]) => {
        const arr: any[] = []
        resultArray.map(r => {
            arr.push({
                "Date/Time": r[0],
                "Transaction ID": r[1],
                "Webhook Attempts": r[2],
                "Webhook Response Received": r[3]
            })
        })
        setExportArrayForCSV(arr)
    }

    // useEffect(() => {
    //     getWebhookLogsFunc(pageNumber)
    // }, [])

    useEffect(() => {
        setDisplayWebhookEvents(false)
    }, [sandboxMode])

    useEffect(() => {
        if (!displayWebhookEvents) {
            getWebhookLogsFunc(1)
        }
    }, [sandboxMode])
    const themeState = useAppSelector((state) => state.theme.themeState);

    const navigate = useNavigate();

    return <Box>
        <Box className={`developer-webkook-events-container ${themeState}-webhook-events`}>
            {!displayWebhookEvents && <Box>
                <Box className="action-panel" display="flex" justifyContent="space-between" mb={2}>
                    <Box>
                        <Box className="webhook-events">Webhook Events</Box>
                        <Box className="subtitle">View your webhook events here</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" gap={"8px"}>
                        {/* <Box sx={{ width: "160px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={startDate}
                                    components={{
                                        OpenPickerIcon: CalendarIcon
                                    }}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.root
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Start Date"
                                        }}
                                    />}
                                    maxDate={endDate !== null ? endDate : null}
                                />
                                <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{endDate !== null && startDate === null ? "Please enter start date" : ""}</Box>
                            </LocalizationProvider>
                        </Box> */}
                        {/* <Box sx={{ width: "160px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    components={{
                                        OpenPickerIcon: CalendarIcon
                                    }}
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.root
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        fullWidth
                                        size="small"
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "End Date"
                                        }}
                                    />}
                                    minDate={startDate !== null ? startDate : null}
                                />
                                <Box style={{ fontSize: "12px", color: "#EB4747", marginTop: "5px" }} ml={1}>{startDate !== null && endDate === null ? "Please enter end date" : ""}</Box>
                            </LocalizationProvider>
                        </Box> */}
                        {/* <Box>
                            <Button className="standard-btn search-btn" onClick={handleSearch}>
                                Search
                            </Button>
                        </Box> */}
                        <Box className="mobile-flex">
                            <CSVLink data={exportArrayForCSV} filename={"webhook_logs" + ".csv"}>
                                <Button className="exportBtn">
                                    <Box>
                                        <ExportIcon />
                                    </Box>
                                    Export
                                </Button>
                            </CSVLink>
                        </Box>
                    </Box>
                </Box>
                <Divider className="divider" />
            </Box>}

            {displaySkeleton ? <Box>
                <Skeleton variant="rounded" />
                <br></br>
                <Skeleton variant="rounded" height={400} />
            </Box> :
                <Box className="padded-options">
                    {!displayWebhookEvents && <Box mt={2}>
                        {displayTable && <CommonTable
                            headings={headings}
                            rows={contentRows}
                            displaySearchIcon={true}
                            functionToCall={setDisplayWebhookEvents}
                            hasRowId={true}
                            setID={setID}
                            displayShowMore={displayShowMore}
                            setDisplayShowMore={displayShowMoreFunc}
                            navigationURL="/webhook-events"
                        />}
                        {!displayTable && <EmptyCard title="No webhook events" subtitle="No webhook event attempts" />}
                    </Box>}
                    {displayWebhookEvents && <Box>
                        <WebhookEventsDetails functionToCall={setDisplayWebhookEvents} ID={ID} />
                    </Box>}
                </Box>}
        </Box>
    </Box>
}

export default WebhookEvents;