import { useAuth } from "../../services/auth/authProvider";
import { Avatar, Box, Button, Card, CardContent, TextField, Grid, Typography, Divider, CircularProgress, useMediaQuery, AppBar, Select, MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import HumanIcon from "../../assets/images/icons/default-profile.svg";

import UploadIcon from "../../assets/images/icons/upload-icon.svg";
import CloudIcon from "../../assets/images/icons/cloud-upload.svg";
import UpdatedIcon from "../../assets/images/icons/updated-icon.svg";
import CloseIcon from "../../assets/images/icons/close-crossed-icon.svg";
import "./profile.scss";
import { messages } from "../../constants/messages";
import { uploadDocument, userRemoveProfilePic, userUpdateData } from "../../services/axios/kyc";
import { getProfile } from "../../services/axios/user";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../store/slices/user-slice";
import { useNavigate } from "react-router-dom";
import { error, success } from "../../store/slices/messages-slice";

import Dropzone, { useDropzone } from 'react-dropzone'
import { updateMsgState } from "../../store/slices/messages-v2-slice";

import { allCountries, CountryData } from 'country-region-data';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarIcon from "../../components/CalendarIcon";
import dayjs from "dayjs";
import { differenceInYears, parse } from "date-fns";

import FileSuccessIcon from "../../assets/images/icons/file-upload-success.svg";
import TickSuccessIcon from "../../assets/images/icons/tick.svg";
import DeleteIcon from "../../assets/images/icons/delete.svg";
import { STATUS_CODE } from "../../constants/general";
import ResetPassword from "../../components/ResetPassword";

function ProfileLayoutWrapper({ children }: any) {
    const matches = useMediaQuery('(max-width:720px)');
    return <div className={!matches ? "flex-row flex-space-between" : ""} style={{ marginTop: "20px" }}>
        {children}
    </div>
}

function MyProfile() {
    const { user } = useAuth();
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [profilePicDisplayFile, setProfilePicDisplayFile] = useState<any>(HumanIcon);
    const [profilePicFile, setProfilePicFile] = useState<any>(null);
    const [passportFile, setPassportFile] = useState<any>(undefined);
    const [nationalIdFile, setNationalId] = useState<any>(undefined);
    const [loadingAll, setLoadingAll] = useState(false);
    const [username, setUsername] = useState("");
    // const [userInfo, setUserInfo] = useState<any>({});
    const [passportFileUrl, setPassportFileUrl] = useState<any>(undefined);
    const [nationalIdFileUrl, setNationalIdUrl] = useState<any>(undefined);
    // const [profilePicStatus, setProfilePicStatus] = useState(false);
    const [nationalIdStatus, setNationalStatus] = useState(false);
    const [passportFileStatus, setPassportFileStatus] = useState(false);
    const [userStatus, setUserStatus] = useState<any>(undefined);
    const [messageTxt, setMessageTxt] = useState<any>(undefined);

    const [listOfCountry, setListOfCountry] = useState<any[]>([]);

    const matches = useMediaQuery('(max-width:720px)');

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
        getValues,
        clearErrors
    } = useForm({
        mode: 'onChange'
    });

    const routeChange = (path: string) => {
        navigate(path);
    };

    const [previewImage, setPreviewImage] = useState<any>(null);
    const [newLogoFile, setNewLogoFile] = useState(false);

    useEffect(() => {
        const listCountry: any[] = [];
        allCountries.forEach((country: any) => {
            listCountry.push(country[0]);
        })
        setListOfCountry(listCountry);
    }, [])


    const [selectedCountryIndex, setSelectedCountryIndex] = useState<any>();
    const [isSelectedCountry, setIsSelectedCountry] = useState(false);
    const [selectedRegionIndex, setSelectedRegionIndex] = useState<any>(null);


    const [region, setRegion] = useState<any[] | undefined>([]);

    const handleCountry = (event: any) => {
        setIsSelectedCountry(true);
        const filter: any[] | undefined = allCountries.find((country, index) => country[0] === event.target.value);

        setRegion(filter?.at(2));
    }

    const userState = useAppSelector((state) => state.user.userData);

    const findUserDetails = async () => {

        // const response = await getProfile();

        const data = userState;
        let getCountryIndex = allCountries.findIndex((item: CountryData, index) => item[0] === data.country);

        setSelectedCountryIndex(data.country)
        // Init load region
        const filter: any[] | undefined = allCountries.at(getCountryIndex)?.[2];

        setSelectedRegionIndex(data.state)
        // setSelectedRegionIndex()
        setRegion(filter);

        // End of Init load region
        setValue('firstName', data.firstName)
        setValue('lastName', data.lastName)
        setValue('dob', data.dateOfBirth)
        setValue('emailAddress', data.emailAddress)
        setValue('streetAddress', data.street)
        setValue('city', data.city)
        setValue('country', data.country)
        setValue('state', data.state)
        setValue('postalCode', data.postalCode)
        setPassportFileUrl(data.userPassportFrontUrl)
        setNationalIdUrl(data.userNricUrl)
        setUserStatus(data.status)

        if (data.status === 'A') {
            setMessageTxt("Verified User, no need to perform re-upload action")
        } else if (data.status === 'PR') {
            setMessageTxt("Still under review stage, please contact admin if you need to perform re-upload action")
        }

        if (data.country !== null) {
            setIsSelectedCountry(true);
        }

        if (data.userPhotoUrl) {
            setProfilePicFile(new File([], data.userPhotoUrl.substring(data.userPhotoUrl.lastIndexOf('/') + 1)))
            setPreviewImage(data.userPhotoUrl);
            setUsername(data.firstName + ' ' + data.lastName)
        } else {
            // setProfilePicFile(HumanIcon)
            setUsername(data.firstName + ' ' + data.lastName)
        }
        // GetProfilePic(data.userPassportFrontUrl)
        var passportfile = new File([], data.userPassportFrontUrl.substring(data.userPassportFrontUrl.lastIndexOf('/') + 1))
        setPassportFile(passportfile)
        var nricfile = new File([], data.userNricUrl.substring(data.userNricUrl.lastIndexOf('/') + 1))
        setNationalId(nricfile)
        // console.log(data)


    };

    const onSubmitAll = async (dataInfo: any) => {

        setLoadingAll(true);

        const profilePic = profilePicFile;
        let dataURL
        let profilePicStatus = false;
        if (newLogoFile) {

            if (profilePicFile) {
                const payloadImg = {
                    fileName: "profile-pic",
                    file: profilePicFile,
                    module: "upload-user-photo"
                }

                const resPP = await uploadDocument(payloadImg);
                if (resPP === undefined) {
                    dispatch(updateMsgState({ state: "error", title: "Error", reason: "Image Upload Error", attempt: true, isMain: false }))
                    dataURL = previewImage;
                } else {

                    dataURL = resPP?.data.data.url;

                    const userFormInfo = {
                        firstName: dataInfo.firstName,
                        lastName: dataInfo.lastName,
                        dateofBirth: dataInfo.dob,
                        street: dataInfo.streetAddress,
                        city: dataInfo.city,
                        state: dataInfo.state,
                        country: dataInfo.country,
                        postalCode: dataInfo.postalCode.toString(),
                        userPhotoUrl: dataURL
                    }

                    const result = await userUpdateData(userFormInfo);

                    setUsername(userFormInfo.firstName + ' ' + userFormInfo.lastName);

                    if (result?.status === STATUS_CODE.CREATED) {
                        dispatch(updateMsgState({ state: "success", title: "Successfully updated my account", attempt: true, isMain: false }))
                    } else {
                        dispatch(updateMsgState({ state: "error", title: "Something went wrong.", reason: "Upload Profile Picture Error.", attempt: true, isMain: false }))
                    }
                    setNewLogoFile(false);
                }
            } else {
                //console.log('from here')
                const userFormInfo = {
                    firstName: dataInfo.firstName,
                    lastName: dataInfo.lastName,
                    dateofBirth: dataInfo.dob,
                    street: dataInfo.streetAddress,
                    city: dataInfo.city,
                    state: dataInfo.state,
                    country: dataInfo.country,
                    postalCode: dataInfo.postalCode.toString(),
                    userPhotoUrl: ""
                }

                const result = await userUpdateData(userFormInfo);

                setUsername(userFormInfo.firstName + ' ' + userFormInfo.lastName);

                if (result?.status === STATUS_CODE.CREATED) {
                    dispatch(updateMsgState({ state: "success", title: "Successfully updated my account", attempt: true, isMain: false }))
                } else {
                    dispatch(updateMsgState({ state: "error", title: "Something went wrong.", reason: "Upload Profile Picture Error.", attempt: true, isMain: false }))
                }
                setNewLogoFile(false);
            }

        } else {
            //console.log('here leh')
            const userFormInfo = {
                firstName: dataInfo.firstName,
                lastName: dataInfo.lastName,
                dateofBirth: dataInfo.dob,
                street: dataInfo.streetAddress,
                city: dataInfo.city,
                state: dataInfo.state,
                country: dataInfo.country,
                postalCode: dataInfo.postalCode.toString(),
                userPhotoUrl: previewImage
            }

            const result = await userUpdateData(userFormInfo);

            setUsername(userFormInfo.firstName + ' ' + userFormInfo.lastName);

            if (result?.status === STATUS_CODE.CREATED) {
                dispatch(updateMsgState({ state: "success", title: "Successfully updated my account", attempt: true, isMain: false }))
            } else {
                dispatch(updateMsgState({ state: "error", title: "Something went wrong.", reason: "Upload Profile Picture Error.", attempt: true, isMain: false }))
            }
        }

        setLoadingAll(false);

        // Refresh data
        const response = await getProfile();
        const data = response?.data.data;
        dispatch(updateUserData({ data }));
    }

    const onCancel = () => {
        setPreviewImage(userState.userPhotoUrl);

        setValue('firstName', userState.firstName);
        setValue('lastName', userState.lastName);
        setValue('dob', userState.dateOfBirth);
        setValue('streetAddress', userState.street);
        setValue('country', userState.country);
        setValue('state', userState.state);
        setValue('city', userState.city);
        setValue('postalCode', userState.postalCode);

        let getCountryIndex = allCountries.findIndex((item: CountryData, index) => item[0] === userState.country);

        setSelectedCountryIndex(userState.country)
        // Init load region
        const filter: any[] | undefined = allCountries.at(getCountryIndex)?.[2];

        setSelectedRegionIndex(userState.state)
        // setSelectedRegionIndex()
        setRegion(filter);
        // setDescription(user.description);
    }

    const [presetPic, setPresetPic] = useState(false);

    const calculateAge = (dob: any): boolean => {
        const ddd = dayjs(dob).format('DD/MM/YYYY')
        const date = parse(ddd, "dd/MM/yyyy", new Date())
        const age = differenceInYears(new Date(), date)
        if (age <= 18) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {

        findUserDetails();

    }, []);

    // return (
    //     <Grid container spacing={1} alignItems="stretch">
    //         <div className="about-tabs">
    //             {/* {isUpdated &&
    //                 <div className="on-updated-container">
    //                     <div className="success-msg">
    //                         <img src={UpdatedIcon}/>
    //                         <div>Successfully updated my account</div>
    //                     </div>
    //                     <div>
    //                         <img className="close-icon" src={CloseIcon} />
    //                     </div>
    //                 </div>
    //             } */}
    //             <div className="highlights text-lg font-medium text-gray-900">Personal Info</div>
    //             <div className="text-sm font-normal text-gray-500">Update your photo and personal details here.</div>
    //         </div>
    //         <Grid xs={12}>
    //             <form onSubmit={handleSubmit(onSubmitAll)}>
    //                 <Box mt={2}>
    //                     <div className="root-profile">
    //                         <div id="profile-container">
    //                             {/** Profile Avatar */}
    //                             <div className="profile-icon-wrapper">
    //                                 <div>
    //                                     <div className="text-marginalized text-sm font-medium text-gray-700">Your photo</div>
    //                                     {(previewImage !== null) ?
    //                                         <Avatar src={previewImage} className="user-profile" />
    //                                         :
    //                                         <Avatar src={HumanIcon} className="user-profile" />
    //                                     }

    //                                 </div>

    //                                 <section className="main-drag-and-drop-container">
    //                                     <div className={`root-box ${(profilePicFile !== null) ? `on-uploaded` : `on-default`}`}>
    //                                         <Dropzone onDrop={(acceptedFiles) => {
    //                                             setPreviewImage(URL.createObjectURL(acceptedFiles[0]));
    //                                             setProfilePicFile(acceptedFiles[0]);
    //                                             setNewLogoFile(true);
    //                                         }}
    //                                             maxSize={2097152}
    //                                             multiple={false}
    //                                             accept={{
    //                                                 'image/png': [],
    //                                                 'image/jpg': [],
    //                                                 'image/jpeg': []
    //                                             }}
    //                                         >
    //                                             {({ getRootProps, getInputProps }) => (
    //                                                 <div {...getRootProps({ className: `drop-box` })}>
    //                                                     {/* <div className="icon allow-cursor">
    //                                                 {logoFile &&
    //                                                     <>
    //                                                         <div className={`bound-icon on-uploaded`}>
    //                                                             <img src={FileSuccessIcon} />
    //                                                         </div>
    //                                                     </>
    //                                                 }
    //                                             </div> */}
    //                                                     <input {...getInputProps()} />
    //                                                     {(profilePicFile !== null) ?
    //                                                         <div className="wrapped-on-uploaded">
    //                                                             <div className="icon">
    //                                                                 <div className={`bound-icon on-uploaded`}>
    //                                                                     <img src={FileSuccessIcon} />
    //                                                                 </div>
    //                                                             </div>
    //                                                             <div className="file-details">
    //                                                                 <div className="file-name text-base font-normal text-gray-500">
    //                                                                     <div className="wrapped-text">{profilePicFile.name}</div>
    //                                                                 </div>
    //                                                                 <div className="progress">
    //                                                                     <div className="bar"></div>
    //                                                                     <div className="text">100%</div>
    //                                                                 </div>
    //                                                             </div>
    //                                                             <div className="action-container">
    //                                                                 <img src={TickSuccessIcon} />
    //                                                                 <img src={DeleteIcon} style={{ cursor: "pointer" }} onClick={(event) => {
    //                                                                     event.stopPropagation()
    //                                                                     setNewLogoFile(true)
    //                                                                     setProfilePicFile(null)
    //                                                                     setPreviewImage(HumanIcon)
    //                                                                 }
    //                                                                 } />
    //                                                             </div>
    //                                                         </div>
    //                                                         :
    //                                                         <div className="text-item-empty">
    //                                                             <div className="icon-wrapper-none"><img src={UploadIcon} /></div>
    //                                                             <div className="guide-info">
    //                                                                 <span className="text-sm font-normal securo-color">Click to upload or</span>
    //                                                                 &nbsp;
    //                                                                 <span className="text-sm font-normal text-gray-500">drag and drop</span>
    //                                                             </div>
    //                                                             <div className="text-xs font-normal text-gray-500">PNG or JPG (max. 2 MB)</div>
    //                                                         </div>
    //                                                     }
    //                                                 </div>
    //                                             )}
    //                                         </Dropzone>
    //                                     </div>
    //                                 </section>
    //                             </div>

    //                             {/** Row of Text Field */}
    //                             <div className="section-one">
    //                                 <div className="top">
    //                                     <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                         <span className="upper-label text-sm font-medium text-gray-700">
    //                                             First Name
    //                                             {/* <span className="aesterisk">*</span> */}
    //                                         </span>

    //                                         <TextField id="outlined-basic"
    //                                             variant="outlined"
    //                                             className="shorter-input"
    //                                             {...register("firstName", {
    //                                                 required: "First Name is empty",
    //                                             })}
    //                                             error={!!errors?.firstName}
    //                                             helperText={errors?.firstName?.message}
    //                                         />
    //                                     </div>

    //                                     <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                         <span className="upper-label text-sm font-medium text-gray-700">
    //                                             Last Name
    //                                             {/* <span className="aesterisk">*</span> */}
    //                                         </span>

    //                                         <TextField id="outlined-basic"
    //                                             variant="outlined"
    //                                             className="shorter-input"
    //                                             {...register("lastName", {
    //                                                 required: "Last Name is empty",
    //                                             })}
    //                                             error={!!errors?.lastName}
    //                                             helperText={errors?.lastName?.message}
    //                                         />
    //                                     </div>
    //                                 </div>

    //                                 <div className="bottom">
    //                                     <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                         <span className="upper-label text-sm font-medium text-gray-700">
    //                                             Date of Birth
    //                                             {/* <span className="aesterisk">*</span> */}
    //                                         </span>
    //                                         <LocalizationProvider dateAdapter={AdapterDateFns}>
    //                                             <Controller
    //                                                 control={control}
    //                                                 name="dob"
    //                                                 rules={{
    //                                                     validate: {
    //                                                         min: (date) => calculateAge(date) || "Age must be above 18 years old"
    //                                                     }
    //                                                 }}
    //                                                 render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
    //                                                     <DatePicker
    //                                                         className="date-picker"
    //                                                         {...field}
    //                                                         inputRef={ref}
    //                                                         components={{
    //                                                             OpenPickerIcon: CalendarIcon
    //                                                         }}
    //                                                         PopperProps={{
    //                                                             placement: "bottom-end",
    //                                                             modifiers: [{
    //                                                                 name: 'flip',
    //                                                                 enabled: false,
    //                                                             }]
    //                                                         }}
    //                                                         renderInput={(inputProps) => <TextField
    //                                                             {...inputProps}
    //                                                             onBlur={onBlur}
    //                                                             name={name}
    //                                                             error={!!fieldState.error}
    //                                                             helperText={fieldState.error?.message}
    //                                                             fullWidth
    //                                                         />}
    //                                                     />
    //                                                 )}
    //                                             />
    //                                         </LocalizationProvider>

    //                                         {/* <TextField id="outlined-basic"
    //                                                 variant="outlined"
    //                                                 type="date"
    //                                                 className="shorter-input"
    //                                                 {...register("dob", {
    //                                                     required: "Date of birth is empty",
    //                                                     validate: (val: string) => {
    //                                                         const selectedDate = new Date(val).getFullYear();
    //                                                         const currentYear = new Date().getFullYear();
    //                                                         if (currentYear - selectedDate <= 18) {
    //                                                             return "You must be older than 18 years old to proceed financial investment.";
    //                                                         }
    //                                                     },
    //                                                 })}
    //                                                 error={!!errors?.dob}
    //                                                 helperText={errors?.dob?.message}
    //                                             /> */}
    //                                     </div>

    //                                     <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%" }}>
    //                                         <span className="upper-label text-sm font-medium text-gray-700">
    //                                             Email Address
    //                                             {/* <span className="aesterisk">*</span> */}
    //                                         </span>

    //                                         <TextField id="outlined-basic"
    //                                             variant="outlined"
    //                                             className="shorter-input disabled"
    //                                             {...register("emailAddress", {
    //                                                 required: "Email Address is empty",
    //                                             })}
    //                                             disabled
    //                                             error={!!errors?.emailAddress}
    //                                             helperText={errors?.emailAddress?.message}
    //                                         />
    //                                     </div>
    //                                 </div>
    //                             </div>

    //                             <div className="section-two">
    //                                 <span className="highlights" style={{ fontWeight: 600, fontSize: "18px" }}>Address</span>
    //                                 <div className="street-address">
    //                                     <div id="input-container" style={{ width: "100%" }}>
    //                                         <span className="upper-label text-sm font-medium text-gray-700">
    //                                             Street Address
    //                                             {/* <span className="aesterisk">*</span> */}
    //                                         </span>

    //                                         <TextField id="outlined-basic"
    //                                             variant="outlined"
    //                                             fullWidth
    //                                             className={"full-width-textfield"}
    //                                             style={{ width: "100%" }}
    //                                             {...register("streetAddress", {
    //                                                 required: "Street is empty",
    //                                             })}
    //                                             error={!!errors?.streetAddress}
    //                                             helperText={errors?.streetAddress?.message}
    //                                         />
    //                                     </div>
    //                                 </div>
    //                                 <div className="street-origin">
    //                                     <div className="top">
    //                                         <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                             <span className="upper-label text-sm font-medium text-gray-700">
    //                                                 Country
    //                                                 {/* <span className="aesterisk">*</span> */}
    //                                             </span>
    //                                             {selectedCountryIndex &&
    //                                                 <Select
    //                                                     className="selection-options"
    //                                                     {...register("country", {
    //                                                         required: "Country is empty",
    //                                                         onChange: (e) => handleCountry(e)
    //                                                     })}
    //                                                     defaultValue={selectedCountryIndex !== null ? selectedCountryIndex : 0}
    //                                                     error={!!errors?.country}
    //                                                 >
    //                                                     {listOfCountry.map((item: any, index: number) =>
    //                                                         <MenuItem key={item} value={item}>{item}</MenuItem>
    //                                                     )}
    //                                                 </Select>
    //                                             }
    //                                             {!selectedCountryIndex &&
    //                                                 <Select
    //                                                     className="selection-options"
    //                                                     {...register("country", {
    //                                                         required: "Country is empty",
    //                                                         onChange: (e) => handleCountry(e)
    //                                                     })}
    //                                                     defaultValue={selectedCountryIndex !== null ? selectedCountryIndex : 0}
    //                                                     error={!!errors?.country}
    //                                                 >
    //                                                     {listOfCountry.map((item: any, index: number) =>
    //                                                         <MenuItem key={item} value={item}>{item}</MenuItem>
    //                                                     )}
    //                                                 </Select>
    //                                             }
    //                                             {/* <TextField id="outlined-basic"
    //                                                     variant="outlined"
    //                                                     className="shorter-input"
    //                                                     {...register("country", {
    //                                                         required: "Country is empty",
    //                                                     })}
    //                                                     error={!!errors?.country}
    //                                                     helperText={errors?.country?.message}
    //                                                 /> */}
    //                                         </div>

    //                                         <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                             <span className="upper-label text-sm font-medium text-gray-700">
    //                                                 State
    //                                                 {/* <span className="aesterisk">*</span> */}
    //                                             </span>
    //                                             {isSelectedCountry && region && region.length > 0 &&
    //                                                 <Select
    //                                                     className="selection-options"
    //                                                     {...register("state", {
    //                                                         required: "State is empty",
    //                                                     })}
    //                                                     defaultValue={selectedRegionIndex}
    //                                                     error={!!errors?.country}
    //                                                 >
    //                                                     {region.map((item: any, index: number) =>
    //                                                         <MenuItem key={index} value={item[0]}>{item[0]}</MenuItem>
    //                                                     )}
    //                                                 </Select>
    //                                             }
    //                                             {!isSelectedCountry &&
    //                                                 <Select className="selection-options" disabled defaultValue={0}>
    //                                                     <MenuItem selected={true} value={0}>- Please select country -</MenuItem>
    //                                                 </Select>
    //                                             }
    //                                             {/* <TextField id="outlined-basic"
    //                                                     variant="outlined"
    //                                                     className="shorter-input"
    //                                                     {...register("state", {
    //                                                         required: "State is empty",
    //                                                     })}
    //                                                     error={!!errors?.state}
    //                                                     helperText={errors?.state?.message}
    //                                                 /> */}
    //                                         </div>
    //                                     </div>

    //                                     <div className="bottom">

    //                                         <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%", marginBottom: "10px" }}>
    //                                             <span className="upper-label text-sm font-medium text-gray-700">
    //                                                 City
    //                                                 {/* <span className="aesterisk">*</span> */}
    //                                             </span>

    //                                             <TextField id="outlined-basic"
    //                                                 variant="outlined"
    //                                                 className="shorter-input"
    //                                                 {...register("city", {
    //                                                     required: "City is empty",
    //                                                 })}
    //                                                 error={!!errors?.city}
    //                                                 helperText={errors?.city?.message}
    //                                             />
    //                                         </div>
    //                                         <div id="input-container" style={!matches ? { width: "50%" } : { width: "100%" }}>
    //                                             <span className="upper-label text-sm font-medium text-gray-700">
    //                                                 Postal Code
    //                                                 {/* <span className="aesterisk">*</span> */}
    //                                             </span>

    //                                             <TextField id="outlined-basic"
    //                                                 variant="outlined"
    //                                                 className="shorter-input"
    //                                                 {...register("postalCode", {
    //                                                     required: "Postal Code is empty",
    //                                                 })}
    //                                                 error={!!errors?.postalCode}
    //                                                 helperText={errors?.postalCode?.message}
    //                                             />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div id="action-container">
    //                         {/* <Button variant="outlined" className="discard-button" disabled={loadingAll}  onClick={() => routeChange("/dashboard")}>Discard</Button> */}
    //                         <Button onClick={() => onCancel()} variant="contained" className="cancel-changes-button text-sm font-medium text-gray-700">Cancel</Button>

    //                         <Button variant="contained" className="save-changes-button text-sm font-medium text-white" type="submit" disabled={loadingAll}>{loadingAll ? <CircularProgress size={24} style={{ color: "#ffffff" }} /> : <span>Save</span>}</Button>
    //                     </div>
    //                 </Box>
    //             </form>
    //         </Grid>
    //     </Grid>
    // )

    return (
        <Grid container spacing={1} alignItems="stretch">
            <ResetPassword />
        </Grid>
    )
}

export default MyProfile;