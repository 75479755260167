import "./index.scss";
import ResetKey from '../../assets/images/icons/reset-password.svg';
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { PASSWORD_PATTERN } from "../../constants/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { userChangePassword } from "../../services/axios/auth";
import { updateIsResetPassword } from "../../store/slices/app-slice";
import { updateMsgState } from "../../store/slices/messages-v2-slice";
import { STATUS_CODE } from "../../constants/general";
import VisibilityOff from "../../assets/images/icons/visible-eye-off.svg";
import Visibility from "../../assets/images/icons/visible-eye.svg";
import FormErrorIcon from '../../assets/images/icons/form-error-icon.svg';
import { BaseSyntheticEvent, useState } from "react";

interface State {
    password: string;
    showPassword: boolean;
}
export default function ResetPasswordV2() {
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch
    } = useForm({
        mode: 'onChange'
    });
    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const onSubmit = async (data: any) => {
        try {
            const currentPassword = location.state.password;
            const updatedPassword = data.confirmPassword;
            if (currentPassword === undefined) {
                dispatch(
                    dispatch(updateMsgState({ state: "error", title: "Error", reason: 'Missing current password', attempt: true, isMain: false }))
                );
            }
            const res = await userChangePassword(updatedPassword, currentPassword);
            if (res.status === STATUS_CODE.BAD_REQUEST) {
                dispatch(updateMsgState({ state: "error", title: "Uh oh... Something went wrong", reason: res.data.errorMessage, attempt: true, isMain: false }))

                return;
            } else {
                dispatch(updateIsResetPassword({ isReset: false, tempPassword: '' }));
            }
            dispatch(updateMsgState({ state: "success", title: "Success", reason: "Password has been updated.", attempt: true, isMain: false }))
            navigate('/developer-dashboard');
    
        } catch (err) {
          console.log(err);
        }
    };
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });
    const [values2, setValues2] = useState<State>({
        password: '',
        showPassword: false,
      });
    const [showVisible, setShowVisible] = useState(true);
    const [showVisible2, setShowVisible2] = useState(true);
    const handleChange = (event: BaseSyntheticEvent, target: number) => {
        switch (target) {
          case 1:
            if (event.target.value) {
              setShowVisible(true);
            } else {
              setShowVisible(false);
            }
            break;
          case 2:
            if (event.target.value) {
              setShowVisible2(true);
            } else {
              setShowVisible2(false);
            }
            break;
        }
      };
    const handleClickShowPassword = (target: number) => {
        console.log(target)
        switch (target) {
          case 1:
            setValues({
              ...values,
              showPassword: !values.showPassword,
            });
            console.log(values)
            break;
          case 2:
            setValues2({
              ...values2,
              showPassword: !values2.showPassword,
            });
            break;
        }
      };

    const themeState = useAppSelector((state) => state.theme.themeState);

    return (
        <div className={`${themeState+'_'}reset_password`}>
            <div className={'wrapped'}>

                <div className={'about_block'}>
                    <div className={'circular_bordered'}>
                        <img src={ResetKey} />
                    </div>
                    <div className={'text_focused'}>
                        Set new password
                    </div>
                    <div className={'text_details'}>
                        Your new password must be different to previously used passwords.
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'form_block'}>
                            <div className={'input_container'}>
                                <span className={'title_label'}>
                                    New Password<span className='text-error-500'>*</span>
                                    {/* <span className="aesterisk">*</span> */}
                                </span>
                                <TextField
                                    className={'input_block'}
                                    placeholder="Enter your new password"
                                    variant="outlined"
                                    type={values.showPassword ? 'text' : 'password'}
                                    fullWidth
                                    onKeyDown={() => setNotFound(false)}
                                    InputProps={{
                                        endAdornment:
                                          <div className="hide-adorment">
                                            {!!errors?.password &&
                                              <div><img src={FormErrorIcon}/></div>
                                            }
                                            {(showVisible && errors?.password === undefined) &&
                                              <div onClick={() => handleClickShowPassword(1)}>
                                                {!values.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                                              </div>
                                            }
                                          </div>
                                    }}
                                    {...register('password', {
                                        required: 'Required',
                                        pattern: {
                                        value: PASSWORD_PATTERN,
                                        message:
                                            'Password must be a combination of at least 1 uppercase, 1 lower case, 1 number and 1 special character with min length of 8',
                                        },
                                        onChange: (e) => handleChange(e, 1),
                                    })}
                                    error={!!errors?.password}
                                    helperText={errors?.password?.message}
                                />
                            </div>

                            <div className={'input_container'}>
                                <span className={'title_label'}>
                                    Confirm Password<span className='text-error-500'>*</span>
                                    {/* <span className="aesterisk">*</span> */}
                                </span>
                                <TextField
                                    className={'input_block'}
                                    id="outlined-basic"
                                    placeholder="Confirm your new password"
                                    variant="outlined"
                                    type={values2.showPassword ? 'text' : 'password'}
                                    fullWidth
                                    onKeyDown={() => setNotFound(false)}
                                    InputProps={{
                                        endAdornment:
                                          <div className="hide-adorment">
                                            {!!errors?.confirmPassword &&
                                              <div><img src={FormErrorIcon}/></div>
                                            }
                                            {(showVisible2 && errors?.confirmPassword === undefined) &&
                                              <div onClick={() => handleClickShowPassword(2)}>
                                                {!values2.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                                              </div>
                                            }
                                          </div>
                                    }}
                                    {...register('confirmPassword', {
                                        required: 'Required',
                                        validate: (val: string) => {
                                        if (watch('password') !== val) {
                                            return 'Password does not match';
                                        }},
                                        onChange: (e) => handleChange(e, 2),
                                    })}
                                    error={!!errors?.confirmPassword}
                                    helperText={errors?.confirmPassword?.message}
                                />
                            </div>
                            <Button
                                type="submit"
                                className={'btn'}
                            >
                                Confirm
                            </Button>
                    </div>
                </form>

                <div className={'skip'} onClick={() => navigate('/developer-dashboard')}>Skip for now</div>
                <div className={'footer_block'}>
                    © ThirdFi 2023. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}