import { Alert, AlertColor, Snackbar, Button, Grid, createTheme, ThemeProvider } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import NotificationLogo from "../../assets/images/icons/NotificationLogo.svg"
// import { updateNotificationAsSeen } from "../../services/axios/userNotification";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updatePopperStatus } from "../../store/slices/popper-slice";
import Slide, { SlideProps } from '@mui/material/Slide';
import "./styles.scss"
import { updateBellIconBadgeStatus } from "../../store/slices/bell-icon-badge-slice";

export interface SnackBarProps {
    open: boolean,
    handleOpen: any,
    autoHideDuration?: number,
    message?: string,
    action?: any
    type: AlertColor; // `error`, `warning`, `info`, `success`
    title?: string;
    createdAt?: string;
    id?: number;
    fromGetUnreadUserNotification?: Boolean | null;
}

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

function ThemedSnackBar(props: SnackBarProps) {

    const {
        open,
        handleOpen,
        message,
        autoHideDuration,
        action,
        type,
        title,
        createdAt,
        id,
        fromGetUnreadUserNotification
    } = props;

    const dispatch = useAppDispatch();

    const handleClose = async (event: any, reason?: any) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(updateBellIconBadgeStatus());
        handleOpen(false);
    }

    const handleDismiss = async () => {
        // if (!type && id) {
        //     await updateNotificationAsSeen([id]);
        //     if (fromGetUnreadUserNotification) {
        //         dispatch(updatePopperStatus());
        //     }
        // }
        dispatch(updateBellIconBadgeStatus());
        handleOpen(false);
    }

    const theme = createTheme({
        components: {
            MuiSnackbar: {
                styleOverrides: {
                    root: {
                        maxWidth: "440px",
                        marginTop: "60px"
                    }
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        padding: "0px",
                        backgroundColor: "white",
                        color: "black",
                    },
                    message: {
                        padding: "0px",
                    }
                }
            }
        }
    })

    return <>
        <ThemeProvider theme={theme}>
            <Snackbar
                onClose={handleClose}
                open={open}
                action={action}
                // style={!type ? { width: "440px" } : {}}
                autoHideDuration={autoHideDuration}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                TransitionComponent={TransitionLeft}
            // message={!type
            //     && <div className="notification-container">
            //         <div><img src={NotificationLogo} style={{ objectFit: "cover", height: "116px", width: "80px" }}></img></div>
            //         <div className="message-container">
            //             <div className="title-container">
            //                 <div>{title?.includes("KYC") || title?.includes("KYB") || title === "Your Account has been Terminated" ? title : ""}</div>
            //                 <div>
            //                     <IconButton
            //                         size="small"
            //                         aria-label="close"
            //                         color="inherit"
            //                         onClick={handleDismiss}
            //                     >
            //                         <CloseIcon fontSize="small" />
            //                     </IconButton>
            //                 </div>
            //             </div>
            //             <div className="message" dangerouslySetInnerHTML={{ __html: message ? JSON.parse(JSON.stringify(message)).message ? JSON.parse(JSON.stringify(message)).message.replace(/(?:\r\n|\r|\n)/g, '<br>') : message : '' }}></div>
            //             <div className="dismiss">
            //                 <a onClick={handleDismiss} style={{ cursor: "pointer" }}>Dismiss</a>
            //             </div>
            //             <div className="date">{createdAt ? new Date(createdAt)
            //                 .toLocaleDateString
            //                 (undefined,
            //                     {
            //                         year: 'numeric',
            //                         month: 'long',
            //                         day: 'numeric',
            //                         hour: 'numeric',
            //                         minute: 'numeric',
            //                         hour12: true
            //                     })
            //                 : ""}
            //             </div>
            //         </div>
            //     </div>}
            // action={!type && closeButton}
            >
                {type && <Alert
                    severity={type}
                    sx={{ width: '100%' }}
                    onClose={handleClose}>{message}</Alert>}
            </Snackbar>
        </ThemeProvider>
    </>;
}

export default ThemedSnackBar;