import WhiteWrapper from "../WhiteWrapper"
import EmailConfirm from "./mailbox.svg"
import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import BackIcon from '../../assets/images/icons/back-arrow.svg';
import { useAppSelector } from "../../store/hooks";

function CreateAccountConfirmation({ email }: { email: string }) {
  const themeState = useAppSelector((state) => state.theme.themeState);
  const navigate = useNavigate()
  return <div style={{ height: "100vh" }}>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "85%" }}>
      <img src={EmailConfirm} alt="email-confirm" style={{ width: "56px", marginTop: "147px" }} />
      <div className={`wrapped-input ${themeState}-btn`}>
        <div className={`text-3xl font-semibold text-white`} style={{ marginTop: "30px", textAlign: "center" }}>Verify your email</div>
        <div className="text-md font-normal text-gray-500" style={{ marginTop: "12px", textAlign: "center", width: "360px" }}>
          You’ve entered&nbsp;
          <span className="text-md font-semibold text-gray-500">{email}</span>
          &nbsp;as the email address for your account.
        </div>

        <div className="text-md font-normal text-gray-500" style={{ marginTop: "16px", textAlign: "center" }}>Please verify this email by clicking below.</div>

        <div className="w-[100%]">
          <div className="btn-padding mt-8">
            <Button className={`btn on-invalid-state`} variant="contained" fullWidth onClick={() => navigate("/")}>
              <div className="text-base font-medium text-white">Verify Now</div>
            </Button>
          </div>
        </div>
      </div>
      <div onClick={() => navigate("/main")} style={{ cursor: "pointer", display: "flex", gap: "12.17px", alignItems: "center" }} className="text-sm font-medium text-gray-500">
        <img src={BackIcon} /> Back to login
      </div>
    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-end", paddingBottom: "32px", height: "15%" }} className="text-base font-normal text-gray-400">© ThirdFi 2023. All Rights Reserved.</div>
  </div>
}

export default CreateAccountConfirmation
