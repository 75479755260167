import { Divider, Box, Grid, Skeleton, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { getSingleAPILog } from "../../../services/axios/apiLogging";
import "./apiLogging.scss";
import { useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import goBackDeveloper from "../../../assets/images/common/goBackDeveloper.svg";
import EmptyCard from "../../../components/DeveloperToolsComponents/EmptyCard";
import CommonTable from "../../../components/DeveloperToolsComponents/CommonTable";
import { CodeBlock, a11yLight } from "react-code-blocks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function APILoggingDetails(props: any) {

    interface IAPILoggingDetails {
        createdDate: string,
        header: string,
        id: number,
        ip: string,
        method: string,
        requestBody: string,
        responseTime: number,
        statusCode: number,
        url: string,
        userAgent: string
    }

    const [apiLoggingDetails, setAPILoggingDetails] = useState<IAPILoggingDetails | null>(null)
    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)
    const [displaySkeleton, setDisplaySkeleton] = useState(true)
    const [displayTable, setDisplayTable] = useState(false)
    const [errorData, setErrorData] = useState<any>("")

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params && params.id) APILogsDetailsFunc(parseInt(params.id));
    }, [])

    const { functionToCall } = props;

    const goBack = () => {
        navigate("/api-logging");
    }
    const APILogsDetailsFunc = async (id: number) => {
        try {
            const APILog = await getSingleAPILog(id, sandboxMode)

            if (APILog.data.data.responseBody) setDisplayTable(true)

            let errorMessage = APILog.data.data.responseBody

            setErrorData(errorMessage)
            setAPILoggingDetails(APILog.data.data)
            setDisplaySkeleton(false)
        } catch (err) {
            goBack();
        }
    }

    useDidMountEffect(() => {
        goBack();
    }, [sandboxMode])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`api-logging-details-container ${themeState}-api-logging`} pb={10}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
                <Box className="api-logging">
                    <img src={goBackDeveloper} alt={"Go Back"} onClick={goBack} style={{ cursor: "pointer" }} />
                    API Logging
                </Box>
                <Box className="subtitle">View your API Logging here</Box>
            </Box>
        </Box>
        <Divider className="divider" />
        {displaySkeleton ? <Box>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
        </Box> :
            <Box>
                {apiLoggingDetails && <Box className="content-box">
                    <Box className="basic-details">
                        <Box my={3} display="flex">
                            <Box pr={3} className="bounded-data" style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Date/Time</Box>
                                <Box className="second-row" mt={1}>{(new Date(apiLoggingDetails.createdDate)
                                    .toLocaleDateString
                                    (undefined,
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })).toString()}
                                </Box>
                            </Box>
                            <Box px={3} className="bounded-data" style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Status</Box>
                                <Box className="second-row" mt={1}>{apiLoggingDetails.statusCode}</Box>
                            </Box>
                            <Box pl={3}>
                                <Box className="headings">API Method</Box>
                                <Box className="second-row" mt={1}>{apiLoggingDetails.method}</Box>
                            </Box>
                        </Box>
                        <Divider className="divider" />
                        <Box my={2} display="flex">
                            <Box pr={3} className="bounded-data" style={{ borderRight: "1px solid rgba(0,0,0,0.1)" }}>
                                <Box className="headings">Source IP Address</Box>
                                <Box className="value" mt={1}>{apiLoggingDetails.ip}</Box>
                            </Box>
                            <Box pl={3}>
                                <Box className="headings">API Endpoint</Box>
                                <Box className="value" mt={1}>{apiLoggingDetails.url}</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="divider" />
                    <Box my={2} className="request">
                        <Box mb={1} className="title">
                            Request Body
                        </Box>
                        <Box className="content" style={{ fontFamily: 'monospace', background: "#FFFFFF", maxWidth: "1064px" }} my={3} p={2} sx={{ border: "1px solid #EAECF0" }}>
                            {/* {apiLoggingDetails.requestBody} */}
                            <CodeBlock
                                text={JSON.stringify(JSON.parse(apiLoggingDetails.requestBody), null, 2)}
                                language="javascript"
                                showLineNumbers={true}
                                startingLineNumber={1}
                                theme={a11yLight}
                            />
                        </Box>
                    </Box>
                    <Divider className="divider" />
                    {displayTable && <Box className="api-log-container" >
                        <Box mb={1} mt={4} className="headings">Error Message</Box>
                        <Divider />
                        <Box my={3}>
                            <Box className="content" style={{ fontFamily: 'monospace', background: "#FFFFFF", maxWidth: "1064px" }} my={3} p={2} sx={{ border: "1px solid #EAECF0" }}>
                                <CodeBlock
                                    text={JSON.stringify(JSON.parse(errorData), null, 2)}
                                    language="typescript"
                                    showLineNumbers={true}
                                    startingLineNumber={1}
                                    theme={a11yLight}
                                />
                            </Box>
                        </Box>
                        <Divider className="divider" />
                    </Box>}
                </Box>}
            </Box>}
    </Box >
}
