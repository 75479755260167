import axios, { getJWT } from "./axios";

export const getCustomers = async (page: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/customers?limit=${10}&page=${page}&sandbox=${sandbox}`, header);
    return res;
}

export const getCustomersFromSearch = async (emailAddress: string, page: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/customers?emailAddress=${emailAddress}&limit=${10}&page=${page}&sandbox=${sandbox}`, header);
    return res;
}

export const getSingleCustomer = async (id: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/customers/${id}?sandbox=${sandbox}`, header);
    return res;
}

export const updateCustomer = async (id: number, payload: any, sandbox: boolean) => {
    const header = await getJWT();
    await axios.patch(`/api/v1/customers/${id}?sandbox=${sandbox}`, payload, header);
}

export const createCustomer = async (payload: any, sandbox: boolean) => {
    const header = await getJWT();
    return await axios.post(`/api/v1/customers?sandbox=${sandbox}`, payload, header);
}

export const getCustomersTimeSeries = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/customers/time-series/new-customers?startDate=${start}&endDate=${end}&sandbox=${sandbox}`, header);
    return res;
}