import { AlertColor } from "@mui/material/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IMessages {
    state: string,
    title: string,
    reason: string,
    attempt: boolean
}


interface MessagesState {
    messagesv2: IMessages | null
}

// Adds a message to the store
const initialState: MessagesState = {
    messagesv2: null
};
const messagesV2Slice = createSlice({
    name: "messagesv2",
    initialState,
    reducers: {
        // Creates an error message
        updateMsgState(state, payload) {
            state.messagesv2 = payload.payload;
        },
        clearState(state, payload) {
            state.messagesv2 = null;
        }
    },
});

export const { updateMsgState, clearState } = messagesV2Slice.actions;

export default messagesV2Slice.reducer;
