import { createSlice } from '@reduxjs/toolkit';

export interface SocialMediaConnectionStatus {
    telegramConnection: boolean,
    slackConnection: boolean
}

const initialState: SocialMediaConnectionStatus = {
    telegramConnection: false,
    slackConnection: false
}

const socialMediaSlice = createSlice({
    name: 'socialMedia',
    initialState,
    reducers: {
        updateTelegramConnectionStatus(state, action) {
            state.telegramConnection = action.payload
        },
        updateSlackConnectionStatus(state, action) {
            state.slackConnection = action.payload
        }
    }
})

export const { updateSlackConnectionStatus, updateTelegramConnectionStatus } = socialMediaSlice.actions

export default socialMediaSlice.reducer