import { Box } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as SettingIcon } from '../../assets/images/icons/gear.svg';
import './sidebar.scss'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTabState } from "../../store/slices/common-slice";
import { bindPage } from "../../pages/Settings";
import { useAppSelector } from "../../store/hooks";

interface ITabbedMode {
    isTabbed: boolean;
}

export default function SettingsMenu(props: ITabbedMode) {

    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search.replace('?', '');
    const [selected, setSelected] = useState(false);

    const handleNavigate = (path: string) => {
        navigate(path);
    }
    const themeState = useAppSelector((state) => state.theme.themeState);

    useEffect(() => {
        location.pathname.includes('settings') ? setSelected(true) : setSelected(false);
    }, [location])

    return <Box className={`${themeState}-settings`}>
        <Box onClick={() => 
            {
                setSelected(!selected);
                updateTabState(-1);
            }} className="menu-container-item flex-row flex-align-center" style={{ cursor: "default" }}>
            <Box display="flex"
                justifyContent="center"
                alignItems="center">
                <div className="menu-item-icon"><SettingIcon/></div>
            </Box>
            <Box style={{ marginRight: "15px", cursor: "pointer" }}>Settings</Box>
            <Box style={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                {selected ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Box>
        </Box>
        {selected && <Box className="menu-container-normal">
            <Box
                className={`menu-container-item ${search === bindPage[0] ? 'selected' : ''}`}
                style={{ marginLeft: "45px" }}
                onClick={() => {
                    handleNavigate("/settings?api-setup")
                }}>
                API Key Setup
            </Box>
            <Box
                className={`menu-container-item ${search === bindPage[1] ? 'selected' : ''}`}
                style={{ marginLeft: "45px" }}
                onClick={() => {
                    handleNavigate("/settings?sandbox-setup")
                }} >
                Sandbox Setup
            </Box>
            <Box
                className={`menu-container-item ${search === bindPage[3] ? 'selected' : ''}`}
                style={{ marginLeft: "45px" }}
                onClick={() => {
                    handleNavigate("/settings?webhook-setup")
                }} >
                Webhooks
            </Box>
        </Box>}
    </Box >
}