import axios, { getJWT } from "./axios";

export const getAllDeveloperTransactions = async (pageNumber: number, sandboxMode: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction?limit=${10}&page=${pageNumber}&sandbox=${sandboxMode}`, header);
    return res;
}

export const getSingleDeveloperTransaction = async (id: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction/related-transaction/${id}?sandbox=${sandbox}`, header);
    return res;
}

export const getDeveloperTransactionsFromFilter = async (status: string, page: number, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction?status=${status}&limit=${10}&page=${page}&sandbox=${sandbox}`, header);
    return res;
}

export const getDeveloperTransactionsTimeSeries = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction/time-series/amount?startDate=${start}&endDate=${end}&sandbox=${sandbox}`, header);
    return res;
}

export const getDeveloperTransactionsTotalDeposit = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction/period/total?startDate=${start}&endDate=${end}&sandbox=${sandbox}&actionType=DEPOSIT`, header);
    return res;
}

export const getDeveloperTransactionsTotalWithdraw = async (start: string, end: string, sandbox: boolean) => {
    const header = await getJWT();
    const res = await axios.get(`/api/v1/transaction/period/total?startDate=${start}&endDate=${end}&sandbox=${sandbox}&actionType=WITHDRAW`, header);
    return res;
}