// Standard characters pattern
export const STANDARD_PATTERN = {
    pattern: /^[a-zA-Z ]*$/,
    message: "Please enter on alphabets only."
}

// Password pattern which receive at least one uppercase, one lowercase, one number and one number with a min length of 8
export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/

// Valid email pattern
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/