import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type serverState = 'Healthy' | 'Under Maintenance' | 'Loading' | 'Offline';

export interface IServerState {
  state: serverState;
}

const initialState: IServerState = {
    state: 'Offline'
}

const serverStateSlice = createSlice({
  name: 'serverState',
  initialState,
  reducers: {
    updateServerState(state, { payload }) {
        state.state = payload.data;
    }
  },
});

const baseInfo = (state: RootState) => state.app;

export default serverStateSlice.reducer;

export const { updateServerState } = serverStateSlice.actions;

export const getServerState = createSelector(baseInfo, (data) => data);
