import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { THEME } from "../constants/general";
import { initThemeState, updateThemeState } from "../store/slices/theming-slice";

export const useThemeSwitch = (mode?: string) => {
    // Initial
    const getThemeMode = localStorage.getItem('MODE');

    const dispatch = useDispatch();
    if(getThemeMode === "DEFAULT") {
        dispatch(updateThemeState('DEFAULT'));
    } else {
        dispatch(initThemeState());
    }
}