import LoaderIcon from "./loader.svg";
import "./index.scss";
import { CircularProgress } from "@mui/material";
    
export default function ChartLoader() {

    return (
        <div className="loader-load">
            <div className="align-loader"><CircularProgress /></div>
        </div>
    )
}