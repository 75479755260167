import DashboardIconActive from '../assets/images/menus/light/dashboard-icon-active.svg';
import DashboardIconInactive from '../assets/images/menus/light/dashboard-icon-inactive.svg';

// Investment Dashboard
import { ReactComponent as DashboardLight } from '../assets/images/menus/light/dashboard.svg';
import { ReactComponent as DashboardDark } from '../assets/images/menus/dark/dashboard.svg';

import { ReactComponent as InvestLight } from '../assets/images/menus/light/invest.svg';

import { ReactComponent as InvestIconActive } from '../assets/images/menus/light/invest-icon-active.svg';
import { ReactComponent as InvestIconInactive } from '../assets/images/menus/light/invest-icon-inactive.svg';

import { ReactComponent as PortfolioIconActive } from '../assets/images/menus/light/portfolio-icon-active.svg';
import { ReactComponent as PortfolioIconInactive } from '../assets/images/menus/light/portfolio-icon-inactive.svg';

import { ReactComponent as TransactionHistoryIconActive } from '../assets/images/menus/light/transaction-history-icon-active.svg';
import { ReactComponent as TransactionHistoryIconInactive } from '../assets/images/menus/light/transaction-history-icon-inactive.svg';

import WalletLight from '../assets/images/menus/light/wallet.svg';
import { ReactComponent as AccountLight } from '../assets/images/menus/light/account.svg';
import { ReactComponent as AccountDark } from '../assets/images/menus/dark/account.svg';
import TransactionLight from '../assets/images/menus/light/transaction.svg';

// Developer Dashboard
import { ReactComponent as BusinessDetailsLight } from "../assets/images/menus/light/BusinessDetails.svg";
import { ReactComponent as BusinessDetailsDark } from "../assets/images/menus/dark/BusinessDetails.svg";

import { ReactComponent as CustomerLight } from "../assets/images/menus/light/Customer.svg";
import { ReactComponent as CustomerDark } from "../assets/images/menus/dark/Customer.svg";

import { ReactComponent as TransactionListingLight } from "../assets/images/menus/light/transactionListing.svg";
import { ReactComponent as TransactionListingDark } from "../assets/images/menus/dark/transactionListing.svg";

import { ReactComponent as ReportingLight } from "../assets/images/menus/light/Reporting.svg";
import { ReactComponent as ReportingDark } from "../assets/images/menus/dark/Reporting.svg";

import { ReactComponent as ReferralIconLight } from '../assets/images/menus/light/ReferralIcon.svg';
import { ReactComponent as ReferralIconDark } from '../assets/images/menus/dark/ReferralIcon.svg';

import InvestDark from '../assets/images/menus/dark/invest.svg';
import WalletDark from '../assets/images/menus/dark/wallet.svg';

import TransactionDark from '../assets/images/menus/dark/transaction.svg';

export interface IMenuItem {
  label: string;
  path: string[];
  headerLabel: string;
  lightIcon?: any; // Icon for light theme
  darkIcon?: any; //Icon for dark theme
  developerPortal?: boolean;
  disable: boolean;
}

const menu: IMenuItem[] = [
  {
    label: 'Dashboard',
    path: ['/dashboard'],
    headerLabel: 'Dashboard',
    lightIcon: <DashboardLight/>,
    darkIcon: <DashboardDark/>,
    developerPortal: false,
    disable: true,
  },
  {
    label: 'Invest',
    path: ['/invest', '/invest/LCI', '/invest/MWI', '/invest/BNI'],
    headerLabel: 'Investment Strategies',
    developerPortal: false,
    lightIcon: <InvestIconActive/>,
    darkIcon: <InvestIconInactive/>,
    disable: true,
  },
  // { label: "Wallet", path: "/", lightIcon: WalletLight, darkIcon: WalletDark },
  {
    label: 'My Portfolio',
    path: ['/portfolio-performance'],
    headerLabel: 'My Portfolio',
    developerPortal: false,
    lightIcon: <PortfolioIconActive/>,
    darkIcon: <PortfolioIconInactive/>,
    disable: true,
  },
  {
    label: 'Transaction History',
    path: ['/transaction-history'],
    headerLabel: 'Transaction History',
    developerPortal: false,
    lightIcon: <TransactionHistoryIconActive/>,
    darkIcon: <TransactionHistoryIconInactive/>,
    disable: true,
  },
  {
    label: 'My Account',
    path: ['/my-account'],
    headerLabel: 'My Account',
    lightIcon: <AccountLight/>,
    darkIcon: <AccountDark/>,
    disable: true,
  },
  {
    label: '',
    path: ['/reset-password'],
    headerLabel: '',
    lightIcon: '',
    darkIcon: '',
    disable: true,
  },
  {
    label: '',
    path: ['/verify'],
    headerLabel: '',
    lightIcon: '',
    darkIcon: '',
    disable: true,
  },
  {
    label: 'Notifications',
    path: ['/notifications'],
    headerLabel: 'Notifications',
    lightIcon: '',
    darkIcon: '',
    disable: true
  },
  {
    label: 'Developer Dashboard',
    path: ['/developer-dashboard'],
    headerLabel: 'Developer Dashboard',

    lightIcon: <DashboardLight/>,
    darkIcon: <DashboardDark/>,
    developerPortal: true,
    disable: false,
  },
  {
    label: 'API Logging',
    path: ['/api-logging'],
    headerLabel: 'API Logging',
    lightIcon: '',
    darkIcon: '',
    disable: true
  },
  {
    label: 'Webhook Events',
    path: ['/webhook-events'],
    headerLabel: 'Webhook Events',
    lightIcon: '',
    darkIcon: '',
    disable: true
  },
  {
    label: 'Business Details',
    path: ['/business-details'],
    headerLabel: 'Business Details',
    lightIcon: <BusinessDetailsLight/>,
    darkIcon: <BusinessDetailsDark/>,
    disable: false,
    developerPortal: true
  },
  {
    label: 'Transactions',
    path: ['/transaction-listing'],
    headerLabel: 'Transactions',
    lightIcon: <TransactionListingLight/>,
    darkIcon: <TransactionListingDark/>,
    disable: false,
    developerPortal: true
  },
  {
    label: 'Customer',
    path: ['/customer'],
    headerLabel: 'Customer',
    lightIcon: <CustomerLight/>,
    darkIcon: <CustomerDark/>,
    disable: false,
    developerPortal: true,
  },
  {
    label: 'Reporting',
    path: ['/reporting'],
    headerLabel: 'Reporting',
    lightIcon: <ReportingLight/>,
    darkIcon: <ReportingDark/>,
    disable: false,
    developerPortal: true
  },
  {
    label: 'Referral Program',
    path: ['/referral-program'],
    headerLabel: 'ThirdFi Referral Program',
    developerPortal: false,
    lightIcon: <ReferralIconLight/>,
    darkIcon: <ReferralIconDark/>,
    disable: false
  },
  {
    label: 'Settings',
    path: ['/settings'],
    headerLabel: 'Settings',
    lightIcon: '',
    darkIcon: '',
    disable: true,
  },
];

export default menu;
