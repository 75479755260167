import { Divider, Box, Grid, Card, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import CommonTable from "../../../components/DeveloperToolsComponents/CommonTable";
import EmptyCard from "../../../components/DeveloperToolsComponents/EmptyCard";
import { getSingleWebhookLog } from "../../../services/axios/webhookLogs";
import "./webhook.scss";
import JSONPretty from 'react-json-pretty';
import { useAppSelector } from "../../../store/hooks";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import goBackDeveloper from "../../../assets/images/common/goBackDeveloper.svg";
import { CodeBlock, a11yLight } from "react-code-blocks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export default function WebhookEventsDetails(props: any) {

    //table titles
    const apiTitles = ['Date/Time', 'Webhook attempts']

    const [displayTable, setDisplayTable] = useState(false);
    const { functionToCall, ID } = props;

    const params = useParams();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    const [webhookDetails, setWebhookDetails] = useState<any>(null)
    const [webhookAttemptsArray, setWebhookAttemptsArray] = useState<any[][]>([[]]);
    const [displaySkeleton, setDisplaySkeleton] = useState(true);
    const [scroll, setScroll] = useState(false);

    const sandboxMode = useAppSelector(state => state.mode.sandboxMode)

    const WebhookDetailsFunc = async (id: number) => {
        try {
            const res = await getSingleWebhookLog(id, sandboxMode)
            setWebhookDetails(res.data.data)

            const webhookAttempts: any[] = []

            if (res.data.data.attemptDetails) {
                const webhookObj = JSON.parse(res.data.data.attemptDetails)

                Object.keys(webhookObj).map((key, i) => {
                    const arr = [];
                    arr[0] = (new Date(parseInt(webhookObj[key].timestamp))
                        .toLocaleDateString
                        (undefined,
                            {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            })).toString();

                    arr[1] = <JSONPretty id="json-pretty" data={webhookObj[key]}></JSONPretty>
                    webhookAttempts[i] = arr
                })
            }

            if (webhookAttempts.length > 0) {
                setDisplayTable(true)
            } else {
                setDisplayTable(false)
            }

            setWebhookAttemptsArray(webhookAttempts)
            setDisplaySkeleton(false)
        } catch (err) {
            goBack()
        }
    }

    useEffect(() => {
        if (params && params.id) WebhookDetailsFunc(parseInt(params.id));
    }, [])

    const root = document.getElementById('overall-content');

    useEffect(() => {
        function onScroll() {
            root?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            root?.removeEventListener('scroll', onScroll, true);
        }

        root?.addEventListener('scroll', onScroll, true);

        return () => {
            // root?.scrollTo(0, 0);
            root?.removeEventListener('scroll', onScroll, true);
        }
    }, []);

    useDidMountEffect(() => {
        goBack();
    }, [sandboxMode])

    const themeState = useAppSelector((state) => state.theme.themeState);

    return <Box className={`webhook-events-details-container ${themeState}-webhook-events`} pb={8}>
        <Box mb={2} display="flex" justifyContent="space-between">
            <Box>
                <Box className="webhook-events" style={{ display: "flex", gap: "10px" }}>
                    <img src={goBackDeveloper} alt={"Go Back"} onClick={goBack} style={{ cursor: "pointer" }} />
                    Webhook Events
                </Box>
                <Box className="subtitle">View your webhook events here</Box>
            </Box>
            {/* <Box>
                <Box style={{ fontWeight: 500, fontSize: "16px", cursor: "pointer" }} onClick={goBack}>
                    Go Back
                </Box>
            </Box> */}
        </Box>
        <Divider className="divider" />
        {displaySkeleton ? <Box>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
            <br></br>
            <Skeleton variant="rounded" height={100} />
        </Box> :
            <Box>
                {webhookDetails && <Box>
                    <Box className="basic-details">
                        <Box my={3} display="flex">
                            <Box pr={3}>
                                <Box className="headings">Date/Time</Box>
                                <Box className="second-row" mt={1}>{(new Date(webhookDetails.createdDate)
                                    .toLocaleDateString
                                    (undefined,
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })).toString()}</Box>
                            </Box>
                        </Box>
                        <Divider className="divider" />
                        <Box my={3} display="flex">
                            <Box pr={3}>
                                <Box className="headings">Webhook URL</Box>
                                {webhookDetails.webhook && webhookDetails.webhook.url ?
                                    <Box className="second-row" mt={1}>
                                        {
                                            webhookDetails.webhook.url
                                        }
                                    </Box> :
                                    <Box className="second-row" mt={1}>
                                        -
                                    </Box>}
                            </Box>
                        </Box>
                        <Divider className="divider" />
                    </Box>
                    <Box my={2} className="webhook-log-container">
                        <Box mb={2} mt={4} className="webhook-log">Event data</Box>
                        <Divider className="divider" />
                        <Box className="content" style={{ fontFamily: 'monospace', background: "#FFFFFF", maxWidth: "1064px" }} my={3} p={2} sx={{ border: "1px solid #EAECF0" }}>
                            {(() => {
                                const transactionDataField = JSON.parse(webhookDetails?.transaction?.data)
                                const transaction = JSON.parse(JSON.stringify(webhookDetails?.transaction))

                                let codeBlockData;

                                if(webhookDetails && webhookDetails.body) {
                                    const parsedData = JSON.parse(webhookDetails.body)
                                    codeBlockData = {
                                        ...parsedData,
                                    }
                                } else {
                                    codeBlockData = {
                                        publicSessionId: transaction.session && transaction.session.publicSessionId ? transaction.session.publicSessionId : "",
                                        orderId: transaction.session && transaction.session.orderId ? transaction.session.orderId : "",
                                        data: transactionDataField,
                                    }
                                }

                                return < CodeBlock
                                    text={JSON.stringify(codeBlockData, null, 2)}
                                    language="typescript"
                                    showLineNumbers={true}
                                    startingLineNumber={1}
                                    theme={a11yLight}
                                />
                            })()}
                        </Box>
                    </Box>
                    <Divider className="divider" />
                    <Box className="webhook-log-container" >
                        <Box mb={2} mt={4} className="webhook-log">Webhook Attempts</Box>
                        <Divider className="divider" />
                        {!displayTable && <Box my={3}>
                            <EmptyCard title="No webhook events" />
                        </Box>}
                        {displayTable && <Box my={3}>
                            <CommonTable headings={apiTitles} rows={webhookAttemptsArray} leftAlign={true} />
                        </Box>}
                        {/* <Divider /> */}
                    </Box>
                    {/* <Box className="webhook-log-container" pb={6}>
                <Box mb={2} mt={4} className="webhook-log">Webhook Response Received</Box>
                <Divider />
                {!displayTable && <Box my={3}>
                    <EmptyCard title="No webhook events" />
                </Box>}
                {displayTable && <Box my={3}>
                    <CommonTable headings={webhookTitles} rows={webhookData} leftAlign={true} />
                </Box>}
                <Divider />
            </Box> */}
                </Box>}
            </Box>}
        <div className="global-bottom-spacer"></div>
    </Box >
}
