import { createSlice } from '@reduxjs/toolkit';

export interface IMode {
    sandboxMode: boolean
}

const initialState: IMode = {
    sandboxMode: localStorage.getItem("SANDBOX_MODE") === null ? false : localStorage.getItem("SANDBOX_MODE") === "1" ? true : false
}

const modeSlice = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        updateModeStatus(state, action) {
            state.sandboxMode = action.payload;
            if (action.payload) {
                localStorage.setItem("SANDBOX_MODE", "1");
            } else {
                localStorage.setItem("SANDBOX_MODE", "0");
            }

        }
    }
})

export const { updateModeStatus } = modeSlice.actions

export default modeSlice.reducer