import "./index.scss";
import "./kyc.scss";
import "./personal-info.scss";
import SecuroIcon from "../../assets/images/icons/Securo.svg"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { updateDrawerState } from "../../store/slices/drawer-slice";
import { useDispatch } from "react-redux";

import BackIcon from "../../assets/images/icons/back-arrow.svg";

import UploadIcon from "../../assets/images/icons/upload-icon-v2.svg";
import FileSuccessIcon from "../../assets/images/icons/file-upload-success.svg";
import TickSuccessIcon from "../../assets/images/icons/tick.svg";
import PreviewIcon from "../../assets/images/icons/magnifying-glass.svg";
import DeleteIcon from "../../assets/images/icons/delete.svg";
import {useDropzone} from 'react-dropzone'
import Dropzone from "react-dropzone";
import { Box } from "@mui/system";
import { updatePortalStatus } from "../../store/slices/portal-toggle-slice";

function MenuSelection() {
    const matches = useMediaQuery('(max-width:720px)');
    const navigate = useNavigate();
    const stepHandler = (mode: string) => {
        if(mode === 'next') {
            setCurrentStep(currentStep + 1);
        } else if(mode === 'back') {
            setCurrentStep(currentStep - 1);
        }
    }
    const stepsTitle = [{
        id: 0,
        title: "KYC Verification",
        text: "Please verify your personal details to enjoy seamless use at Securo."
    },
    {
        id: 1,
        title: "KYC Verification",
        text: "Kindly upload your Passport and National ID to verify and complete your KYC"
    },
    {
        id: 2,
        title: "Thank you for submitting your documents",
        text: "We are reviewing your documents. This should only take a couple of minutes. We will update you via mail once your KYC is completed."
    },]
    const [currentStep, setCurrentStep] = useState(0);

    const dispatch = useDispatch();

    const [selection, setSelection] = useState(0);
    const [isSelected, setIsSelected] = useState(false);

    const selectionHandler = (index: number) => {
        if(index === 0) {
            setSelection(index)
            setIsSelected(true)
        } else {
            setSelection(index)
            setIsSelected(true)
        }
    }

    const confirmSelection = () => {
        if(selection === 0) {
            dispatch(updateDrawerState(false))
            dispatch(updatePortalStatus(false))
            localStorage.setItem('DEV_MODE', '0')
            navigate('/dashboard');
        } else {
            dispatch(updateDrawerState(false))
            dispatch(updatePortalStatus(true))
            localStorage.setItem('DEV_MODE', '1')
            navigate('/developer-dashboard');
        }
    }


    return (
        <div className="selection-root-page DARK-bg">
            <div className="selection-wrap">
                <div className={`DARK-text-m-xl`}>Select the portal you prefer</div>
                <div className="flex-selections">
                    <div className={`selections DARK-selections ${isSelected && selection === 0 ? `selected` : null}`} onClick={() => selectionHandler(0)}>
                        <div className="img-box"><img src={require("../../assets/images/menus/Investment-Portal.png")}></img></div>
                        <div className="about-box">
                            <div className={`DARK-text-xl`}>Investment Portal</div>
                            <div className="text-base font-normal text-gray-500">You can access various investment strategies where you can invest and manage the investment.</div>
                        </div>
                    </div>
                    <div className={`selections DARK-selections ${isSelected && selection === 1 ? `selected` : null}`} onClick={() => selectionHandler(1)}>
                    <div className="img-box"><img src={require("../../assets/images/menus/Developer-Portal.png")}></img></div>
                        <div className="about-box">
                            <div className={`DARK-text-xl`}>Developer Portal</div>
                            <div className="text-base font-normal text-gray-500">You can access developer tools where you can manage business transactions and operations through Securo API.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-info-area text-base font-normal text-gray-500">Don’t worry! <br/>  You can change the type of the portal later inside.</div>
            <div className="btn-area-selection"><Button onClick={confirmSelection} disabled={!isSelected} className={`DARK-selection-confirm-btn`}>Confirm</Button></div>
            <div className="about-wrapped-verification text-base font-normal text-gray-400">© ThirdFi 2023. All Rights Reserved.</div>
        </div>
    )
}

export default MenuSelection;