import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../services/auth/authProvider";
import { getBusinessDetails } from "../../services/axios/businessDetails";
import { getProfile } from "../../services/axios/user";
import { updateUserData } from "../../store/slices/user-slice";
import { updateServerState } from "../../store/slices/server-slice";

function useGetProfile() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const findUserDetails = useCallback(async () => {
    // const response = await getProfile();
    const response = await getBusinessDetails();
    const data = response?.data.data;

    if (data) {
      data.firstName = data.firstName ? data.firstName : data.contactName ? data.contactName : null;
      data.userPhotoUrl = data.userPhotoUrl ? data.userPhotoUrl : data.businessPhotoUrl ? data.businessPhotoUrl : null;
    }

    if (data) {
      dispatch(updateUserData({ data }));
      dispatch(updateServerState({ data: 'Healthy' }));
    }
  }, []);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      findUserDetails();
    }
  }, [user, location]);
}

export default useGetProfile;
