import { Button, Checkbox, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { EMAIL_PATTERN } from "../../constants/validation";
import { useAuth } from "../../services/auth/authProvider";
import { useAppSelector } from "../../store/hooks";
import useMediaQuery from '@mui/material/useMediaQuery';

import "./main.scss";
import VisibilityOff from "../../assets/images/icons/visible-eye-off.svg";
import Visibility from "../../assets/images/icons/visible-eye.svg";
import FormErrorIcon from '../../assets/images/icons/form-error-icon.svg';
import SocialLogins from '../../components/SocialLogins';
import { MESSAGE_TYPE } from "../../constants/general";
import CompanyLogo from '../../assets/images/company';

interface CustomizedState {
  from: string
}

interface State {
  password: string;
  showPassword: boolean;
}

function LoginContainer() {

  const isResetPassword = useAppSelector((state) => state.app.isResetPassword);

  const matches = useMediaQuery('(max-width:720px)');

  const location = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
  });
  // Navigate to forget password
  const navigate = useNavigate();
  const navigateToForgetPassword = () => {
    navigate("/forget-password");
  };

  const navigateToCreateAccount = () => {
    navigate("/register");
  };


  // Redirect user to reset password page if user reset password before
  const { user, message } = useAuth();

  useEffect(() => {
    if (!!user) {
      if (isResetPassword) {
        navigate("/reset-password", {
          state: {password: getValues('password')}
        });
        return;
      }

      // ;TODO Main navigation on undefined route
      navigate("/developer-dashboard");
    }
  }, [user, isResetPassword]);

  // Handling for login page
  const { login } = useAuth();
  const [notFound, setNotFound] = useState(false);

  const onSubmit = async (data: any) => {
    
    const email = data.email;
    const password = data.password;
    await login(email, password);

    // if(isPending) {
    //   navigate('/profile-completion')
    // }
    if (location.state) {
      const state = location.state as CustomizedState

      Object.entries(state).map(([key, value]) => {
        if (key == 'from')
          navigate(value);
      })
    }
  };

  useEffect(() => {
    if(message === MESSAGE_TYPE.NOT_FOUND) {
      setNotFound(true)
      // dispatch(updateMsgState({state: "error", title: "Wrong email or password", reason: "Please enter correct credentials", attempt: condition}))
    } else {
      setNotFound(false)
    }
  }, [message])

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
  });
  const [showVisible, setShowVisible] = useState(true)
  const handleChange = (event: BaseSyntheticEvent) => {
    if(event.target.value) {
      setShowVisible(true)
    } else {
      setShowVisible(false)
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className={`wrapped-input ${themeState}-input-base ${!!errors?.email ? `on-error` : `on-default`}`}>
              <div className="text-input-title text-sm font-medium text-gray-700">Email</div>
              <TextField
                id="outlined-basic"
                placeholder="Enter your email"
                variant="outlined"
                fullWidth
                onKeyDown={() => setNotFound(false)}
                {...register("email", {
                  required: "Email Required",
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: "Your email format was incorrect. Please double check and try again.",
                  }
                })}
                InputProps={{
                  endAdornment:
                  <div className="hide-adorment">
                  {(!!errors?.email || notFound === true) &&
                    <div><img src={FormErrorIcon}/></div>
                  }
                  </div>
                }}
                error={!!errors?.email || notFound}
                helperText={
                  <>
                  {errors?.email &&
                    <div className='text-sm font-normal text-error-500'>Your email format was incorrect. Please double check and try again.</div>
                  }
                  {notFound === true &&
                    // <div className='text-sm font-normal text-error-500'>User not found</div>
                    <></>
                  }
                  </> 
                }
              />
            </div>

            <div className={`wrapped-input ${themeState}-input-base ${!!errors?.password ? `on-error` : `on-default`}`}>
              <div className="text-input-title text-sm font-medium text-gray-700">Password</div>
              <TextField
                id="outlined-adornment-password"
                placeholder="Enter your password"
                variant="outlined"
                type={values.showPassword ? "text" : "password"}
                fullWidth
                InputProps={{
                  endAdornment:
                    <div className="hide-adorment">
                      {!!errors?.password &&
                        <div><img src={FormErrorIcon}/></div>
                      }
                      {(showVisible && errors?.password === undefined) &&
                        <div onClick={handleClickShowPassword}>
                          {!values.showPassword ? <img src={VisibilityOff}/> : <img src={Visibility}/>}
                        </div>
                      }

                    </div>
                }}
                {...register("password", { required: "Required", onChange: (e) => handleChange(e) })}
                error={!!errors?.password || notFound}
                helperText={
                  <>
                  {errors?.password &&
                    <div className='text-sm font-normal text-error-500'>Password can’t be empty</div>
                  }
                  {notFound === true &&
                    // <div className='text-sm font-normal text-error-500'>User not found</div>
                    <div className='text-sm font-normal text-error-500'>Wrong email or password</div>
                  }
                  </> 
                }
              />
            </div>

            <div className="wrapped-options">
                <div className="wrapped-remember-me text-sm font-medium text-gray-700">
                  {/* <Checkbox checked={isRememberMe} onChange={handleRememberMe}/><span>Remember me</span> */}
                </div>
                <a
                  className={`${themeState}-text`}
                  href="#"
                  id="forget-password"
                  onClick={() => navigateToForgetPassword()}
                >
                  Forgot Password
                </a>
            </div>

            <div className={`wrapped-input ${themeState}-btn`}>
                <div style={matches ? { width: "100%" } : { width: "100%" }}>
                  <div className="btn-padding">
                    <Button className={`btn on-invalid-state`} variant="contained" type="submit" fullWidth>
                      <div className="text-base font-medium text-white">Login</div>
                    </Button>
                  </div>
                  <div className={`${themeState}-social-login`}>
                    <div className="social-login-divider">Or continue with</div>
                  </div>
                  <SocialLogins/>
                  <div className="create-account text-sm font-normal text-gray-500 mt-[32px]">Don't have an account ?&nbsp;<span className={`${themeState}-text`} onClick={() => navigateToCreateAccount()}>Sign Up</span></div>
              </div>
            </div>
        </form>
      </div>
    </>
  );
}

function Main() {
  const themeState = useAppSelector((state) => state.theme.themeState);

  return (
    <>
      <div className="main-page-wrapper">
          <div className="form-wrap-upper">
              <div className="logo-wrapper">
                <CompanyLogo mode={themeState} />
              </div>
              <div className={`${themeState}-divider`}></div>
              <div className="intro-wrapper">
                <div className={`${themeState}-text-m-3xl`}>Log in to your account</div>
                <div className="text-base font-normal text-gray-500">Welcome to ThirdFi!</div>
              </div>
              <LoginContainer />
          </div>
          <div className="about-wrapped-main text-base font-normal text-gray-400">© ThirdFi 2023. All Rights Reserved.</div>
      </div>
    </>
  );
}

export default Main;
