import MyProfile from "./normalAccount";
import MyBusinessProfile from "./businessAccount";
import { useAppSelector } from "../../store/hooks";
import Tabs from "./tabs";

function AccountComponent() {
    const user = useAppSelector(state => state.user.userData);
    // if (user && user.userType === "B") return <Tabs />
    // // if (user && user.userType === "I") return <MyProfile />
    // if (user && user.userType === "I") return <Tabs />
    // return <></>
    return <Tabs />
}

export default AccountComponent;
