import styles from "./index.module.scss";
import ErrorIcon from "../../assets/images/icons/error-icon.svg"
import SuccessIcon from "../../assets/images/icons/updated-icon.svg";
import CloseCrossIcon from "../../assets/images/icons/close-crossed-icon.svg"
import CloseCrossIconWarn from "../../assets/images/icons/close-crossed-icon-warn.svg"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearState } from "../../store/slices/messages-v2-slice";

interface IMSG {
    state: string,
    title?: string,
    reason?: string,
    attempt: boolean,
    isMain?: boolean,
}

const Snacky = ({payload = {state: "", title: "", reason: "", attempt: false, isMain: false}, style}: {payload?: IMSG | null, style?: any}) => {

    const [msg, setMsg] = useState("Nothing");
    const [timeoutDuration, setTimeoutDuration] = useState(3000);
    const [displayable, setDisplayable] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        if(payload?.attempt) {
            setDisplayable(true);
            setTimeout(() => {
                setDisplayable(false);
                dispatch(clearState)
            }, timeoutDuration)
        }
    }, [payload])

    const closeHandler = () => {
        setDisplayable(false);
    }

    return (
        <>
        {payload?.isMain &&
            <div className={displayable ? styles.snacky : styles.case_timeout }>
            <div className={`${styles.snacky_position} ${styles.snacky_isMain}`}>
                <div className={payload?.state === "success" ? styles.snacky_position_message_box_success : styles.snacky_position_message_box_error}>
                    <div className={styles.snacky_position_content}>
                        <div className={styles.snacky_position_content_title}>
                            <div className={styles.snacky_position_content_title_icon_sign}><img src={payload?.state === "success" ? SuccessIcon : ErrorIcon}/></div>
                            <div className={payload?.state === "success" ? styles.case_success_title : styles.case_error_title}>{payload?.title}</div>
                            <div className={styles.snacky_position_content_title_close_btn}><img onClick={() => closeHandler()} src={payload?.state === "success" ? CloseCrossIcon : CloseCrossIconWarn}/></div>
                        </div>
                        <div className={styles.snacky_position_content_reason}>
                            <div className={styles.snacky_position_content_reason_spacer}></div>
                            <div className={payload?.state === "success" ? styles.case_success_reason : styles.case_error_reason}>{payload?.reason}</div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        }
        {!payload?.isMain &&
            <div className={displayable ? styles.snacky : styles.case_timeout }>
                <div className={`${styles.snacky_position} ${styles.snacky_isNotMain}`}>
                    <div
                        className={`
                        ${
                            payload?.state === "success" ? styles.snacky_position_message_box_success : styles.snacky_position_message_box_error
                        }
                        ${
                            payload?.isMain === false ? styles.snacky_position_on_auth: null
                        }
                        ${
                            payload?.reason === undefined ? styles.snacky_position_on_nulled_reason: null
                        }
                        `}
                    >
                        <div className={`${styles.snacky_position_content}`}>
                            <div className={styles.snacky_position_content_title}>
                                <div className={styles.snacky_position_content_title_icon_sign}><img src={payload?.state === "success" ? SuccessIcon : ErrorIcon}/></div>
                                <div className={payload?.state === "success" ? styles.case_success_title : styles.case_error_title}>{payload?.title}</div>
                                <div className={styles.snacky_position_content_title_close_btn}><img onClick={() => closeHandler()} src={payload?.state === "success" ? CloseCrossIcon : CloseCrossIconWarn}/></div>
                            </div>
                            <div className={styles.snacky_position_content_reason}>
                                <div className={styles.snacky_position_content_reason_spacer}></div>
                                <div className={payload?.state === "success" ? styles.case_success_reason : styles.case_error_reason}>{payload?.reason}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>

    )
}

export default Snacky;